import { ICurrency } from '@models/currency.interface'
import type { ICryptos, IGame, IGames, IPlayer } from '@models/game.interface'
import { GameStateEnum } from '@models/game.interface'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from '@store/store'
import _ from 'lodash'

const initialState: IGame ={
  id: null,
  bet: 0,
  duration: 10,
  startsAt: Date.now().toString(),
  endsAt: Date.now().toString(),
  winner: null,
  state: GameStateEnum.preparation,
  maxCryptos: 3,
  cryptos: [],
  players: [],
}


export const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    setGame: (state, action: PayloadAction<IGame>) => {
      state.id = action.payload.id
      state.state = action.payload.state
      state.bet = action.payload.bet
      state.cryptos = action.payload.cryptos
      state.startsAt = action.payload.startsAt
      state.endsAt = action.payload.endsAt
      state.duration = action.payload.duration
      state.maxCryptos = action.payload.maxCryptos
      state.winner = action.payload.winner
    },
    tempStartGame: (state) => {
      state.state = GameStateEnum.started
    },
    tempEndGame: (state) => {
      state.state = GameStateEnum.ended
    },
    setCryptos: (state, action: PayloadAction<ICryptos[]>) => {
      state.cryptos = action.payload
    },
    setGameStartDate: (state, action: PayloadAction<string>) => {
      state.startsAt = action.payload
    },
    setGameId: (state, action: PayloadAction<number>) => {
      state.id = action.payload
    },
    setWinner: (state, action: PayloadAction<IPlayer | null>) => {
      state.winner = action.payload
    },
    resetGame: () => {
      return initialState
    },
  },
})


export const selectGame = (state: RootState) => state.gameReducer

export const selectIsWinner = (state: RootState) => {
  return state.userReducer.user.id === state.gameReducer.winner?.id
}

export const selectOpponent = (state: RootState): { id: number; username: string } => {
  const myId = state.userReducer.user.id
  const myCryptosIndex = _.findIndex(state.gameReducer.cryptos, { user: { id: myId } })
  const oppCryptosIndex = myCryptosIndex === 0 ? 1 : 0
  const opp = state.gameReducer.cryptos[oppCryptosIndex]
  if (opp && opp.user) {
    return opp.user
  }
  return { id: 0, username: '' }
}

export const { tempStartGame, tempEndGame, setGameId, setGame, resetGame, setCryptos, setGameStartDate, setWinner } = gameSlice.actions

export default gameSlice.reducer
