import logoImg from '@assets/icons/profile-icon.png'
import style from './about.module.scss';
import TeamMemberComponent from './components/team-member.component';
import GameComponent from './components/game.component';
import GameHeader from '@components/shared/game-header/game-header.component';



export default function AboutPage() {

  return (
    <div>
        <GameHeader/>
    <div className={style.content}>
        <div className={style.title}>About</div>
        <div className={style.componentRow}>
            <div className={style.team}>
                <p>Company Profile</p>
                {/*<div className={style.comoponentDivider}>
                    <TeamMemberComponent name='1' image={logoImg}/>
                    <TeamMemberComponent name='1' image={logoImg}/>
                    <TeamMemberComponent name='1' image={logoImg}/>
                    <TeamMemberComponent name='1' image={logoImg}/>
                </div>
                <div className={style.comoponentDivider}>
                    <TeamMemberComponent name='1' image={logoImg}/>
                    <TeamMemberComponent name='1' image={logoImg}/>
                    <TeamMemberComponent name='1' image={logoImg}/>
                    <TeamMemberComponent name='1' image={logoImg}/>
                </div>*/}
            </div>
        </div>
        <div className={style.componentRow}>
            <div className={style.games}>
                <p>Company Profile</p>
                    {/*<div className={style.wrapper}><GameComponent name='Totix' image={logoImg} desc='A puzzle game where players combine colored number
                        cards on a Tic-Tac-Toe board to make three-card
                        combinations for points, with disappearing cards and
                        increasing levels of challenge.'/></div>
                    <div className={style.wrapper}><GameComponent name='POKLETTE' image={logoImg} desc='Poker and Roulette combined into a fast-paced, unique single-player gambling game. Bet on outcomes like same suit, pocket pair, and final hand for an exciting experience.'/></div>
                    <div className={style.wrapper}><GameComponent name='PODOKU' image={logoImg} desc='Sudoku and Poker combined in a unique puzzle game. Hunt for quality cards on a Sudoku board and engage in a Texas Hold’em Showdown with your opponent using the cards you’ve kept.'/></div>
            <div className={style.wrapper}><GameComponent name='PODRAW' image={logoImg} desc='An engaging online card game that merges Poker with Solitaire, where players aim to create 5-card Poker hands on three Solitaire lanes and outscore their opponent.'/></div>*/}
                
            </div>
        </div>
    </div>
    </div>
  )
}

