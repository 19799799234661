import { useAppSelector } from '@hooks/use-app-dispatch.hook'
import { DurationEnum } from '@models/game.interface'
import styles from './game-length-title.module.scss'
import { selectRound } from '@store/tournament/rounds.slice'

export default function GameLengthTitle(props: { gameId: number }) {
  const { duration } = useAppSelector((state) => selectRound(state, props.gameId))

  const durationEnumToTime = (dur: DurationEnum) => {
    return dur;
  }

  return <h3 className={styles.title}>{durationEnumToTime(duration)} minute game</h3>
}
