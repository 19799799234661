import Table from '@components/shared/table/table.component';
import BattleLogComponent from '@pages/home/components/battleLog/batte-log.component';
import clsx from 'clsx';
import style from '../game-log.module.scss';

export default function LogTable(props: { title: string, cols: string[], rows: any[], smallSize?: boolean }): JSX.Element {
    
    return (
        <div className={clsx(style.logTable, props.smallSize && style.logTable_small)}>
            <div className={clsx(style.title, props.smallSize && style.title_small)}>{props.title}</div>
            <Table rows={props.rows} columns={props.cols} darkTheme showLogo  thinBars={props.smallSize} thinRow={props.smallSize} isPaginate={props.title == 'leaderboard' ||props.title=='battle log'} columnDivider={props.title != 'battle log'} bolderHead/>
        </div>
    );
}