import Button from '@components/shared/button/button.component'
import React from 'react'

import styles from './error-fallback.module.scss'

const ErrorFallback = ({ error }: { error: any }) => {
  return (
    <div role="alert" className={`container ${styles.wrapper}`}>
      <h2>Ooops, something went wrong :( </h2>
      <pre>{error.message}</pre>
      <Button onClick={() => window.location.assign(window.location.origin)}>Refresh</Button>
    </div>
  )
}

export default ErrorFallback
