import musicFile from '@assets/audio/retro-game-music.mp3'
import musicIcon from '@assets/icons/music-icon.png'
import pauseIcon from '@assets/icons/pause-white.png'
import playIcon from '@assets/icons/play-white.png'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'

import styles from './music.module.scss'

interface IMusicProperties {
  playing: boolean
  toggleAudio: () => void
  hidden?: boolean
}

export default function Music(props: IMusicProperties) {
  const { playing, toggleAudio, hidden } = props
  const [audio] = useState(new Audio(musicFile))

  useEffect(() => {
    if (playing) {
      const resp = audio.play()

      if (resp !== undefined) {
        resp
          .then((_) => {
            audio.volume = 0.3
          })
          .catch((error) => {
            toggleAudio()
          })
      }
    } else {
      audio.pause()
    }
  }, [audio, playing])

  // Audio Loop
  useEffect(() => {
    function loop() {
      audio.currentTime = 0
      audio.play()
      audio.volume = 0.3
    }

    audio.addEventListener('ended', loop)

    return () => {
      audio.removeEventListener('ended', loop)
      audio.pause()
    }
  }, [audio])

  return (
    <div className={clsx(styles.wrapper, hidden && styles.wrapper_hidden)}>
      <button className={styles.btn} onClick={toggleAudio}>
        {playing ? <img src={pauseIcon} alt="pause" /> : <img src={playIcon} alt="play" />}
      </button>
      <img src={musicIcon} alt="music icon" className={styles.musicIcon} />
    </div>
  )
}
