import authService from '@api/services/auth.service'
import Button from '@components/shared/button/button.component'
import ModalWrapper from '@components/shared/modal-wrapper/modal-wrapper.component'
import { useAppDispatch } from '@hooks/use-app-dispatch.hook'
import { setUsername } from '@store/user/user.slice'
import clsx from 'clsx'
import React, { useState } from 'react'
import { ReferenceDot } from 'recharts'

import styles from './modals.module.scss'

interface INicknameModalProperties {
  isOpen: boolean
  onClose: () => void
}

export default function NicknameModal(props: INicknameModalProperties) {
  const { isOpen, onClose } = props
  const [name, setName] = useState('')
  const [refCode, setRefCode] = useState<string | null>('')
  const [inputError, setInputError] = useState('')
  const dispatch = useAppDispatch()

  const validateName = (value: string) => {
    const regex = /^[\dA-Za-z]{0,32}$/
    return regex.test(value)
  }

  const handleChange = (value: string) => {
    setName(value)
    setInputError('')
  }

  const createNickname = async (e: React.SyntheticEvent) => {
    e.preventDefault()

    const isValid = validateName(name)
    if (isValid === false) {
      setInputError('Name entering error')
      return
    }
    if (refCode) {
      const refValid = await authService.validateRefCode(refCode);
      if (!refValid.data)
        return;
    }
    try {
      await authService.setUserAttr(name, refCode)
      dispatch(setUsername(name))
      onClose()
    } catch (error: any) {
      console.log(error)
      if (error.error[0] === 'this username is already exists') {
        setInputError('This username is already exists')
      } else {
        setInputError('Server error')
      }
    }
  }

  return (
    <ModalWrapper isOpen={isOpen} toggle={() => {}}>
      <form className={styles.form} onSubmit={createNickname}>
        <h4 className={styles.title}>Enter your nickname</h4>
        <p className={styles.description}>Be careful, you can't change it later</p>
        <input
          type="text"
          className={clsx('input', inputError && 'input_error')}
          value={name}
          onChange={({ target: { value } }) => handleChange(value)}
          required
        />
        <h4 className={clsx(styles.title, styles.title_second)}>referall code</h4>
        <p className={styles.description}></p>
        <input
          type="text"
          className={clsx('input', inputError && 'input_error')}
          value={refCode!}
          onChange={({ target: { value }}) => { setRefCode(value)}}
        />
        <Button type="submit" additionalClassnames={styles.btn} disabled={!!inputError}>
          Confirm
        </Button>
      </form>
      {inputError && <small className={styles.formError}>{inputError}</small>}
    </ModalWrapper>
  )
}
