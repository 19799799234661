import React, { useEffect, useState } from 'react'

import styles from './paragraph.module.scss';
import Collapsible from 'react-collapsible';

export interface IParagraphProperties {
  question: any
  answer: any
  darkTheme?: boolean
}

export default function Paragraph(props: IParagraphProperties) {
  const { question, answer, darkTheme } = props

  const paragraphClassName = darkTheme ? styles.darkParagraph : styles.lightParagraph
  return (
    <div className={paragraphClassName}>
      <Collapsible
        key={question}
        transitionTime={200}
        trigger={
          <h3 className={styles.title}>
            {' '}
            <svg width={35} height={41} fill="#7efbef">
              <path id="Polygon_1" d="M 0 0 L 35 20 L 0 41 Z">
                {' '}
              </path>
            </svg>
            <div className={styles.label}>{question}</div>
          </h3>
        }
        triggerOpenedClassName={styles.itemHeaderOpened}
      >
        <p className={styles.paragraph}> {answer}</p>
      </Collapsible>
    </div>
  )
}
