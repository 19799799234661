import Button from "@components/shared/button/button.component";
import CardSlider from "./card-slider.component";
import styles from './SearchCard/search-card.module.scss'
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { useLogin } from "@hooks/use-login.hook";
import { useAppSelector } from "@hooks/use-app-dispatch.hook";
import { selectIsLoggedIn } from "@store/user/user.slice";

interface ICardContentProps{
  setBuyInState: (value: React.SetStateAction<number>) => void;
  buyInState: number;
  freeplay?: boolean
}

export default function CardContent(props: ICardContentProps) {  
  const isLogged = useAppSelector(selectIsLoggedIn)
  const { executeLogin } = useLogin()
  const navigate = useNavigate()
  const setBuy = (value: number) => {
    props.setBuyInState(value);
  }
  const popitup = (url: string, windowName: string) => {
    const newwindow=window.open(url,windowName,'height=1920,width=1080');
    if (newwindow)
      newwindow.focus()
    else {
      alert('Pop-up blocked! Please enable pop-ups for this site');
      navigate(url, { replace: false, relative: 'path' })
    }
  }
  const renderBet = (bet: number) => {
    return (
      <div className={styles.betContent}>
        <div className={styles.bet}>{bet}</div>
        <div className={styles.betLabel}>Usdt</div>
      </div>
    );
  }
  return (
    <div className={clsx(styles.rangeWrapper, !props.freeplay && styles.rangeWrapper_safe)}>
      
      { props.freeplay &&
          <Button additionalClassnames={styles.freeBtn} variant='secondary' onClick={() => { 
              if (isLogged)
                popitup('https://app.tradasy.io/ai-game', 'aiGame') 
              else 
                executeLogin()
            }}>
            FreePlay
          </Button>
        }
        
      <p>Select buy-in</p>
      <div className={styles.tokens}>
              <Button additionalClassnames={styles.tokenButton} variant={props.buyInState == 1 ? 'primary': 'secondary'} onClick={() => {setBuy(1)}}>{renderBet(1)}</Button>
              <Button additionalClassnames={styles.tokenButton} variant={props.buyInState == 5 ? 'primary': 'secondary'} onClick={() => {setBuy(5)}}>{renderBet(5)}</Button>
              <Button additionalClassnames={styles.tokenButton} variant={props.buyInState == 10 ? 'primary': 'secondary'} onClick={() => {setBuy(10)}}>{renderBet(10)}</Button>
      </div>
    </div>
  );
}