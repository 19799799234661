import gameService from "@api/services/game.service";
import tournamentService from "@api/services/tournament.service";
import { WebsocketContext } from "@api/websocket-provider";
import GameHeader from "@components/shared/game-header/game-header.component";
import { useAppSelector } from "@hooks/use-app-dispatch.hook";
import { GameStateEnum } from "@models/game.interface";
import { WsResponseAction } from "@models/ws.interface";
import ResultModal from "@pages/tournamentGame/components/ResultModal/result-modal.component";
import TournamentGamePage from "@pages/tournamentGame/index.page";
import { ITournament } from "@store/tournament/tournament.model";
import { selectUser } from "@store/user/user.slice";
import clsx from "clsx";
import { useContext, useEffect, useState } from "react";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import RoundMatchComponent from "./components/round-match.component";
import SliderComponent from "./components/slider.component";
import style from './tournament-map.module.scss';
import loadingImg from '@assets/icons/loading-tournament.png'


export default function TournamentMapPage() {
    const navigate = useNavigate();
    const { socket } = useContext(WebsocketContext);
    const [isLoading, setIsLoading] = useState(false)
    const [searchParameters] = useSearchParams();
    const queryId = searchParameters.get('id') as string;
    const roundId = searchParameters.get('round') as string;
    const [tournament, setTournament] = useState<ITournament | null>();
    const [expiryTime, setExpiryTime] = useState('');
    const [offset, setOffset] = useState(0);
    const [tournamentState, setTournamentState] = useState('Starts In: ');
    const { id, wallet } = useAppSelector(selectUser);
    const [showResult, setShowResult] = useState(false);
    const [resultState, setResultState] = useState<number>(-1)
    useEffect(() => {
      if (Number(roundId) > 0) {
        navigate({
          pathname: '/tournament-game',
          search: createSearchParams({ tournament: queryId, id: roundId.toString() }).toString(),
        })
      }
    }, [roundId, queryId])

    const tournamentReq = async (close: boolean = true) => {
      const serverTimestamp = await gameService.getServerTimestamp()
      const offset = Date.now() - serverTimestamp.data
      setOffset(offset)
      const tournamentInfo = await tournamentService.getTournamentInfo(queryId)
      if (tournamentInfo.status == 200 && tournamentInfo.data) {

        if (close && (tournamentInfo.data.state == GameStateEnum.canceled || tournamentInfo.data.state == GameStateEnum.ended)) {
          window.close();
        }

        setTournament(tournamentInfo.data)
        if (tournamentInfo.data.state == GameStateEnum.preparation) {
          setTournamentState('Starts In: ')
        } else {
          setTournamentState('Ends In: ')
        }

        setExpiryTime(
          new Date(new Date(tournamentInfo.data.timeLabel).getTime() + offset).toISOString()
        )
      }
    }
    useEffect(() => {
      tournamentReq()
    }, [searchParameters])

    useEffect(() => {
      if (socket === null) {
        return
      }
      const tournamentReadyListener = (data: any) => {
        if (tournament && Number(roundId) > 0 )
          navigate({
            pathname: '/tournament-game',
            search: createSearchParams({ tournament: queryId, id: roundId.toString() }).toString(),
          })

        //tournamentReq()
      }

      const roundMessageListener = (data: any) => {
        //tournamentReq(false)
        try {
          setTournamentState(data.message)
          setExpiryTime((new Date(data.date)).toISOString())
        } catch (err) {
          console.log(err)
        }
      }
      const tournamentMessageListener = async (data: number) => {
        try {
          await tournamentReq(false);
          setTournamentState('Rounds Finished, New Rounds Starts In: ')
          setExpiryTime(new Date(data + offset).toISOString())
        } catch (err) {
          console.log(err)
        }
      }
      
      const tournamentEndsListener = (data: { winners: string[], tournament: any}) => {
        try {
          setTournamentState('Tournament Ended')
          tournamentReq(false);
          const winnerState = data.winners.findIndex( winner => winner == wallet);
          setResultState(winnerState + 1)
          setShowResult(true)
        } catch (err) {
          console.log(err)
        }
      }

      socket.on(WsResponseAction.roundReady + '_' + id, tournamentReadyListener)
      socket.on(WsResponseAction.roundMessage + '_' + tournament?.id, roundMessageListener)
      socket.on(
        WsResponseAction.tournamentMessage + '_' + tournament?.id,
        tournamentMessageListener
      )
      socket.on(WsResponseAction.tournamentEnds + '_' + tournament?.id, tournamentEndsListener)

      return () => {
        //socket.off(WsResponseAction.roundReady + '_' + id)
        socket.off(WsResponseAction.roundMessage + '_' + tournament?.id)
        socket.off(WsResponseAction.tournamentMessage + '_' + tournament?.id)
        socket.off(WsResponseAction.tournamentEnds + '_' + tournament?.id)
      }
    }, [navigate, socket, tournament])
    const confirmResultAndRedirect = () => {
      window.close();
    }
    const getPercentPerDist = (playerCount: number, index: number) => {
      if (index == 1){
        if (playerCount == 8)
          return 100;
        if (playerCount == 16)
          return 65;
        if (playerCount == 32)
          return 50;
      }
      if (index == 2){
        if (playerCount == 16)
          return 35;
        if (playerCount == 32)
          return 25;
      }
      if (index == 3){
        return 12.5;
      }
      return 12.5;
      
    }
    useEffect(() => {
      if (new Date(expiryTime).getTime() < Date.now())
        setIsLoading(true)
      else 
        setIsLoading(false)
      
    }, [expiryTime])

    /*if (isLoading) {
      return (
        <div>
          <GameHeader gameType='Tournament Game'/>
          <div className={style.loader}>
            <img src={loadingImg} alt='Loading'/>
          </div>
        </div>
      )
    }*/
    return (<>
    <GameHeader gameType="Tournaments"/>
      <div className={style.container}>
        {tournament && 
          <RoundMatchComponent tournament={tournament} expiryTime={expiryTime} info={tournamentState}/>
        }
        <ResultModal
          map
          isOpen={showResult}
          onClose={() => confirmResultAndRedirect()}
          bet={getPercentPerDist(tournament?.maxPlayers!, resultState) / 100 * tournament?.bet! * tournament?.maxPlayers!}
          isWinner={tournament?.winner?.id == id}
          state={ resultState ? resultState+ '. Winner ' : undefined}
          winnerName={tournament?.winner ? tournament.winner.username : undefined}
        />

      </div>
        <SliderComponent tournamentId={Number(queryId)}/></>
    )

}