import React, { useEffect, useState } from 'react'
import { useTimer } from 'react-timer-hook'

import styles from './timer.module.scss'
import clsx from 'clsx'

interface ITimerProperties {
  expiry: any
  onExpire?: () => void
  isGame?: boolean;
  isRound?: boolean;
  // requestRestart: (newTime: any) => void
}

export default function Timer({ expiry, onExpire, isGame, isRound}: ITimerProperties) {
  const { seconds, minutes, restart, start } = useTimer({
    expiryTimestamp: new Date(expiry),
    onExpire: () => restart(new Date(expiry)),
    autoStart: true
  })
  
  useEffect(() => {
    if (expiry && isRound) {
      const currentTimestamp = new Date().getTime();
      
      if (new Date(expiry).getTime() > currentTimestamp) {
        restart(new Date(expiry));
      }
    }
  }, [expiry, isRound, restart]);
  return (
    <div className={clsx(styles.timer, isGame && styles.timer_game)}>
      { isGame && <div className={styles.title}>Time</div>}
      <div className={clsx(isGame && styles.time)}> {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}</div>
    </div>
  )
}
