import style from '../../../inGame/components/currencyPick/currency-pick.module.scss'
import clsx from 'clsx'
import Button from '@components/shared/button/button.component'
import { useCallback, useContext, useEffect, useState } from 'react'
import { WebsocketContext } from '@api/websocket-provider'
import { useAppSelector, useAppDispatch } from '@hooks/use-app-dispatch.hook'
import { ICurrency } from '@models/currency.interface'
import gameService from '@api/services/game.service'
import { useDidMountEffect } from '@hooks/use-did-mount-effect.hook'
import { GameStateEnum } from '@models/game.interface'
import { WsAction, WsResponseAction } from '@models/ws.interface'
import Fuse from 'fuse.js'
import _ from 'lodash'
import PickedTokensComponent from './components/picked-tokens.component'
import { selectUser } from '@store/user/user.slice'
import CurrenciesListComponent from '@pages/inGame/components/currencyPick/components/currencies-list.component'
import { selectGame, setGameStartDate, tempStartGame } from '@store/game/games.slice'
import { pickCurrency, selectPickedCurrencies, setOpponentPick } from '@store/currency-pick/currency-picks.slice'


export default function CurrencyPickPage(props: { prizePool: any}) {
    
    const [inputError, setInputError] = useState('');
    
  const picked = useAppSelector((state) => selectPickedCurrencies(state, 0))
  const dispatch = useAppDispatch()
  const { maxCryptos, state } = useAppSelector((state) => selectGame(state, 0))
  const [allCryptos, setAllCryptos] = useState<ICurrency[]>([])
  const [searched, setSearched] = useState<ICurrency[]>([])
  const { socket, isReady } = useContext(WebsocketContext)
  const readyToGame = () => {
    if (picked.length != maxCryptos)
      return;
    
    socket.emit(WsAction.playWithBot)
    dispatch(setGameStartDate({ id: 0, date: new Date(Date.now()).toISOString() }))
    dispatch(tempStartGame(0))
  }
  const fuse = new Fuse(allCryptos, {
    keys: ['name', 'symbol'],
    threshold: 0.2,
  })

  const handleSearch = (value: string) => {
    if (value === '') {
      setSearched(allCryptos)
      return
    }
    const searchResult = fuse.search(value)
    const res: ICurrency[] = []
    searchResult.forEach((el) => res.push(el.item))
    setSearched(res)
  }

  const selectCurrency = (currency: ICurrency) => {
    if (picked.length >= maxCryptos) {
      return
    }
    dispatch(pickCurrency({ id: 0, currency: currency }))
  }

  const updateSearchedList = useCallback(
    (newData: ICurrency[]) => {
      const updatedSearched: ICurrency[] = searched.map((token) =>
        _.find(newData, { symbol: token.symbol })
      ) as ICurrency[]
      setSearched(updatedSearched)
    },
    [searched]
  )

  // Get all currencies for pick on startup
  useEffect(() => {
    if (!isReady) {
      return
    }
    
    const getAllCurrencies = async () => {
      try {
        const resp = await gameService.getCryptosList()

        setAllCryptos(resp.data)
        setSearched(resp.data)

        const opponentRandomPicked: ICurrency[] = [];
        for (let i = 0; i < 3; i++) {
          const currency = resp.data[Math.floor(Math.random() * (resp.data.length - 1))]!;
          if (opponentRandomPicked.includes(currency)){
            i--;
            continue;
          }
          opponentRandomPicked.push(currency);
        }
        dispatch(setOpponentPick({ id: 0, currencies: opponentRandomPicked }))
      } catch (error) {
        console.log(error)
      }
    }
    getAllCurrencies()
  }, [isReady])

  // Send picked tokens through socket
  useDidMountEffect(() => {
    if (!isReady && state !== GameStateEnum.preparation) {
      return
    }
  }, [picked, isReady])

  // Watch for all currencies update from websocket
  useEffect(() => {
    if (!isReady) {
      return
    }
    const allCurrenciesListener = (allCurrencies: ICurrency[]) => {
      setAllCryptos(allCurrencies)
      updateSearchedList(allCurrencies)
    }
    socket.on(WsResponseAction.allCurrencies, allCurrenciesListener)

    return () => {
      socket.off(WsResponseAction.allCurrencies, allCurrenciesListener)
    }
  }, [isReady, updateSearchedList]);
  
  return (
    <div className={style.content}>
      <div className={style.leftColumn}>
        <div className={clsx(style.wrapper, style.wrapper_inner)}>
          <div className={style.wrapperTitle}> 5 Minute Game</div>
          <div className={style.pickCurrenciesRow}>
            <div className={style.pickCurrenciesCol}>
              <p className={style.label}>Pick currencies({picked.length}/{maxCryptos}){' '}</p>
            </div>
            <div className={style.pickCurrenciesCol}>
              <input
                className={clsx('input', style.additionInput, inputError && 'input_error')}
                type="text"
                name="search"
                id="search"
                placeholder="🔍 Search..."
                onChange={({ target: { value } }) => handleSearch(value)}
              />
            </div>
          </div>
          <CurrenciesListComponent tokens={searched} picked={picked} onSelect={selectCurrency} />
        </div>
      </div>
      <div className={style.rightColumn}>
        {props.prizePool}
        <PickedTokensComponent />
        <Button additionalClassnames={clsx(style.readyBtn, picked.length != maxCryptos && style.readyBtn_disabled)} variant='primary' disabled={picked.length != maxCryptos} onClick={readyToGame} children={'READY'}/>
      </div>
    </div>
  )
}
