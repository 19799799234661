import style from './in-game.module.scss';
import clsx from 'clsx';
import Table from '@components/shared/table/table.component';
import ChatComponent from './components/chat.component';
import PrizePoolComponent from './components/prize-pool.component';
import gameService from '@api/services/game.service';
import { WebsocketContext } from '@api/websocket-provider';
import { useAppSelector, useAppDispatch } from '@hooks/use-app-dispatch.hook';
import { useGetBalance } from '@hooks/use-get-balance.hook';
import { GameStateEnum, IGame } from '@models/game.interface';
import { WsResponseAction } from '@models/ws.interface';
import { selectGame, resetGames, setGame, removeGame } from '@store/game/games.slice';
import { selectUser, setBalance } from '@store/user/user.slice';
import { useState, useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CurrencyPickPage from './components/currencyPick/index.page';
import Music from '@pages/inGame/components/Music/music.component';
import Graph from './components/Graph/graph.component';
import GameLengthTitle from './components/GameLengthTitle/game-length-title.component';
import PickedTokensComponent from './components/currencyPick/components/picked-tokens.component';
import ResultModal from './components/ResultModal/result-modal.component';
import { timeAgo } from '@utils/utils';
import { clearPicked } from '@store/currency-pick/currency-picks.slice';
import GameHeader from '@components/shared/game-header/game-header.component';
import loadingImg from '@assets/icons/loading.png'



export default function InGamePage() {
  const [searchParameters] = useSearchParams()
  const paramId = searchParameters.get('id')! as unknown as number;
  const { state } = useAppSelector((state) => selectGame(state, paramId))
  const { id, balance } = useAppSelector(selectUser)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [expiryTime, setExpiryTime] = useState(new Date().toString())
  const [showResultModal, setShowResultModal] = useState(false)
  const { socket } = useContext(WebsocketContext)
  const { getBalance } = useGetBalance()
  const [timeOffset, setTimeOffset] = useState(0)
  const [musicPlaying, setMusicPlaying] = useState(false)
  const confirmResultAndRedirect = () => {
    setShowResultModal(false)
    dispatch(clearPicked(paramId))
    dispatch(removeGame(paramId))
    window.close();
  }

  // Get game info from startup
  const [prepareTime, setPrepareTime] = useState<any>(0);
  useEffect(() => {
    const requestGameInfo = async () => {
      setIsLoading(true)
      if (state === GameStateEnum.waiting) {
        return;
      }
      try {
        getBalance()
        const serverTimestamp = await gameService.getServerTimestamp()
        const offset = Date.now() - serverTimestamp.data
        setTimeOffset(offset)
        const queryId = searchParameters.get('id') as string
        const gameInfo = await gameService.getGameInfo(queryId) 
        if(!(gameInfo.data.players.map(player => player?.id!).includes(id!))){
          navigate('/custom-game');
          window.close();
        }
        dispatch(setGame(gameInfo.data))
        setIsLoading(false)
        if (state === GameStateEnum.preparation) {
          dispatch(clearPicked(paramId))
          const startsAt = new Date(gameInfo.data.startsAt)
          const gameStartDate = new Date(startsAt.getTime() + offset + 5000).toISOString()
          setExpiryTime(gameStartDate)
          const secondsToStart = new Date(gameStartDate).getTime() - Date.now()
          setPrepareTime(setTimeout(() => {
            setIsLoading(true)
          }, secondsToStart));
        } else {
          clearTimeout(prepareTime);
          const endsAt = new Date(gameInfo.data.endsAt)
          setExpiryTime(new Date(endsAt.getTime() + offset).toISOString())
          setMusicPlaying(true)
          // getBalance()
          // dispatch(setBalance(balance - gameInfo.data.bet))
        }
      } catch (error) {
        console.log(error)
      }
    }
    if (id && state !== GameStateEnum.ended) {
      requestGameInfo()
    }
  }, [dispatch, searchParameters, id, state])
  
  const columns = [ 'players', 'time', 'duration', 'bet', 'winner'];
  const [rows, setRows] = useState<any>([]);
  // Websocket listeners
  useEffect(() => {
    if (!socket) {
      return
    }
    const startGameListener = (data: IGame) => {
      dispatch(setGame(data))
      const endsAt = new Date(data.endsAt)
      setExpiryTime(new Date(endsAt.getTime() + timeOffset).toISOString())
      dispatch(setBalance(balance - data.bet))
      setMusicPlaying(true)
    }
    socket.on(WsResponseAction.gameStarted+ '_' + paramId, startGameListener)

    const endGameListener = (data: IGame) => {
      dispatch(setGame(data))
      setShowResultModal(true)
      setMusicPlaying(false)
      // getBalance()
    }
    socket.on(WsResponseAction.gameEnds+ '_' + paramId, endGameListener)

    const cancelGameListener = (data: any) => {
      alert(data)
      dispatch(clearPicked(paramId))
      dispatch(removeGame(paramId))
      window.close();
    }
    socket.on(WsResponseAction.gameCanceled+ '_' + paramId, cancelGameListener)

    
    const setBattles = (data: any) => {
      setRows(data.map(( (dataRow: any) => {
        if (dataRow.players.length != 2)
          return null;

        return { players : (dataRow.players ? dataRow.players[0].username + ' vs '+ dataRow.players[1].username : ''), time: timeAgo(dataRow.startsAt), duration: (dataRow.duration + 'Mins'), bet: (dataRow.bet + 'USDT'), winner: (dataRow.winner ? dataRow.winner.username : '')}
      })));
    }
    socket.on(WsResponseAction.battleLog, setBattles)

    return () => {
      socket.off(WsResponseAction.gameStarted+ '_'+paramId, startGameListener)
      socket.off(WsResponseAction.gameEnds+ '_'+paramId, endGameListener)
      socket.off(WsResponseAction.gameCanceled+ '_'+paramId, cancelGameListener)
    }
  }, [dispatch, socket, paramId])

  if (isLoading) {
    return (
      <div>
        <GameHeader gameType='Custom Game'/>
        <div className={style.loader}>
          <img src={loadingImg} alt='Loading'/>
        </div>
      </div>
    )
  }
  return (
    <div>
      <GameHeader gameType='Custom Game'/>
      <div className={style.mainContent}>
        {state === GameStateEnum.preparation ? (
          <CurrencyPickPage prizePool={<PrizePoolComponent expiryTime={expiryTime} gameId={paramId}/>} gameId={paramId}/>
        ) : (
          <div className={style.content}>
            <div className={style.leftColumn}>
              
            <PickedTokensComponent isGame gameId={paramId}/>

            <div className={clsx(style.wrapper, style.wrapper_inner, style.wrapper_withoutHeight, style.wrapper_hideLaptop)}>
                <ChatComponent gameId={paramId} />
              </div>
            </div>
            <div className={style.midColumn}>
              <div className={clsx(style.wrapper, style.wrapper_inner, style.wrapper_displayBlock, style.wrapper_graph)}>
                <div className={style.withMusicWrapper}>
                  <GameLengthTitle gameId={paramId}/>
                  <Music
                    playing={musicPlaying}
                    toggleAudio={() => setMusicPlaying(!musicPlaying)}
                    hidden={state !== GameStateEnum.started}
                  />
                </div>
                <Graph gameId={paramId}/></div>
            </div>
            <div className={style.rightColumn}>
              <PrizePoolComponent expiryTime={expiryTime} isGame gameId={paramId}/>
              <div className={clsx(style.checkLaptop, style.checkLaptop_visibleDesktop)}><Table rows={rows} columns={columns} fullRadius darkTheme isGame  thinRow thinBars/></div>
              <div className={clsx(style.checkLaptop, style.checkLaptop_visibleLaptop)}>
                <div className={clsx(style.wrapper, style.wrapper_inner, style.wrapper_withoutHeight, style.wrapper_inner)}>
                  <ChatComponent gameId={paramId} />
                </div>
              </div>
            </div>
          </div>
        )}
        <ResultModal isOpen={showResultModal} onClose={() => confirmResultAndRedirect()} gameId={paramId}/>
      </div>
    </div>
  )
}

