import type { ICurrency } from '@models/currency.interface'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from '@store/store'

interface ICurrencyPickSlice {
  picked: ICurrency[]
  opponentPick: ICurrency[]
}

const initialState: ICurrencyPickSlice = {
  picked: [],
  opponentPick: [],
}

export const currencyPickSlice = createSlice({
  name: 'pick',
  initialState,
  reducers: {
    pickCurrency: (state, action: PayloadAction<ICurrency>) => {
      state.picked.push(action.payload)
    },
    removePickedCurrency: (state, action: PayloadAction<ICurrency>) => {
      const findedIndex = state.picked.findIndex((item) => item.symbol === action.payload.symbol)
      state.picked.splice(findedIndex, 1)
    },
    setOpponentPick: (state, action: PayloadAction<ICurrency[]>) => {
      state.opponentPick = action.payload
    },
    setPicked: (state, action: PayloadAction<ICurrency[]>) => {
      state.picked = action.payload
    },
    clearPicked: (state) => {
      state.picked = []
      state.opponentPick = []
    },
  },
})

export const { pickCurrency, removePickedCurrency, setOpponentPick, setPicked, clearPicked } =
  currencyPickSlice.actions

export const selectPickedCurrencies = (state: RootState) => state.currencyPickReducer.picked

export const selectPickedByName = (state: RootState, name: string) =>
  state.currencyPickReducer.picked.find((item) => item.name === name)

export const selectOpponentPick = (state: RootState) => state.currencyPickReducer.opponentPick

export const selectPickedSymbols = (state: RootState) => {
  const myPickedSymbols = state.currencyPickReducer.picked.map((token) => {
    return { symbol: token.symbol }
  })
  const opponentPickedSymbols = state.currencyPickReducer.opponentPick.map((token) => {
    return { symbol: token.symbol }
  })
  return { myPickedSymbols, opponentPickedSymbols }
}

export default currencyPickSlice.reducer
