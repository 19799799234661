import type { ICurrency } from '@models/currency.interface'
import { ChainId } from '@usedapp/core'
import { BigNumber, BigNumberish, ethers } from 'ethers'

export const checkAndChangeChainId = async () => {
  // @ts-ignore
  const isMainnet = !!+process.env.REACT_APP_IS_MAINNET

  const chain = Number.parseInt(window.ethereum.chainId, 16)

  if (!isMainnet) {
    if (chain !== ChainId.Sepolia) {
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: BigNumber.from(ChainId.Sepolia).toHexString() }],
        })
      } catch (error: any) {
        if (error.code === 4902) {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: BigNumber.from(ChainId.Sepolia).toHexString(),
                chainName: 'Sepolia',
                rpcUrls: ['https://ethereum-sepolia.blockpi.network/v1/rpc/public'] /* ... */,
              },
            ],
          })
        }
        throw new Error('Did not change network')
      }
    }
  } else if (chain !== ChainId.BSC) {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: BigNumber.from(ChainId.BSC).toHexString() }],
      })
    } catch (error: any) {
      if (error.code === 4902) {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: BigNumber.from(ChainId.BSC).toHexString(),
              chainName: 'BSC Mainnet',
              rpcUrls: ['https://bsc-dataseed.binance.org'] /* ... */,
            },
          ],
        })
      }
      throw new Error('Did not change network')
    }
  }
}

export const roundNumber = (value: number) => {
  if (typeof value === 'number') {
    return value.toFixed(2)
  }
  return value
}

export const calculateTotalChange = (tokens: ICurrency[]) => {
  const res = tokens.reduce((previous, current) => previous + +current.change, 0)
  return roundNumber(res)
}

export const amountToWei = (value: string) => {
  return ethers.utils.parseUnits(value, 'ether')._hex
}
export const amountToWeiBigNumber = (value: string) => {
  return ethers.utils.parseUnits(value, 'ether')
}

export const isInputDecimalValid = (value: string) => {
  const hasDecimal = /[,.]/g.test(value)
  if (hasDecimal) {
    const [, decimal] = value.split('.')
    if (decimal && decimal.length > 2) {
      return false
    }
  }
  return true
}
export const timeAgo = (timestamp: number) =>  {
  const current = Date.now();
  const previous = new Date(timestamp).getTime();
  const elapsed =  current - previous;

  const seconds = 60000;
  const minutes = Math.floor(elapsed / seconds);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);
  if (elapsed < 60000) { // Less than a minute
      return 'now';
  } else if (minutes < 60) { // Less than an hour
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  } else if (hours < 24) { // Less than a day
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  } else if (days < 30) {
      return `${days} day${days > 1 ? 's' : ''} ago`;
  } else if (months < 12 ) {
    return `${months} month${months > 1 ? 's' : ''} ago`;
  } else {
    return `${years} year${years > 1 ? 's' : ''} ago`;
  }
}

