
import { useState } from 'react';
import styles from '../tournament.module.scss';
import Button from '@components/shared/button/button.component';
export default function CancelGamePopup (props: { gameId: number, onDelete: (gameId: number) => void, onCancel: () => void }) {
    return (
        <div className={styles.popover}>
            <h4> Are you Sure ? </h4>
            <Button additionalClassnames={styles.copyBtn} variant='primary' onClick={async () => { await props.onDelete(props.gameId)}}>Yes</Button>
            <Button additionalClassnames={styles.copyBtn} variant='secondary' onClick={props.onCancel}>No</Button>
        </div>
    )
}