import clsx from 'clsx';
import styles from './table.module.scss';
import logoImg from '@assets/icons/tradasy_logo@2x.png'
import Loader from '../loader/loader.component';

export interface ITableProperties {
  columns: any[];
  rows: any[];
  columnDivider?: boolean;
  rowNumber?: boolean;
  darkTheme?: boolean;
  showLogo?: boolean;
  isGame?: boolean;
  nonRadius?: boolean
  isLoading?: boolean;
  addCss?: any;
}

export default function LobyTable(props: ITableProperties) {
  const { columns, rows, columnDivider, rowNumber, darkTheme, isGame, nonRadius, addCss, isLoading } = props;

  const tableClassName = darkTheme ? styles.mainTable_dark : styles.mainTable_light;
  const cellClassName = columnDivider ? styles.columnDivider : '';
  return (
    <div className={clsx(styles.wrapper, isLoading && styles.wrapper_loading, addCss && addCss)}>
    { isLoading ? <Loader color='#7EFBEF'/> : 
      <table className={clsx(styles.mainTable, tableClassName)} >
      <thead>
        <tr>
          {rowNumber && <th>#</th>}
          {columns.map((column: any, index: number) => (
            <th key={index} className={clsx(styles.field, (columnDivider && index < columns.length - 1) && styles.columnDivider, column == 'id' && styles.hideCol)}>{column}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row: any, rowIndex: number) => (
          <tr key={rowIndex} className={ clsx(isGame && styles.gameRow, (rowIndex % 2 == 1 && !row.mine) && styles.secondaryRow, nonRadius && styles.thinRow, row.mine && styles.mineRow)}>
            {rowNumber && <td>{rowIndex + 1}</td>}
            {columns.map((col: any, colIndex: number) => (
              <td key={colIndex} className={`${cellClassName} ${columnDivider && colIndex < columns.length - 1 ? styles.columnDivider : ''}`}>
                {row[col]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
      { props.showLogo && 
      <tfoot>
        <tr>
          <th className={styles.footer} colSpan={columns.length}>
              <img src={logoImg}/>
          </th>
        </tr>
      </tfoot>
      }
    </table>}
    </div>
  );
}
