import style from './loby.module.scss';
import LabyTableComponent from './components/loby-table.component';
import CustomPoolComponent from './components/custom-pool.component';
import { WebsocketContext } from '@api/websocket-provider';
import {  WsResponseAction } from '@models/ws.interface'
import { useAppDispatch, useAppSelector, } from '@hooks/use-app-dispatch.hook';
import { useContext, useEffect, useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import Loader from '@components/shared/loader/loader.component';
import Button from '@components/shared/button/button.component';
//import { setGameId } from '@store/game/game.slice';
import { selectIsLoggedIn, selectUser } from '@store/user/user.slice';
import BattleLogComponent from '@pages/home/components/battleLog/batte-log.component';
import GameHeader from '@components/shared/game-header/game-header.component';
import clsx from 'clsx';
import GameLog from '@components/shared/gameLog/game-log.component';



export default function LobyPage() {
  const navigate = useNavigate()
  const { id } = useAppSelector(selectUser);
  const { socket } = useContext(WebsocketContext)
  const dispatch = useAppDispatch()
  const [refreshTime, setRefreshTime] = useState(Date.now())

  const popitup = (url: string, windowName: string) => {
    const newwindow=window.open(url,windowName,'height=1920,width=1080');
    if (newwindow)
      newwindow.focus()
    else {
      alert('Pop-up blocked! Please enable pop-ups for this site');
      navigate(url, { replace: false, relative: 'path' })
    }
  }
  
  useEffect(() => {
    const interval = setInterval(() => {
      setRefreshTime(Date.now())
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    if (socket === null) {
      return
    }
    const gameReadyListener = (data: number) => {
      popitup('https://app.tradasy.io/game?id='+ data.toString(), 'game' + data.toString());
      setRefreshTime(Date.now());
    }
    const gameNotReadyListener = (data: any) => {
      alert(data);
      setRefreshTime(Date.now());
    }

    socket.on(WsResponseAction.gameReady + '_' + id, gameReadyListener)
    socket.on(WsResponseAction.gameNotReady + '_' + id, gameNotReadyListener)
  }, [dispatch, navigate, socket, id])
  if (!socket) {
    return (
      <div className={style.loader}>
        <Loader color="#02959f" />
        <p>Establishing a connection...</p>
      </div>
    )
  }
  return (
    <div>
      <GameHeader/>
    <div className={style.mainContent}>
      <div className={style.title}>Select Game Type
        
        <div className={style.titleButtons}>
          <Button additionalClassnames={style.btns} variant='secondary' onClick={() => { navigate('/standard-game') }}> Standard</Button>
          <Button additionalClassnames={clsx(style.btns, style.btns_selected)} variant='primary' onClick={() => { navigate('/custom-game') }}> Custom</Button>
          <Button additionalClassnames={style.btns} variant='secondary' onClick={() => { navigate('/tournament') }}> tournament</Button>
        </div>
      </div>
      <div className={style.content}>
        <div className={style.leftColumn}>
          <LabyTableComponent refreshTime={refreshTime}/>
        </div>
        <div className={style.rightColumn}>
          <CustomPoolComponent setRefreshTime={setRefreshTime}/>
        </div>
      </div>
      <div className={style.content}>
        <GameLog/>
      </div>
    </div>
    </div>
  )
}
