import {useNavigate} from "react-router-dom";
import styles from '@components/shared/navigation/navigation.module.scss'
import Button from "@components/shared/button/button.component";
import {useLogin} from "@hooks/use-login.hook";
import clsx from "clsx";

export default function Navigation( props: { isLogged?: boolean, gameType?: string, isHome?: boolean}): JSX.Element {
    const { executeLogin, logout } = useLogin()
    const navigate = useNavigate()

    const handleLogin = async () => {
        await executeLogin()
    }
  return (
    <nav className={styles.Navigation} role="navigation">
      <ul>
        { props.gameType  ? <li style={{ color: 'white'}}>{props.gameType}</li> : <>
        { !props.isLogged &&<li onClick={()=>navigate('/')}> Home </li>}
        <li onClick={()=>navigate('/custom-game')}> Game Lobby </li>
        { !props.isLogged &&<li onClick={()=>navigate('/faq')}> FAQ </li>}
        <li onClick={()=>navigate('/contact')}> { props.isLogged ? 'Support': 'Contact'} </li>
        { !props.isLogged && <li onClick={()=>navigate('/about')}> About </li>}
        { (!props.isLogged && props.isHome) && <Button additionalClassnames={clsx(styles.connectBtn,(props.isHome && !props.isLogged) && styles.connectBtn_notLogged) } onClick={handleLogin}>Connect</Button> }</>}
      </ul>
    </nav>
  )
}
