import { callGetUserBalance } from '@blockchain/game-contract-api'
import { selectUser, setBalance } from '@store/user/user.slice'
import { useEthers } from '@usedapp/core'

import { useAppDispatch, useAppSelector } from './use-app-dispatch.hook'

export function useGetBalance() {
  const {  library } = useEthers()
  const { wallet} = useAppSelector(selectUser);
  const dispatch = useAppDispatch()

  const getBalance = async () => {
    if (wallet) {
      const amount = await callGetUserBalance(wallet, library)
      dispatch(setBalance(+amount))
    }
  }

  const getBalanceByWallet = async (addrs: string) => {
    const amount = await callGetUserBalance(addrs, library)
    dispatch(setBalance(+amount))
  }

  return { getBalance, getBalanceByWallet }
}
