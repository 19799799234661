import { getRequest, postRequest } from '@api/axios-client'
import type { ICurrencyHistory } from '@api/response.interface'
import type { ICurrency } from '@models/currency.interface'
import type { IGame, IGameLog } from '@models/game.interface'

class GameService {
  async getGameInfo(gameId: string) {
    return await getRequest<IGame>(`api/game/id/${gameId}`)
  }
  async cancelGame(gameId: number) {
    return await getRequest<IGame>(`api/game/cancel/${gameId}`)
  }

  async getCryptosList() {
    return await getRequest<ICurrency[]>('api/crypto/all')
  }

  async getCryptoHistory(requestedTokens: string[], date: string) {
    //const requestedTime = new Date(Date.now() - 5 * 60_000)
    return await postRequest<ICurrencyHistory>(`api/crypto/story/special`, { date: date, symbols: requestedTokens })
  } 

  async getGameHistory(gameId: number) {
    return await getRequest(`api/crypto/story/game/${gameId}`)
  }

  async getRoundHistory(roundId: number) {
    return await getRequest(`api/crypto/story/round/${roundId}`)
  }
  async getGameLogs(userId: number) {
    return await getRequest<IGameLog[]>(`api/game/logs/${userId}`)
  }
  async getBattleLog() {
    return await getRequest<any[]>(`api/game/battlelogs`)
  }

  async getServerTimestamp() {
    return getRequest<number>('api/sync/time')
  }
  async getGames(filter?: number[]) {
    return await getRequest<any>(`api/game/games/${filter}`);
  }
  async createGame(userId: number, duration: number, bet: number,inviteCode: string, isPrivate: boolean, maxCryptos: number) {
    return await postRequest<any>('api/game/create', { userId, duration, bet, inviteCode, isPrivate, maxCryptos });
  }
  async getInvitedGame(inviteCode: string) {
    return await getRequest<any>(`api/game/invite/${inviteCode}`)
  }
  async joinGame(userId: number, gameId: number) {
    return await postRequest<any>('api/game/join', { userId, gameId});
  }
  
}

export default new GameService()
