import linkedinIcon from '@assets/icons/linkedin.svg'
import footerLogo from '@assets/icons/logo-small.png'
import twitterIcon from '@assets/icons/twitter.svg'
import discordIcon from '@assets/icons/discord.svg'
import faqLogo from '@assets/icons/faq.svg'
import { Link } from 'react-router-dom'

import styles from './footer.module.scss'
import { useAppSelector } from '@hooks/use-app-dispatch.hook'
import { selectIsLoggedIn } from '@store/user/user.slice'

interface IFooterProperties {}

export default function Footer(props: IFooterProperties): JSX.Element {

  const isLogged = useAppSelector(selectIsLoggedIn);

  return (
    <footer className={styles.footer}>
      { isLogged && <img className={styles.footerLogo} src={footerLogo} alt="Tradasy" />}
      <div className={styles.social}>
        { isLogged && <Link to={'/faq'}>
          FAQ<img src={faqLogo} alt="FAQ" />
          </Link>
        }
        <Link to={'/'} target={'_blank'}>
          <img src={twitterIcon} alt="Twitter" />
        </Link>
        <Link to={'/'} target={'_blank'}>
          <img src={discordIcon} alt="Discord" />
        </Link>
        <Link to={'/'} target={'_blank'}>
          <img src={linkedinIcon} alt="Linkedin" />
        </Link>
      </div>
    </footer>
  )
}
