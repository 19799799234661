import Button from '@components/shared/button/button.component';
import styles from '../tournament.module.scss';
import gameService from '@api/services/game.service';
import { useContext, useEffect, useState } from 'react';
import authService from '@api/services/auth.service';
import { WebsocketContext } from '@api/websocket-provider';
import { WsAction } from '@models/ws.interface';
import LobyTable from '@components/shared/loby-table/table.component';
import clsx from 'clsx';
import { useAppSelector } from '@hooks/use-app-dispatch.hook';
import { selectIsLoggedIn, selectUser } from '@store/user/user.slice';
import inviteLogo from '@assets/icons/invite.png';
import CancelGamePopup from './cancel-game-popup.component';
import { useLogin } from '@hooks/use-login.hook';
import tournamentService from '@api/services/tournament.service';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { IRound, ITournament } from '@store/tournament/tournament.model';
import Loader from '@components/shared/loader/loader.component';
import { GameStateEnum } from '@models/game.interface';

export default function TournamentTableComponent(props: { refreshTime: number, percentFee: number}) {
  const isLogged = useAppSelector(selectIsLoggedIn)
  const navigate = useNavigate()
  const { id, balance } = useAppSelector(selectUser);
  const cols = ['id', 'entry fee','coin', 'round time', 'prize pool','players','status'];
  const { socket } = useContext(WebsocketContext)
  const [rows, setRows] = useState<any[]>([]);
  const [filter, setFilter] = useState('all')
  const [popupGame, setPopupGame] = useState<number |null>(null);
  const [loading, setLoading] = useState(true);
  const popitup = (url: string, windowName: string) => {
    const newwindow=window.open(url,windowName,'height=1920,width=1080');
    if (newwindow)
      newwindow.focus()
    else {
      alert('Pop-up blocked! Please enable pop-ups for this site');
      navigate(url, { replace: false, relative: 'path' })
    }
  }
  const cancelGame = async (id: number) => {
    setPopupGame(null);
    const cancelState = await tournamentService.cancelTournament(id)
      if (cancelState.status == 200){
        alert('Game Canceled!');
      }
    getSearchs('scheduled');
  }
  const [joinState, setJoinState] = useState(true);
  useEffect(() => {
    if (joinState) {
      const interval = setInterval(() => {
        setJoinState(false);
        setLoading(false);
        getSearchs(filter);
      }, 2000);
      return () => clearInterval(interval);
    }
    return;
  }, [joinState, filter]);
  useEffect(() => {
    getSearchs(filter, false);
  }, [props.refreshTime])
  useEffect(() => {
    if (id != null)
      getSearchs('all')
  }, [id])
  const getStatusFromGame = (id: number) => {
    if (id == 4) return 'CANCELED'
    if (id == 3) return 'COMPLETED'
    if (id == 2) return 'FULL'
    if (id == 1) return 'FULL'
    if (id == 0) return 'JOIN'

    return 'COMPLETED'
  }
  const { executeLogin } = useLogin()
  const getGameButton = (id: number, state: number, isMine: boolean, inTournament: boolean, bet: number) => {
    if (state == 0) {
      return (
        <div className={styles.tableBtns}>
          { !isMine ? <Button
          additionalClassnames={styles.tableBtn}
            variant={ inTournament ? 'secondary' : "primary" }
            onClick={() => {
              if (isLogged) {
                if (inTournament)
                  leave(id)
                else 
                  join(id, bet)
              } else {
                executeLogin()
              }
            }}
            title='Open Tournament'
          >{ inTournament ? '⧖ - Leave' :  'Join'}</Button>
          :
          <Button
            additionalClassnames={clsx(styles.tableBtn, styles.text_negative)}
            variant="primary"
            onClick={() => {
              /*cancelGame(id)*/
              setPopupGame(id);
              //getSearchs('scheduled')
            }}
            title='Cancel Game'
          >cancel</Button>}
        </div>
      )
    }
    if (inTournament && (state == 2 || state == 1)) {
      return ( <div className={styles.tableBtns}>
        <Button
          additionalClassnames={styles.tableBtn}
          variant="primary"
          onClick={() => { rejoin(id) }}
        >
          Re-Join
        </Button></div>
      )
    }
    return <a className={styles.joinBtn}>{getStatusFromGame(state)}</a>
  }

  const filtered = (filterText: string) => {
    if (filterText == 'all')
      return undefined;
    if (filterText == 'scheduled')
      return [0];
    if (filterText == 'running')
      return [1, 2];

    return [3];
  }
  const copyToClipboard = (text: string) => {
    const textArea = document.createElement('textarea');
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
  
      try {
        document.execCommand('copy');
        alert('Invite URL Copied');
      } catch (err) {
        console.error('Unable to copy to clipboard', err);
      } finally {
        document.body.removeChild(textArea);
      }
  };

  const getSearchs = async (filter: string, loading = true) => {
    setLoading(loading);
    setPopupGame(null);
    setFilter(filter)
    const searchs = await tournamentService.getTournaments(filtered(filter))
    if (searchs.status == 200) {
      const rendered = searchs.data.map((row: any) => {
        const isMine = (row.owner && row.owner.id == id);
        const inTournament = (row.players as any[]).find(player => player.id == id) != null;
        if (row.isPrivate && !isMine && row.state == 0) 
          return null;

        return {
          ...{ 'entry fee': row.bet + '+' + Number(row.bet) * (props.percentFee / 100) +'USDT', coin: row.maxCryptos },
          players: <div className={styles.tableBtns}>{(row.players.length + '/' + row.maxPlayers)} { (isMine && row.state == 0) && <Button
            variant="primary"
            additionalClassnames={clsx(styles.tableBtn, styles.tableBtn_invite)}
            onClick={() => {
              copyToClipboard('https://app.tradasy.io/invite?type=tournament&code=' + row.invite.code!);
            }}
            title='Invite a friend'
          ><img src={inviteLogo} alt='Invite a friend'/></Button>}</div>,
          'round time': (row.currentRound + '/' + row.maxRound),
          'prize pool': (row.players.length * row.bet) + 'USDT',
          status: getGameButton(row.id, row.state, isMine, inTournament, Number(row.bet) * (props.percentFee / 100)),
          mine: (isMine && row.state == 0),
          id: ('T-' +row.id),
        }
      })
      setRows(rendered.filter((row: any) => row !=null))
      setLoading(false)
    }
  }

  const rejoin = async (tournamentId: any) => {
    
    if (!isLogged || id == null) {
      executeLogin()
      return;
    }
    const tournamentState = await tournamentService.userTournamentState(id, tournamentId);
    if (tournamentState.status == 201 && tournamentState.data) {
      const round = tournamentState.data as IRound;
      if (round.state == GameStateEnum.preparation || round.state == GameStateEnum.started) {
        popitup('https://app.tradasy.io/tournament-map?id='+tournamentId.toString() + '&round=' + round.id.toString(),'tournament' +  tournamentId.toString());
        return;
      }

    }
    
    
    popitup('https://app.tradasy.io/tournament-map?id='+tournamentId.toString(),'tournament' +  tournamentId.toString())
  }
  const join = async (tournamentId: any, bet: number) => {
    setPopupGame(null);

    if (!isLogged) {
      executeLogin()
      return;
    }
    if (balance < bet ) {
      alert('Not enough USDT');
      return;
    }
    const joinParameters = {
      tournamentId: tournamentId,
      userId: id,
    }

    socket.emit(WsAction.join_tournament, joinParameters)
    setJoinState(true);
    setLoading(true);
  }

  const leave = async (tournamentId: any) => {
    setPopupGame(null);
    if (!id) {
      alert('An error occured');
      return;
    }
    const leaveState = await tournamentService.leaveTournament(id, tournamentId);
    
    if (leaveState.status == 201)
      alert('Leaved Tournament: T-'+tournamentId)
    else 
      alert('An error occured');
    getSearchs(filter);
  }
  return (
    <>
    <div className={styles.columnHeader}> 
      <Button additionalClassnames={clsx(styles.filterBtn, filter == 'all' && styles.filterBtn_selected)} variant={(filter == 'all' ? 'primary' : 'secondary')} children={'All'} onClick={() => { getSearchs('all');}}/>
      <Button additionalClassnames={clsx(styles.filterBtn, filter == 'scheduled' && styles.filterBtn_selected)}  variant={(filter == 'scheduled' ? 'primary' : 'secondary')} children={'Scheduled'} onClick={() => { getSearchs('scheduled');}}/>
      <Button additionalClassnames={clsx(styles.filterBtn, filter == 'running' && styles.filterBtn_selected)}  variant={(filter == 'running' ? 'primary' : 'secondary')} children={'Running'} onClick={() => { getSearchs('running');}}/>
      <Button additionalClassnames={clsx(styles.filterBtn, filter == 'completed' && styles.filterBtn_selected)}  variant={(filter == 'completed' ? 'primary' : 'secondary')} children={'Completed'} onClick={() => {  getSearchs('completed');}}/>
    </div>
    <LobyTable rows={rows} columns={cols} darkTheme columnDivider isLoading={loading} addCss={styles.tableCustom}/>
    { popupGame != null && <CancelGamePopup onCancel={() => { setPopupGame(null)}} gameId={popupGame} onDelete={cancelGame}/> }
    </>
  );
}