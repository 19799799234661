/* eslint-disable */
import Button from '@components/shared/button/button.component'
import Loader from '@components/shared/loader/loader.component'
import type { IGameVariant } from '@constants/game-variants.constant'
import { betNumberToEnum } from '@constants/game-variants.constant'
import clsx from 'clsx'
import Slider from 'rc-slider'
import React, { useState } from 'react'

import styles from './SearchCard/search-card.module.scss'
import CardContent from './card-content.component'

interface ISearchCardProperties {
  card: IGameVariant
  selectedGame: IGameVariant | null
  isSearchStarted: boolean
  startSearching: (card: IGameVariant, bet: number) => void
  cancelSearching: () => void
  error: string
  freeplay?: boolean
}

export default function Card(props: ISearchCardProperties) {
  const { card, freeplay, selectedGame, isSearchStarted, startSearching, cancelSearching, error } = props
  const [buyInState, setBuyInState] = useState(card.betNumber)

  const handleStartSearch = () => {
    startSearching(card, buyInState)
  }

  return (
    <article
      className={clsx(
        styles.card,
        isSearchStarted && card !== selectedGame && styles.card_disabled
      )}
    >
      <div>
        <h3 className={styles.duration}>
          <span>{card.duration}</span> minutes
        </h3>
        <p className={styles.descr}>
          Choose <span>{card.maxCryptos}</span> cryptocurrencies
        </p>
      </div>
      {isSearchStarted && card === selectedGame ? (
        <div className={clsx(styles.loaderWrapper, !freeplay && styles.loaderWrapper_safe)}>
          <Loader />
          Searching for opponent
        </div>
      ) : (
        <CardContent  freeplay={freeplay} setBuyInState={setBuyInState} buyInState={buyInState}/>
      )}
      

      {isSearchStarted && card === selectedGame ? (
        <Button additionalClassnames={styles.btn} onClick={cancelSearching}>
          Cancel
        </Button>
      ) : (
        <Button additionalClassnames={styles.btn} onClick={handleStartSearch}>
          Play
        </Button>
      )}
      {card === selectedGame && error && <small className={styles.error}>{error}</small>}
    </article>
  )
}
