import { ReactComponent as DownIcon } from '@assets/icons/down-change.svg'
import { ReactComponent as UpIcon } from '@assets/icons/up-change.svg'
import { roundNumber } from '@utils/utils'
import clsx from 'clsx'
import React from 'react'

import styles from './token-change.module.scss'

interface ITokenChangeProperties {
  value: number | string;
  showIcon?: boolean;
  preparetion?: boolean;
  opp?: boolean;
}

export default function TokenChange({ value, showIcon = true, preparetion, opp}: ITokenChangeProperties) {
  if (preparetion && opp ) {
    return <></>
  }
  return (
    <div className={clsx(styles.change, value < 0 && styles.change_negative, preparetion && styles.change_prepare)}>
      {showIcon && (value < 0 ? <DownIcon /> : <UpIcon />)}
      <div id='label'>{ !showIcon && `${value < 0 ? '' : '+'}`}{`${roundNumber(+value)}`}%</div>
    </div>
  )
}
