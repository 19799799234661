import { useAppSelector } from '@hooks/use-app-dispatch.hook'
import style from '../in-game.module.scss';
import clsx from 'clsx';
import Timer from './Timer/timer.component';
import tetherLogo from '@assets/icons/tether.png'
import { GameStateEnum } from '@models/game.interface';


export default function MapPrizePoolComponent(props: { expiryTime: any, info: string, state: number}) {
    return (
        <div className={style.infoWrapper}>
          <div className={style.info}>
            {props.info}
          </div>
          <div className={style.timer}><Timer expiry={props.expiryTime} requestRestart={props.state != GameStateEnum.canceled && props.state != GameStateEnum.ended } /></div>
        </div>
    )
  }
  