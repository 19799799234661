import style from './tournament.module.scss';
import TournamentTableComponent from './components/tournament-table.component';
import CustomPoolComponent from './components/custom-pool.component';
import { WebsocketContext } from '@api/websocket-provider';
import {  WsResponseAction } from '@models/ws.interface'
import { useAppDispatch, useAppSelector, } from '@hooks/use-app-dispatch.hook';
import { useContext, useEffect, useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import Loader from '@components/shared/loader/loader.component';
import Button from '@components/shared/button/button.component';
import BattleLogComponent from '@pages/home/components/battleLog/batte-log.component';
import GameHeader from '@components/shared/game-header/game-header.component';
import clsx from 'clsx';
import GameLog from '@components/shared/gameLog/game-log.component';
import { selectUser } from '@store/user/user.slice';
import tournamentService from '@api/services/tournament.service';



export default function TournamentPage() {
  const navigate = useNavigate()
  const { socket } = useContext(WebsocketContext)
  const dispatch = useAppDispatch()
  const [refreshTime, setRefreshTime] = useState(Date.now())
  const { id } = useAppSelector(selectUser)
  const [percentFee, setPercentFee] = useState(12);

  const popitup = (url: string, windowName: string) => {
    const newwindow=window.open(url,windowName,'height=1920,width=1080');
    if (newwindow)
      newwindow.focus()
    else {
      alert('Pop-up blocked! Please enable pop-ups for this site');
      navigate(url, { replace: false, relative: 'path' })
    }
  }
  useEffect(() => {
    const getPercentFee = async () => {
      const percentState = await tournamentService.getPercentFee();
      if (percentState.status == 200) {
        setPercentFee(percentState.data);
      }
    }
    const interval = setInterval(() => {
      setRefreshTime(Date.now())
    }, 5000);

    getPercentFee();
    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    if (socket === null) {
      return
    }
    const tournamentReadyListener = (data: any) => {
      popitup('https://app.tradasy.io/tournament-map?id='+ data.tournament+'&round='+ data.round,'tournament' +   data.tournament.toString());
      setRefreshTime(Date.now());
    }
    const tournamentOpener= (data: number) => {
      popitup('https://app.tradasy.io/tournament-map?id='+ data.toString(), 'tournament' + data.toString());
      setRefreshTime(Date.now());
    }
    const tournamenteNotReadyListener = (data: any) => {
      alert(data);
      setRefreshTime(Date.now());
    }

    socket.on(WsResponseAction.tournamentNotReady + '_' + id, tournamenteNotReadyListener)
    socket.on(WsResponseAction.roundReady + '_' + id, tournamentReadyListener)
    socket.on(WsResponseAction.tournamentOpen + '_' + id, tournamentOpener)

    return () => {
        socket.off(WsResponseAction.roundReady + '_' + id)
        socket.off(WsResponseAction.tournamentOpen + '_' + id)
    }
  }, [navigate, socket, id])
  
  if (!socket) {
    return (
      <div className={style.loader}>
        <Loader color="#02959f" />
        <p>Establishing a connection...</p>
      </div>
    )
  }
  return (
    <div>
      <GameHeader/>
    <div className={style.mainContent}>
      <div className={style.title}>Select Game Type
        
        <div className={style.titleButtons}>
          <Button additionalClassnames={style.btns} variant='secondary' onClick={() => { navigate('/standard-game') }}> Standard</Button>
          <Button additionalClassnames={style.btns} variant='secondary' onClick={() => { navigate('/custom-game') }}> Custom</Button>
          <Button additionalClassnames={clsx(style.btns, style.btns_selected)} variant='primary' onClick={() => { navigate('/tournament') }}> tournament</Button>
        </div>
      </div>
      <div className={style.content}>
        <div className={style.leftColumn}>
          <TournamentTableComponent refreshTime={refreshTime} percentFee={percentFee}/>
        </div>
        <div className={style.rightColumn}>
          <CustomPoolComponent setRefreshTime={setRefreshTime} percentFee={percentFee}/>
        </div>
      </div>
      <div className={style.content}>
        <GameLog/>
      </div>
    </div>
    </div>
  )
}
