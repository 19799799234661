import logoImg from '@assets/icons/profile-icon.png'
import style from './contact.module.scss';
import { useState } from 'react';
import clsx from 'clsx';
import Button from '@components/shared/button/button.component';
import GameHeader from '@components/shared/game-header/game-header.component';



export default function ContactPage() {
    const [inputError, setInputError] = useState('');
  return (
    <div>
    <GameHeader/>
    <div className={style.content}>
        <div className={style.title}>Contact</div>
        <form className={style.form}>
            <div className={style.midColumn}>
                <input
                    type="text"
                    className={clsx('input', inputError && 'input_error')}
                    required
                    step=".01"
                    placeholder='NAME'
                />
              <input
                type="text"
                className={clsx('input', inputError && 'input_error')}
                required
                step=".01"
                placeholder='E-MAIL'
              />
            </div>
            <div className={style.fullCollumn}>
              <textarea
                className={clsx('input',inputError && 'input_error')}
                required
                placeholder='...'
              />
            </div>
            <div className={style.fullCollumn}>
              <Button type="submit" additionalClassnames={style.btn}  disabled={!!inputError}>
                Send
              </Button>
            </div>
            </form>
    </div>
    </div>
  )
}

