import style from './faq.module.scss';
import ParagrapListComponent from './components/paragraph-list.component';
import GameHeader from '@components/shared/game-header/game-header.component';

export default function FaqPage() {
  const faqData = [
    {
      question: 'What is Tradasy?',
      answer:
        'Tradasy is a fantasy finance platform, where players select a certain number of cryptocurrencies for a given time period, and compete with others for cash prizes.',
    },
    {
      question: 'Why Tradasy?',
      answer:
        'Tradasy offers crypto traders/enthusiasts the ability to test their skills and generate profit according to their performance against other players.',
    },
    {
      question: 'How do I enter a contest?',
      answer:
        'It is fairly simple. You connect your crypto wallet, deposit funds and select the type of contest you want to enter according to the buy-in amount and duration.',
    },
    {
      question: 'Are there free games?',
      answer:
        'Absolutely. You can try Tradasy for free until you feel ready to compete for real money.',
    },
    {
      question: 'What types of contests?',
      answer:
        'We currently offer 10 minute, 30 minute and 60 minute contests, in which, you can buy-in for 1 USDT, 2 USDT, 5 USDT or 10 USDT.',
    },
    {
      question: 'How many people enter a contest?',
      answer: 'The contests we currently offer are 1vs1 (head-to-head) format.',
    },
    {
      question: 'How many cryptocurrencies do I pick?',
      answer:
        'For the 10 minute game you pick 3 cryptocurrencies. For the 30 minute game you pick 4 cryptocurrencies. For the 60 minute game you pick 5 cryptocurrencies.',
    },
    {
      question: 'How do I withdraw my winnings?',
      answer: 'You can withdraw your winnings to your crypto wallet any time you like.',
    },
    {
      question: 'How is the performance of the portfolio calculated?',
      answer:
        'All cryptocurrencies in your portfolio are distributed equally. At the end of the contest, the percentage change of each cryptocurrency is added together and an overall portfolio profit percentage is formed.',
    },
    {
      question: 'How is the winner determined?',
      answer:
        'At the end of the contest the player who has profited more/lost less is the winner.',
    },
    {
      question: 'Is Tradasy legal?',
      answer:
        'Since Fantasy Finance games are considered games of skill, Tradasy is completely legal under federal law.',
    },
    {
      question: 'Any other questions?',
      answer: (
        <>
          Please email us at{' '}
          <a href="mailto:support@tradasy">support@INSERT TRADASY DOMAIN</a> and we will get
          back to you shortly.
        </>
      ),
    },
  ]
  return (
    <div>
      <GameHeader/>
      <div className={style.title}>Frequently Asked Questions</div>
      <div className={style.content}>
          <ParagrapListComponent values={faqData} icon darkTheme/>
      </div>
    </div>
  )
}

