// import { lazy } from 'react'
import HomePage from '@pages/home/index.page'
import SearchPage from '@pages/search/index.page'
import LobyPage from '@pages/loby/index.page'
import ContactPage from '@pages/contact/index.page'
import InGamePage from '@pages/inGame/index.page'
import ProfilePage from '@pages/profile/index.page'
import AboutPage from '@pages/about/index.page'
import FaqPage from '@pages/faq/index.page'
import BotGamePage from '@pages/botGame/index.page'
import InvitePage from '@pages/invite/index.page'
import TournamentPage from '@pages/tournament/index.page'
import TournamentGamePage from '@pages/tournamentGame/index.page'
import TournamentMapPage from '@pages/tournament-map/index.page'

interface IRoutesProperties {
  path: string
  component: () => JSX.Element
  exact?: boolean
  requireAuth: boolean
}

const ROUTES: IRoutesProperties[] = [
  {
    path: '/',
    component: HomePage,
    exact: true,
    requireAuth: false,
  },
  { path: '/standard-game', component: SearchPage, requireAuth: false },
  { path: '/game', component: InGamePage, requireAuth: true },
  { path: '/custom-game', component: LobyPage, requireAuth: false },
  { path: '/tournament-game', component: TournamentGamePage, requireAuth: true },
  { path: '/tournament-map', component: TournamentMapPage, requireAuth: true },
  { path: '/ai-game', component: BotGamePage, requireAuth: true },
  { path: '/profile', component: ProfilePage, requireAuth: true },
  { path: '/faq', component: FaqPage, requireAuth: false },
  { path: '/contact', component: ContactPage, requireAuth: false },
  { path: '/about', component: AboutPage, requireAuth: false },
  { path: '/invite', component: InvitePage, requireAuth: false },
  { path: '/tournament', component: TournamentPage, requireAuth: false },
]

// //If react suspense using
// const LAZY_ROUTES = [
//   {
//     path: "/",
//     component: lazy(() => import("../../pages/home/home.page")),
//     exact: true,
//   },
// ];

export default ROUTES
