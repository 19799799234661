import { ICurrency } from '@models/currency.interface';
import style from '../currency-pick.module.scss'
import Button from '@components/shared/button/button.component'
import TokenChange from '@components/TokenChange/token-change.component';
import { roundNumber } from '@utils/utils';
import { useAppSelector } from '@hooks/use-app-dispatch.hook';
//import { selectPickedByName } from '@store/currency-pick/currency-pick.slice';
import { useEffect, useState } from 'react';
interface ICurrenciesProps {
    tokens: ICurrency[];
    picked: ICurrency[];
    onSelect: (token: ICurrency) => void;
}
export default function CurrenciesListComponent(props: ICurrenciesProps) {
  return (
    <div className={style.tokenlist}>
      {props.tokens.map((token) => {
        const state = props.picked.find(pickedToken => pickedToken.symbol == token.symbol);
        return (
          <Button
            additionalClassnames={style.additionTokenButton}
            variant={state ? 'primary' : 'secondary'}
            key={token?.symbol}
            onClick={() => !state && props.onSelect(token)}
          ><img src={`https://www.cryptocompare.com${token.image}`} className={style.tokenIcon} alt={token.symbol}/>
          <div className={style.currenciesTokenLabel}>{token.name}</div>
          <p className={style.changeLabel}><TokenChange value={roundNumber(+token.change)} showIcon={false}/> </p>
          <div className={style.currency}>/{roundNumber(+token.currency)}$</div>
          </Button>
        )
      })}
    </div>
  )
}
