
import { useState } from 'react';
import styles from '../loby.module.scss';
import friendImg from '@assets/icons/Friends.svg';
import Button from '@components/shared/button/button.component';
export default function InviteFriend (props: { generatedLink: string }) {
    return (
        <div className={styles.popover}>
            <h4> Generate Link </h4>
            <img src={friendImg} />
            <div className={styles.generatedLink}>{props.generatedLink}</div>
            <p>{'(Battle will start when the player clicks the link.)'}</p>
            <Button additionalClassnames={styles.copyBtn} variant='primary' onClick={() => {navigator.clipboard.writeText(props.generatedLink)}}>Copy</Button>
        </div>
    )
}