import type { ICurrency } from '@models/currency.interface'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from '@store/store'
import { useDispatch } from 'react-redux'

interface ICurrencyPickSlice {
  picked: ICurrency[]
  opponentPick: ICurrency[]
}
interface ICurrencyPicksSlice {
    picks: { [id: number]: ICurrencyPickSlice };
}
const initialState: ICurrencyPicksSlice = {
  picks: []
}
const defaultPicks:ICurrencyPickSlice  = { 
    picked: [],
    opponentPick: [],
}

export const currencyPicksSlice = createSlice({
  name: 'picks',
  initialState,
  reducers: {
    pickCurrency: (state, action: PayloadAction<{ id: number, currency: ICurrency}>) => {
        const { id, currency } = action.payload;
        
        if (!state.picks[id])
            state.picks[id] = defaultPicks

        const pick = state.picks[id]
        if (pick) 
            pick.picked.push(currency)
    },
    removePickedCurrency: (state, action: PayloadAction<{ id: number, currency: ICurrency}>) => {
        const { id, currency } = action.payload;
        
        if (!state.picks[id])
            state.picks[id] = defaultPicks

        const pick = state.picks[id]
        if (pick) {
            const findedIndex = pick.picked.findIndex((item) => item.symbol === action.payload.currency.symbol)
            pick.picked.splice(findedIndex, 1)
        }
    },
    setOpponentPick: (state, action: PayloadAction<{ id: number, currencies: ICurrency[]}>) => {
        const { id, currencies } = action.payload;
        if (!state.picks[id])
            state.picks[id] = defaultPicks

        const pick = state.picks[id]
        if (pick)
            pick.opponentPick = currencies
    },
    setPicked: (state, action: PayloadAction<{ id: number, currencies: ICurrency[]}>) => {
        const { id, currencies } = action.payload;
        if (!state.picks[id])
            state.picks[id] = defaultPicks

        const pick = state.picks[id]
        if (pick)
            pick.picked = currencies
    },
    clearPicked: (state, action: PayloadAction<number>) => {
        const id = action.payload;
        if (!state.picks[id])
            state.picks[id] = defaultPicks
        
        const pick = state.picks[id]
        if (pick) {
            pick.picked = []
            pick.opponentPick = []
        }
    },
  },
})

export const { pickCurrency, removePickedCurrency, setOpponentPick, setPicked, clearPicked } =
  currencyPicksSlice.actions

export const selectPickedCurrencies = (state: RootState, id: number) => {
  return state.currencyPicksReducer.picks[id]?.picked || defaultPicks.picked;
}

export const selectPickedByName = (state: RootState, id: number, name: string) =>
  state.currencyPicksReducer.picks[id]?.picked.find((item) => item.name === name)

export const selectOpponentPick = (state: RootState, id: number) => {
    return state.currencyPicksReducer.picks[id]?.opponentPick || defaultPicks.opponentPick;
}
export const selectPickedSymbols = (state: RootState, id: number) => {
  const myPickedSymbols = ( state.currencyPicksReducer.picks[id] ? state.currencyPicksReducer.picks[id]?.picked! : defaultPicks.picked).map((token) => {
    return { symbol: token.symbol }
  })
  const opponentPickedSymbols = ( state.currencyPicksReducer.picks[id] ? state.currencyPicksReducer.picks[id]?.opponentPick! : defaultPicks.opponentPick).map((token) => {
    return { symbol: token.symbol }
  })
  return { myPickedSymbols, opponentPickedSymbols }
}

export default currencyPicksSlice.reducer
