import { useAppSelector } from '@hooks/use-app-dispatch.hook'
import { DurationEnum } from '@models/game.interface'
import React from 'react'

import styles from './game-length-title.module.scss'
import { selectGame } from '@store/game/games.slice'

export default function GameLengthTitle() {
  const { duration } = useAppSelector((state) => selectGame(state, 0))

  const durationEnumToTime = (dur: number) => {
    return dur;
  }
  return <h3 className={styles.title}>{durationEnumToTime(duration)} minute game</h3>
}
