import { ICurrency } from '@models/currency.interface';
import style1 from '../currency-pick.module.scss'
import style2 from '../currency-pick-game.module.scss'
import { WebsocketContext } from '@api/websocket-provider';
import { useAppSelector, useAppDispatch } from '@hooks/use-app-dispatch.hook';
import { selectUser } from '@store/user/user.slice';
import { useContext, useEffect } from 'react';
import PortfolioComponent from '@components/shared/portfolio/portfolio.component';
import { GameStateEnum } from '@models/game.interface';
import { WsResponseAction } from '@models/ws.interface';
import _ from 'lodash';
import { selectGame, selectOpponent } from '@store/game/games.slice';
import { selectOpponentPick, selectPickedCurrencies, setOpponentPick, setPicked } from '@store/currency-pick/currency-picks.slice';
import clsx from "clsx";
import style from "@pages/inGame/in-game.module.scss";

export default function PickedTokensComponent( props: { gameId: number, isGame?: boolean }) {
  
  const style = props.isGame ? style2 : style1;
  const picked = useAppSelector((state) => selectPickedCurrencies(state, props.gameId))
  const opponentPick = useAppSelector((state) => selectOpponentPick(state, props.gameId))
  const { id, username } = useAppSelector(selectUser)
  const { state, cryptos } = useAppSelector((state) => selectGame(state, props.gameId))
  const { socket, isReady } = useContext(WebsocketContext)
  const dispatch = useAppDispatch()
  const { username: oppUsername } = useAppSelector((state) => selectOpponent(state, props.gameId))

  useEffect(() => {
    if (state === GameStateEnum.preparation) {
      return
    }
    if (!isReady) {
      return
    }

    const currencyListener = (data: any) => {
      if (id === null) {
        return
      }
      const myPick: ICurrency[] = []
      const myCryptosIndex = _.findIndex(cryptos, { user: { id } })
      const oppCryptosIndex = myCryptosIndex === 0 ? 1 : 0
      cryptos[myCryptosIndex]?.cryptos.forEach((tkn) => {
        const finded = data.find((f: ICurrency) => f.symbol === tkn.symbol)
        myPick.push(finded)
      })
      dispatch(setPicked({ id: props.gameId, currencies: myPick}))

      const opponentsPick: ICurrency[] = []
      cryptos[oppCryptosIndex]?.cryptos.forEach((tkn) => {
        const finded = data.find((f: ICurrency) => f.symbol === tkn.symbol)
        opponentsPick.push(finded)
      })
      dispatch(setOpponentPick({ id: props.gameId, currencies: opponentsPick }))
    }
    socket.on(WsResponseAction.gameCurrencies + '_'+props.gameId, currencyListener)
    /*return () => {
      socket.off(WsResponseAction.gameCurrencies + '_'+props.gameId, currencyListener)
    }*/
  }, [isReady, cryptos, dispatch, state, id])

  return (
    <div className={clsx(style.wrapper, style.wrapper_inner, style.wrapper_withoutHeight )}>
      <PortfolioComponent title={username} tokens={picked} preparation = {state == GameStateEnum.preparation} gameId={props.gameId}/>
      <PortfolioComponent title=  {oppUsername} tokens={opponentPick} preparation={state == GameStateEnum.preparation} opp gameId={props.gameId}/>
    </div>
  )
}
