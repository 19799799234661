import TokenChange from '@components/TokenChange/token-change.component';
import style from '../in-game.module.scss';
import { useContext, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import Button from '@components/shared/button/button.component';
import MessageComponent from './message.component';
import SendMessageComponent from './send-message.component';
import { WebsocketContext } from '@api/websocket-provider';
import { WsResponseAction } from '@models/ws.interface';
import { useAppSelector } from '@hooks/use-app-dispatch.hook';
import { selectUser } from '@store/user/user.slice';
import lockIcon from '@assets/icons/private-lock.svg';


export default function ChatComponent(props: { gameId: number | null }) {
  const { id } = useAppSelector(selectUser)
  const [muted, setMute] = useState(false)
  const [messages, setMessages] = useState<JSX.Element[]>([])
  const { socket } = useContext(WebsocketContext)

  useEffect(() => {
    const receiveMessage = (data: any) => {
        if (!muted){
          setMessages(messages.concat({...<MessageComponent key={data.date} message={data.message} senderName={data.username} sender={data.userId == id ? 'you' : 'opponent'}/>}))
        }
      }
    socket.on(WsResponseAction.receiveMessage + '_' + props.gameId, receiveMessage)

    return () => {
      socket.off(WsResponseAction.receiveMessage + '_' + props.gameId)
    }
  }, [socket, muted, messages])
  const messageContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    if (messageContainerRef.current) {
      const container = messageContainerRef.current;
      container.scrollTop = container.scrollHeight;
    }
  };
    return (
        <>
        { !props.gameId &&
            <div className={style.lockedChat}><img src={lockIcon}></img>LOCKED</div>}
          <div className={style.chatWrapper}>
            <div className={style.muteWrapper}>
                CHAT<Button additionalClassnames={clsx(style.chatMute, muted && style.chatMute_muted)} size='small' onClick={() => { setMute(!muted)}}> Mute</Button>
            </div>
            <div className={style.chatScreen} ref={messageContainerRef}>
              { messages.map(message => { return message; })}
            </div>
            <div className={style.sendMessage}>
                <SendMessageComponent gameId={props.gameId} userId={id} muted={muted}/>
            </div>
          </div>
        </>
    )
  }
