import TokenChange from '@components/TokenChange/token-change.component';
import style from '../../tournamentGame/in-game.module.scss';
import { useContext, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import Button from '@components/shared/button/button.component';
import { WebsocketContext } from '@api/websocket-provider';
import { WsResponseAction } from '@models/ws.interface';
import { useAppSelector } from '@hooks/use-app-dispatch.hook';
import { selectUser } from '@store/user/user.slice';
import lockIcon from '@assets/icons/private-lock.svg';
import SendMessageComponent from '@pages/tournamentGame/components/send-message.component';
import MessageComponent from '@pages/tournamentGame/components/message.component';


export default function ChatComponent(props: { tournamentId: number | null }) {
  const { id } = useAppSelector(selectUser)
  const [muted, setMute] = useState(false)
  const [messages, setMessages] = useState<JSX.Element[]>([])
  const { socket } = useContext(WebsocketContext)

  useEffect(() => {
    if (!socket)
      return;
    
    const receiveMessage = (data: any) => {
        if (!muted){
          setMessages(messages.concat({...<MessageComponent key={data.date} message={data.message} senderName={data.username} sender={data.userId == id ? 'you' : 'opponent'}/>}))
        }
      }
    socket.on(WsResponseAction.receiveTournamentMessage + '_' + props.tournamentId, receiveMessage)

    return () => {
      socket.off(WsResponseAction.receiveTournamentMessage + '_' + props.tournamentId)
    }
  }, [socket, muted, messages])

  const messageContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    if (messageContainerRef.current) {
      const container = messageContainerRef.current;
      container.scrollTop = container.scrollHeight;
    }
  };
    return (
      <div className={style.chatWrapper_map}>
        { !props.tournamentId && 
          <div className={style.lockedChat}><img src={lockIcon}></img>LOCKED</div>}
        <div className={style.muteWrapper_map}>
            <Button additionalClassnames={clsx(style.chatMute, muted && style.chatMute_muted)} size='small' onClick={() => { setMute(!muted)}}> Mute</Button>
        </div>
        <div className={style.chatScreen} ref={messageContainerRef}>
          { messages.map(message => { return message; })}
        </div>
        <div className={style.sendMessage}>
            <SendMessageComponent id={props.tournamentId} userId={id} muted={muted} isTournament/>
        </div>
      </div>
    )
  }
  