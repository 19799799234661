import React, { useEffect, useState } from 'react'
import { useTimer } from 'react-timer-hook'

import styles from './timer.module.scss'
import clsx from 'clsx'
import { useLocation, useNavigate } from 'react-router-dom'

interface ITimerProperties {
  expiry: any
  onExpire?: () => void
  isGame?: boolean;
  requestRestart?: boolean;
}

export default function Timer({ expiry, onExpire, isGame, requestRestart }: ITimerProperties) {
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [hide, setHide] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const interval = setInterval(() => {
      const data = new Date(new Date(expiry).getTime() - Date.now());
      setSeconds(data.getSeconds());
      setMinutes(data.getMinutes());
      if (new Date(expiry).getTime() < Date.now()) {
        setSeconds(0);
        setMinutes(0);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [expiry]);

  useEffect(() => { if (seconds == 0 && minutes == 0 && requestRestart) setHide(true); else setHide(false) }, [seconds, minutes, requestRestart])
  return hide ? <div className={clsx(styles.timer)}>⧖</div> : (isGame ? 
      
    <div className={clsx(styles.timer,isGame && styles.timer_game)}>
    Time left: {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
    </div>
    :

    <div className={clsx(styles.timer)}>
       {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
    </div>)
    
}
