import Footer from '@components/shared/footer/footer.component'
import Header from '@components/shared/header/header.component'
import { Outlet, useLocation } from 'react-router-dom'
import {useAppSelector} from "@hooks/use-app-dispatch.hook";
import {selectIsLoggedIn} from "@store/user/user.slice";

export interface ILayoutProperties {}

export default function MainLayout(): JSX.Element {
    
    const location = useLocation();
    const isGame = location.pathname == '/game' ||  location.pathname == '/ai-game' || location.pathname == '/tournament-game';
    return (
    <div className={location.pathname != '/' ? 'bg' : 'global-bg'}>

      <Outlet />

      { !isGame && <Footer />}
    </div>
    )
}
