import style from '../inGame/in-game.module.scss';
import clsx from 'clsx';
import Table from '@components/shared/table/table.component';
import gameService from '@api/services/game.service';
import { WebsocketContext } from '@api/websocket-provider';
import { useAppSelector, useAppDispatch } from '@hooks/use-app-dispatch.hook';
import { useGetBalance } from '@hooks/use-get-balance.hook';
import { GameStateEnum, IGame, IPlayer } from '@models/game.interface';
import { WsAction, WsResponseAction } from '@models/ws.interface';
import { selectUser, setBalance } from '@store/user/user.slice';
import { useState, useContext, useEffect, useMemo, useCallback, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CurrencyPickPage from './components/currencyPick/index.page';
import Music from '@pages/inGame/components/Music/music.component';
import Graph from './components/Graph/graph.component';
import GameLengthTitle from './components/GameLengthTitle/game-length-title.component';
import PickedTokensComponent from './components/currencyPick/components/picked-tokens.component';
import { ICurrency } from '@models/currency.interface';
import ResultModal from '@pages/inGame/components/ResultModal/result-modal.component';
import { calculateTotalChange, timeAgo } from '@utils/utils';
import PrizePoolComponent from '@pages/inGame/components/prize-pool.component';
import { removeGame, resetGames, selectGame, setGame, setGameCrytos, setGameWinner, tempEndGame, tempStartGame } from '@store/game/games.slice';
import { clearPicked, selectOpponentPick, selectPickedCurrencies } from '@store/currency-pick/currency-picks.slice';
import { useDidMountEffect } from '@hooks/use-did-mount-effect.hook';
import GameHeader from '@components/shared/game-header/game-header.component';
import loadingImg from '@assets/icons/loading.png'
import ChatComponent from '@pages/inGame/components/chat.component';



export default function BotGamePage() {
  const { state } = useAppSelector((state) => selectGame(state, 0))

  const { id, balance, username } = useAppSelector(selectUser)
  const gameBot = {
    id: 0,
    bet: 0,
    state: GameStateEnum.preparation,
    duration: 5,
    startsAt: new Date(Date.now() + 1 * 60 * 1000).toISOString(),
    endsAt: new Date(Date.now() + 5 * 60 * 1000).toISOString(),
    maxCryptos: 3,
    cryptos: [],
    winner: null,
    players: [],
  } as IGame

  const dispatch = useAppDispatch()

  const picked = useAppSelector((state) => selectPickedCurrencies(state, 0))
  const opponentPick = useAppSelector((state) => selectOpponentPick(state, 0))
  const [isLoading, setIsLoading] = useState(false)
  const [expiryTime, setExpiryTime] = useState(new Date().toString())
  const { socket } = useContext(WebsocketContext)
  const [showResultModal, setShowResultModal] = useState(false)
  const [searchParameters] = useSearchParams()
  const { getBalance } = useGetBalance()
  const [timeOffset, setTimeOffset] = useState(0)
  const [musicPlaying, setMusicPlaying] = useState(false)
  const pickedRef = useRef(picked);
  useEffect(() => {
    pickedRef.current = picked;
  }, [picked])
  useEffect(() => {
    dispatch(setGame(gameBot))
    return () => {
      dispatch(removeGame(0))
    }
  }, [])
  useEffect(() => {
    dispatch(
      setGameCrytos({ id: 0, cryptos: [
        {
          user: { id: id!, username: username },
          cryptos: picked as unknown as [
            {
              image: string
              name: string
              symbol: string
            }
          ],
        },
        {
          user: { id: 0, username: 'BOT' },
          cryptos: opponentPick as unknown as [
            {
              image: string
              name: string
              symbol: string
            }
          ],
        },
      ]} )
    )
    
    const winner = Number(calculateTotalChange(picked)) > Number(calculateTotalChange(opponentPick))
    if (winner) dispatch(setGameWinner({ id: 0, player: { id: id } as IPlayer }))
    else dispatch(setGameWinner({ id: 0, player: null}))

  }, [picked, opponentPick])

  const confirmResultAndRedirect = () => {
    setShowResultModal(false)
    dispatch(clearPicked(0))
    dispatch(removeGame(0))
    window.close();
  }

  // Get game info from startup
  const [prepareTime, setPrepareTime] = useState<any>(0)
  useEffect(() => {
    const requestGameInfo = async () => {
      setIsLoading(true)
      try {
        const serverTimestamp = await gameService.getServerTimestamp()
        const offset = Date.now() - serverTimestamp.data
        setTimeOffset(offset)
        setIsLoading(false)
        if (state === GameStateEnum.preparation) {
          dispatch(clearPicked(0))
          getBalance()
          const startsAt = new Date(gameBot.startsAt)
          const gameStartDate = new Date(startsAt.getTime()).toISOString()
          setExpiryTime(gameStartDate)
          const secondsToStart = new Date(gameStartDate).getTime() - Date.now()
          setPrepareTime(
            setTimeout(() => {
              if (pickedRef.current.length != gameBot.maxCryptos) {
                confirmResultAndRedirect();
              }
              setIsLoading(true)
              socket.emit(WsAction.playWithBot)
              dispatch(tempStartGame(0))
            }, secondsToStart)
          )
        } else {
          clearTimeout(prepareTime)
          const endsAt = new Date(gameBot.endsAt)
          setTimeout(() => {
            dispatch(tempEndGame(0))
            socket.emit(WsAction.endGameWithBot)
            setShowResultModal(true)
            setMusicPlaying(false)
          }, endsAt.getTime() - Date.now())
          setExpiryTime(new Date(endsAt.getTime() + offset).toISOString())
          setMusicPlaying(true)
        }
      } catch (error) {
        console.log(error)
      }
    }
    if (id && state !== GameStateEnum.ended) {
      requestGameInfo()
    }
  }, [dispatch, searchParameters, id, state])

  const columns = [ 'players', 'time', 'duration', 'bet', 'winner'];
  const [rows, setRows] = useState<any>([]);
  // Websocket listeners
  useEffect(() => {
    const startGameListener = (data: IGame) => {
      dispatch(setGame(data))
      const endsAt = new Date(data.endsAt)
      setExpiryTime(new Date(endsAt.getTime() + timeOffset).toISOString())
      dispatch(setBalance(balance - data.bet))
      setMusicPlaying(true)
    }
    startGameListener(gameBot)
    
    const setBattles = (data: any) => {
      setRows(data.map(( (dataRow: any) => {
        if (dataRow.players.length != 2)
          return null;

        return { players : (dataRow.players ? dataRow.players[0].username + ' vs '+ dataRow.players[1].username : ''), time: timeAgo(dataRow.startsAt), duration: (dataRow.duration + 'Mins'), bet: (dataRow.bet + 'USDT'), winner: (dataRow.winner ? dataRow.winner.username : '')}
      })));
    }
    socket.on(WsResponseAction.battleLog, setBattles)
  }, [dispatch, socket])
  if (isLoading) {
    return (
      <div>
        <GameHeader gameType='AI Game'/>
        <div className={style.loader}>
          <img src={loadingImg} alt='Loading'/>
        </div>
      </div>
    )
  }
  return (
    <div>
      <GameHeader gameType='AI Game'/>
      <div className={style.mainContent}>
        {state === GameStateEnum.preparation ? (
          <CurrencyPickPage prizePool={<PrizePoolComponent expiryTime={expiryTime} gameId={0}/>} />
        ) : (
          <div className={style.content}>
            <div className={style.leftColumn}>
              <PickedTokensComponent isGame/>
              
              <div className={clsx(style.wrapper, style.wrapper_inner, style.wrapper_withoutHeight, style.wrapper_hideLaptop)}>
                <ChatComponent gameId={null} />
              </div>
            </div>
            <div className={style.midColumn}>
              <div className={clsx(style.wrapper, style.wrapper_inner, style.wrapper_displayBlock, style.wrapper_graph)}>
                <div className={style.withMusicWrapper}>
                  <GameLengthTitle />
                  <Music
                    playing={musicPlaying}
                    toggleAudio={() => setMusicPlaying(!musicPlaying)}
                    hidden={state !== GameStateEnum.started}
                  />
                </div>
                <Graph />
              </div>
            </div>
            <div className={style.rightColumn}>
              <PrizePoolComponent expiryTime={expiryTime} isGame gameId={0}/>
              <div className={clsx(style.checkLaptop, style.checkLaptop_visibleDesktop)}><Table rows={rows} columns={columns} fullRadius darkTheme isGame  thinRow thinBars/></div>
              <div className={clsx(style.checkLaptop, style.checkLaptop_visibleLaptop)}>
                <div className={clsx(style.wrapper, style.wrapper_inner, style.wrapper_withoutHeight, style.wrapper_inner)}>
                  <ChatComponent gameId={null} />
                </div>
              </div>
            </div>
          </div>
        )}
        <ResultModal isOpen={showResultModal} onClose={() => confirmResultAndRedirect()} gameId={0}/>
      </div>
    </div>
  )
}

