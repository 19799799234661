import Button from '@components/shared/button/button.component'
import ModalWrapper from '@components/shared/modal-wrapper/modal-wrapper.component'
import { useAppSelector } from '@hooks/use-app-dispatch.hook'
import { selectIsWinner, selectRound } from '@store/tournament/rounds.slice'
import clsx from 'clsx'
import logoImg from '../../../../assets/icons/logo-small.png'
import trophies from '../../../../assets/images/trophy.svg'
import styles from './result-modal.module.scss'

interface IResultModalProperties {
  isWinner?: boolean
  bet: number
  isOpen: boolean
  onClose: () => void
  map?: boolean,
  state?: string | undefined,
  winnerName?: string | undefined,
}

export default function ResultModal(props: IResultModalProperties) {
  const { isOpen, onClose, isWinner, bet, map, state, winnerName } = props


  return (
    <ModalWrapper
      isOpen={isOpen}
      toggle={onClose}
      styleVariant="secondary"
      className={clsx(styles.result, !isWinner && styles.result_lose)}
      isNegative={!isWinner}
    >
      <img className={styles.logo} src={logoImg}></img>
      <p className={styles.gameTypeLabel}>Tournament</p>
      {isWinner ? (
        <h4 className={styles.title}>CONGRATS! { state ? <><br/> {state}</> : <></>}</h4>
      ) : (
        <h4 className={clsx(styles.title, styles.title_lose)}>YOU LOSE</h4>
      )}
      <p className={styles.desc}>
        { isWinner ? ( <p>You won { map ? bet + ' USDT' : '' }!</p>): <><p>Better luck next time!</p> { winnerName ? <p style={{margin: 'auto', marginTop: '3px', textAlign: 'center' }}>Winner: {winnerName}</p> : ''}</> }
      </p>
      <Button additionalClassnames={clsx(styles.btn, !isWinner && styles.btn_lose)} onClick={onClose} variant="secondary">
        { (!map && isWinner) ? 'Advance' : 'Exit' }
      </Button>
      {(map && isWinner) && <img className={styles.trophies} src={trophies}/>}
    </ModalWrapper>
  )
}
