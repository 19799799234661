import logoImg from '@assets/icons/tradasy_logo@2x.png'
// import { ReactComponent as ProfileIcon } from '@assets/icons/profile-icon.svg'
import ProfileIcon from '@assets/icons/profile-icon.png'
import  NotificationOn  from '@assets/icons/notification-on.svg';
import  NotificationOff  from '@assets/icons/notification-off.svg';
import { ReactComponent as RefreshIcon } from '@assets/icons/refresh.svg'
import { ReactComponent as LineIcon } from '@assets/icons/line.svg'
import styles from '@components/shared/header/header.module.scss'
import { useAppSelector } from '@hooks/use-app-dispatch.hook'
import { useGetBalance } from '@hooks/use-get-balance.hook'
import { useLogin } from '@hooks/use-login.hook'
import { selectIsLoggedIn, selectUser } from '@store/user/user.slice'
import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'
import star from '../../../assets/icons/star.svg'
import Button from '../button/button.component'
import Footer from '../footer/footer.component'
import DepositModal from '../modals/deposit-modal.component'
import NicknameModal from '../modals/nickname-modal.component'
import WithdrawModal from '../modals/withdraw-modal.component'
import ProfileMenu from '../profile-menu/profile-menu.component'
import Navigation from "@components/shared/navigation/navigation.component";
import { useLocation } from 'react-router-dom'
import gameService from '@api/services/game.service';
import { timeAgo } from '@utils/utils';

export default function Header(): JSX.Element {
  const location = useLocation();
  const { id, balance, username } = useAppSelector(selectUser)
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const {logout } = useLogin()
  const [showPopover, setShowPopover] = useState(false)
  const [showNotifications, setShowNotifications] = useState(false)
  const popoverReference = useRef(null)
  const popoverButtonReference = useRef(null)
  const notificationReference = useRef(null)
  const notificationButtonReference = useRef(null)
  const [showNicknameModal, setShowNicknameModal] = useState(false)
  const [showDepositModal, setShowDepositModal] = useState(false)
  const [showWithdrawModal, setShowWithdrawModal] = useState(false)
  const [refreshButtonActive, setRefreshButtonActive] = useState(true)
  const { getBalance } = useGetBalance()

  const closePopover = () => {
    setShowPopover(false)
  }
  const closeNotifications = () => {
    setShowNotifications(false);
  }
  const openDepositModal = () => {
    setShowDepositModal(true)
    closePopover()
  }

  const openWithdrawModal = () => {
    setShowWithdrawModal(true)
    closePopover()
  }

  const handleLogout = () => {
    logout()
    closePopover()
  }

  const requestBalance = () => {
    if (refreshButtonActive === false) {
      return
    }
    getBalance()
    setRefreshButtonActive(false)

    setTimeout(() => {
      setRefreshButtonActive(true)
    }, 10_000)
  }

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        popoverReference.current &&
        // @ts-ignore
        !popoverReference.current.contains(event.target) &&
        // @ts-ignore
        !popoverButtonReference.current.contains(event.target)
      ) {
        closePopover()
      }
      if (
        notificationReference.current &&
        // @ts-ignore
        !notificationReference.current.contains(event.target) &&
        // @ts-ignore
        !notificationButtonReference.current.contains(event.target)
      ) {
        closeNotifications()
      }
    }
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  useEffect(() => {
    if (isLoggedIn && username === null) {
      setShowNicknameModal(true)
    } else {
      setShowNicknameModal(false)
    }
  }, [isLoggedIn, username])
  const [logs, setLogs] = useState<any>([])
  const getLogs = async () => {
    if (id == null)
      return

    const logs = await gameService.getGameLogs(id)
    if (logs.status == 200 && logs.data) {
      setLogs(logs.data.map(( (dataRow: any) => {
        if (dataRow && dataRow.players && dataRow.players.length == 2) {
          return { players : (dataRow.players ? dataRow.players[0].username + ' vs ' + dataRow.players[1].username : ''), time: timeAgo(dataRow.startsAt), duration: (dataRow.duration + 'Mins'), bet: (dataRow.bet + 'USDT'), winner: (dataRow.winner ? dataRow.winner.username : '')}
        } else {
          return null;
        }
      })));
    }
  };

  const renderLogs = (logs: any[]) => {
    return logs.filter( log => log != null ).map((log, index) => {
      return <li>
        {log.players}, bet: {log.bet}, winner: {log.winner}  
        <div className={styles.time}>{log.time}</div>
      </li>
    })
  };
  return (
    <header className={(location.pathname != '/' && isLoggedIn ) ? styles.header : styles.globalHeader}>
      <div className={styles.headerContent} style={{ backgroundColor: 'unset', marginRight: '60px', marginTop: isLoggedIn ? 'unset' : '40px'}}>
      <a href={isLoggedIn ? '/custom-game' : '/'} className={styles.logo}>
        <img src={logoImg} alt="Tradasy" />
      </a>
      {isLoggedIn ? (
        <>
        
        <Navigation isLogged/>
        <div className={clsx(styles.user, showPopover && styles.user_active_popover, showNotifications && styles.user_active_notification)}>
          


          <Button variant='primary' size="small" onClick={openDepositModal} additionalClassnames={styles.depositBtn}>
            Deposit
          </Button>
          <button
            className={styles.userBtn}
            onClick={() =>{ setShowNotifications(!showNotifications); if  (!showNotifications) { getLogs(); } else { setLogs([]); }}}
            ref={notificationButtonReference}
          >
          <div className={clsx(styles.profileAtc, showNotifications && styles.smallImage)}>
            <img src={showNotifications ? NotificationOn : NotificationOff} alt="notifications" />
          </div>
          </button>
          <div className={styles.userDivider}>
            <LineIcon />
          </div>
          <button
            className={styles.userBtn}
            onClick={() => setShowPopover(!showPopover)}
            ref={popoverButtonReference}
          >
            <div className={styles.profileAtc}>
              <img src={ProfileIcon} alt="profile" />
              <div className={styles.stars}>
                  <div className={styles.star}><img src={star} alt="profile" /></div>
                  <div className={styles.star}><img src={star} alt="profile" /></div>
                  <div className={styles.star}><img src={star} alt="profile" /></div>
              </div>
            </div>
          </button>
          <div className={styles.userContent}>
            <span>{username}</span>
              <div className={styles.balanceContent}>
              
              <span className={styles.balance}>{balance} USDT</span>
                <button
                className={clsx(styles.refreshBtn, !refreshButtonActive && styles.refreshBtn_rotate)}
                onClick={requestBalance}
                disabled={!refreshButtonActive}
              >
                <RefreshIcon />
              </button>
            </div>
          </div>
          <div className={styles.popover} ref={popoverReference}>
            <div className={styles.popover__mobileHeader}>
              <img src={logoImg} alt="Tradasy" />
              <button className={styles.popover__mobileClose} onClick={closePopover}>
                &times;
              </button>
            </div>
            <ProfileMenu
              openDepositModal={openDepositModal}
              openWithdrawModal={openWithdrawModal}
              handleLogout={handleLogout}
            />
            <div className={styles.popover__mobileFooter}>
              <Footer />
            </div>
          </div>
          <div className={styles.notification} ref={notificationReference}>
            <div className={styles.notificationTitle}>notifications</div>
            <ol className={styles.notificationList}>
            {renderLogs(logs)}
            </ol>
          </div>
        </div>
        </>
      ) : (
          <Navigation isHome/>
      )}

      <NicknameModal isOpen={showNicknameModal} onClose={() => setShowNicknameModal(false)} />
      <DepositModal isOpen={showDepositModal} onClose={() => setShowDepositModal(false)} />
      <WithdrawModal isOpen={showWithdrawModal} onClose={() => setShowWithdrawModal(false)} />
      </div>
    </header>
  )
}
