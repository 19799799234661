import Table from '@components/shared/table/table.component'
import logoImg from '@assets/icons/profile-icon.png'
import style from './profile.module.scss';
import Button from '@components/shared/button/button.component';
import { useEffect, useState } from 'react';
import gameService from '@api/services/game.service';
import { join } from 'lodash';
import authService from '@api/services/auth.service';
import { useAppSelector } from '@hooks/use-app-dispatch.hook';
import { selectUser } from '@store/user/user.slice';
import WithdrawModal from '@components/shared/modals/withdraw-modal.component';
import DepositModal from '@components/shared/modals/deposit-modal.component';
import porfileChange from '@assets/icons/profile-change.svg';
import InfoPopup from './components/info-popup.component';
import linkImg from '@assets/icons/link.svg'
import { timeAgo } from '@utils/utils';
import ProgramComponent from './components/program/program.component';
import BonusesModal from '@components/shared/modals/bonuses-modal.component';
import GameHeader from '@components/shared/game-header/game-header.component';
import LogTable from '@components/shared/gameLog/components/log-table.component';
import LobyTable from '@components/shared/loby-table/table.component';

export default function ProfilePage() {
  const { username, balance } = useAppSelector(selectUser)
  const [componentImg, setComponentImg] = useState(logoImg);
  const [showDepositModal, setShowDepositModal] = useState(false)
  const [showWithdrawModal, setShowWithdrawModal] = useState(false)
  const [showBonusesModal, setShowBonusesModal] = useState(false)
  const [popup, setPopup] = useState(false);
  const { id, referralCode } = useAppSelector(selectUser)
  const GameCols = [ 'players', 'time', 'duration', 'prize', 'winner'];
  const TransactionCols = [ 'type', 'date', 'signature', 'amount'];
  const transactions = [
    {
      type: 'DEPOSIT',
      date: '12.12.2023',
      signature: 'Signature Address',
      amount: '100 USDT',
    },
    {
      type: 'DEPOSIT',
      date: '12.12.2023',
      signature: 'Signature Address',
      amount: '100 USDT',
    },
    {
      type: 'WITHDRAW',
      date: '12.12.2023',
      signature: 'Signature Address',
      amount: '100 USDT',
    },
    {
      type: 'DEPOSIT',
      date: '12.12.2023',
      signature: 'Signature Address',
      amount: '100 USDT',
    },
    {
      type: 'DEPOSIT',
      date: '12.12.2023',
      signature: 'Signature Address',
      amount: '100 USDT',
    },
  ]
  const [logs, setLogs] = useState<any>([])
  const getLogs = async () => {
    if (id == null)
      return

    const logs = await gameService.getGameLogs(id)
    if (logs.status == 200 && logs.data) {
      setLogs(logs.data.map(( (dataRow: any) => {
        if (dataRow && dataRow.players && dataRow.players.length == 2) {
          return { players : (dataRow.players ? dataRow.players[0].username + ' vs ' + dataRow.players[1].username : ''), time: timeAgo(dataRow.startsAt), duration: (dataRow.duration + 'Mins'), prize: (dataRow.bet + 'USDT'), winner: (dataRow.winner ? dataRow.winner.username : '')}
        } else {
          return null;
        }
      })));
    }
  }
  useEffect(() => {
    getLogs();
  }, [])
  return (
    <div>
      <GameHeader/>
      <div className={style.title}>Profile</div>
      <div className={style.content}>
        <div className={style.column}>
          <ProgramComponent title='loyalty program' subTitle='rakeback' subData={'33.25 USDT'} currentValue={320} maxValue={500}/>
          <div className={style.subTitle}>Player Log</div>
          <Table thinBars key={1} rows={logs.filter( (log: any) => log != null).slice(0,5)} columns={GameCols} darkTheme thinRow height={450}></Table>
        </div>
        <div className={style.column}>
          <img className={style.profileImage} src={componentImg} width={10} />
          <div className={style.profileName}> {username}</div>
          <div className={style.profileBalance}> Balance {balance} usdt</div>
          <div className={style.profileInfo}>
            <p> Widthdrawable 68.33 USDT</p>
            <hr/>
            <p> Restricted 60.00 USDT</p>
          </div>
          <div className={style.buttonWrapper}>
            <Button variant="secondary" additionalClassnames={style.profileButton} onClick={() => setComponentImg((componentImg == logoImg ? porfileChange : logoImg))}>
              Edit Profile
            </Button>
            <Button variant="secondary" additionalClassnames={style.profileButton} onClick={() => setShowWithdrawModal(true)}>
              Withdraw
            </Button>
            <Button variant="secondary" additionalClassnames={style.profileButton} onClick={() => setShowDepositModal(true)}>
              Deposit
            </Button>
            <Button variant="secondary" additionalClassnames={style.profileButton} onClick={() => setShowBonusesModal(true)}>
              Bonuses
            </Button>
          </div>
          <div className={style.refWrapper}>
            <h4 className={style.refTitle}>Referral Link / Code</h4>
            <div className={style.refBtns}>
              <div className={style.refBtn}>{referralCode}</div>
              <div className={style.refBtnCpy} onClick={() => {navigator.clipboard.writeText(referralCode); alert('Invite Url Copied');}}>🔗</div>
            </div>
            <p>
            {'(You will be our %25 commission partner for each player that joins via your referral code.)'}
            </p>
          </div>
        </div>
        <div className={style.column}>
          <ProgramComponent title='partnership program' subTitle='commision' subData={'28.46 USDT'} currentValue={320} maxValue={500} secondary/>
          <div className={style.subTitle}>Transaction History</div>
          <Table thinRow thinBars  key={0} rows={transactions} columns={TransactionCols} darkTheme height={450}></Table>
        </div>
      </div>
      <DepositModal isOpen={showDepositModal} onClose={() => setShowDepositModal(false)} />
      <WithdrawModal isOpen={showWithdrawModal} onClose={() => setShowWithdrawModal(false)} />
      <BonusesModal isOpen={showBonusesModal} onClose={() => setShowBonusesModal(false)} />
    </div>
  )
}

