import { GameStateEnum, ICryptos, IGame, IGames, IPlayer } from "@models/game.interface";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@store/store";
import _ from "lodash";

const initialState: IGames = { games: []};
interface ISetCryptos { id: number, cryptos: ICryptos[] };
interface IsetGameStartDate { id: number, date: string };
interface ISetGameWinner { id: number, player: IPlayer | null };
const defaultGame = {
  id: null,
  bet: 0,
  duration: 10,
  startsAt: Date.now().toString(),
  endsAt: Date.now().toString(),
  winner: null,
  state: GameStateEnum.preparation,
  maxCryptos: 3,
  cryptos: [],
};
export const gamesSlice = createSlice({
    name: 'games',
    initialState,
    reducers: {
      setGame: (state, action: PayloadAction<IGame>) => {
        const { id } = action.payload;
        state.games[id!] = action.payload;
      },
      removeGame: (state, action: PayloadAction<number>) => {
        delete state.games[action.payload];
      },
      tempStartGame: (state, action: PayloadAction<number>) => {
        const game = state.games[action.payload];
        if (game) {
          game.state = GameStateEnum.started
        }
      },
      tempEndGame: (state, action: PayloadAction<number>) => {
        const game = state.games[action.payload];
        if (game) {
          game.state = GameStateEnum.ended
        }
      },
      setGameCrytos: (state, action: PayloadAction<ISetCryptos>) => { 
        const { id, cryptos } = action.payload;
        const game = state.games[id];
        if (game) {
          game.cryptos = cryptos;
        }
      },
      setGameStartDate: (state, action: PayloadAction<IsetGameStartDate>) => { 
        const { id, date } = action.payload;
        const game = state.games[id];
        if (game) {
          game.startsAt = date;
        }
      },
      setGameWinner: (state, action: PayloadAction<ISetGameWinner>) => { 
        const { id, player } = action.payload;
        const game = state.games[id];
        if (game) {
          game.winner = player;
        }
      },
      resetGames: () => {
        return initialState
      },
    },
  });

  export const selectGame = (state: RootState, id: number) => { return state.gamesReducer.games[id] || defaultGame }

  export const selectIsWinner = (state: RootState, id: number) => {
    return state.userReducer.user.id == state.gamesReducer.games[id]?.winner?.id
  }

  export const selectOpponent = (state: RootState, id: number): { id: number; username: string } => {
    const myId = state.userReducer.user.id
    const myCryptosIndex = _.findIndex(state.gamesReducer.games[id]?.cryptos, { user: { id: myId } })
    const oppCryptosIndex = myCryptosIndex === 0 ? 1 : 0
    const opp = state.gamesReducer.games[id]?.cryptos[oppCryptosIndex]
    if (opp && opp.user) {
      return opp.user
    }
    return { id: 0, username: '' }
  }

  export const {
    setGame,
    removeGame,
    resetGames,
    setGameCrytos,
    setGameStartDate,
    setGameWinner,
    tempStartGame,
    tempEndGame
  } = gamesSlice.actions;
  export default gamesSlice.reducer;