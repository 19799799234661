import Table from '@components/shared/table/table.component'
import logoImg from '@assets/icons/profile-icon.png'
import style from './battle-log.module.scss';
import { WebsocketContext } from '@api/websocket-provider';
import { useAppDispatch } from '@hooks/use-app-dispatch.hook';
import { WsResponseAction } from '@models/ws.interface';
import { useContext, useEffect, useState } from 'react';
import titleImage from '@assets/images/battle-log-title.png';
import { timeAgo } from '@utils/utils';

export default function BattleLogComponent(props: { nonTitle?: boolean}) {

  const columns = [ 'players', 'time', 'duration', 'prize', 'winner'];
  const [rows, setRows] = useState<any>([])
  const { socket } = useContext(WebsocketContext)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (socket === null) {
      return
    }
    const setBattles = (data: any[]) => {
      setRows(data.map(( (dataRow: any) => {
        if (dataRow.players.length != 2)
          return null;

        return { players : (dataRow.players ? dataRow.players[0].username + ' vs ' + dataRow.players[1].username : ''), time: timeAgo(dataRow.startsAt), duration: (dataRow.duration + 'Mins'), prize: (dataRow.bet + 'USDT'), winner: (dataRow.winner ? dataRow.winner.username : '')}
      })));
    }
    socket.on(WsResponseAction.battleLog, setBattles)

    return () => {
      socket.off(WsResponseAction.battleLog);
    }

  }, [dispatch, socket])
  return (
    <div className={style.wrapper}>
      { !props.nonTitle && <div className={style.title}><img src={titleImage}/></div>}
      <div className={style.content}>
        <Table rows={rows} columns={columns} fullRadius darkTheme showLogo isPaginate/>
      </div>
    </div>
  )
}

