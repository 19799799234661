import { useAppSelector } from '@hooks/use-app-dispatch.hook'
import { DurationEnum } from '@models/game.interface'
//import { selectGame } from '@store/game/game.slice'
import React from 'react'

import styles from './game-length-title.module.scss'
import { selectGame } from '@store/game/games.slice'

export default function GameLengthTitle(props: { gameId: number }) {
  const { duration } = useAppSelector((state) => selectGame(state, props.gameId))

  const durationEnumToTime = (dur: DurationEnum) => {
    return dur;
  }

  return <h3 className={styles.title}>{durationEnumToTime(duration)} minute game</h3>
}
