import TokenChange from '@components/TokenChange/token-change.component';
import style from './portfolio.module.scss';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { ICurrency } from '@models/currency.interface';
import { calculateTotalChange, roundNumber } from '@utils/utils';
import { useAppDispatch, useAppSelector } from '@hooks/use-app-dispatch.hook';
//import { removePickedCurrency } from '@store/currency-pick/currency-pick.slice';
import { selectGame } from '@store/game/games.slice';
import { removePickedCurrency } from '@store/currency-pick/currency-picks.slice';
//import { selectGame } from '@store/game/game.slice';
import hiddenToken from '@assets/icons/question.png';
interface IPortfolioProps{
  gameId: number;
  title: string;
  tokens: ICurrency[];
  preparation?: boolean;
  opp?: boolean;
}

export default function PortfolioComponent(props: IPortfolioProps) {
  const dispatch = useAppDispatch();
  const { maxCryptos } = useAppSelector((state) => selectGame(state, props.gameId))
  const [tokenList, setTokenList] = useState<ICurrency[]>([])
  useEffect(() => {
    const userTokens = props.tokens.concat(
        Array(maxCryptos - props.tokens.length).fill({
          image: '',
          name: '',
          currency: 0,
          change: 0,
          symbol: '',
          createdAt: '',
        })
    )
    setTokenList(
      props.preparation && props.opp
        ? Array(maxCryptos).fill({
            image: '',
            name: '',
            currency: 0,
            change: 0,
            symbol: '',
            createdAt: '',
          })
        : userTokens
    )
  }, [props.tokens, maxCryptos, props.preparation, props.opp]);

  const tokenRenderer = () => {
    return (
      <>
        {tokenList.map((token, index) => {
          return (
            <div
              key={token.symbol ||index}
              className={clsx(style.tokenBox, !props.opp && props.preparation && style.remover, index >= props.tokens.length && style.emptyToken)}
              
            >
              <div className={style.tokenIcon} onClick={() => {
                if (props.preparation && !props.opp) dispatch(removePickedCurrency({ id: props.gameId, currency: token }))
              }}>
                { token.symbol && <p className={style.closeBtn} >x</p> }
                <img src={(props.opp && props.preparation) ? hiddenToken : `https://www.cryptocompare.com${token.image}`} alt={token.symbol} title={token.symbol} />
              </div>
              <div className={style.change}>
                <TokenChange value={(props.preparation ? 0 : roundNumber(+token.change))} preparetion={props.preparation} showIcon={false} opp={props.opp}/>
              </div>
            </div>
          )
        })}
      </>
    )
  }
  return (
    <div className={clsx(style.portfolio, !props.preparation && style.portfolio_game)}>
      <h4 className={clsx(style.portfolioTitle, !props.preparation && style.portfolioTitle_game)}>
      <div>{props.opp ? "Opponent's Portfolio" : "Your Portfolio"}</div> - {props.title} 
      </h4>
      <div className={style.tokenRower}>{tokenRenderer()}</div>
      { !(props.opp && props.preparation) && <h4
        className={style.tokenTotalLabel}
        style={
          Number(
            calculateTotalChange(props.preparation && props.opp ? (Array(maxCryptos).fill({
              image: '',
              name: '',
              currency: 0,
              change: 0,
              symbol: '',
              createdAt: '',
            })) : props.tokens)
          ) < 0
            ? { color: (props.preparation ? 'white': 'red') }
            : { color: (props.preparation ? 'white': 'green') }
        }
      >
        total: { calculateTotalChange(props.preparation ? (Array(maxCryptos).fill({
              image: '',
              name: '',
              currency: 0,
              change: 0,
              symbol: '',
              createdAt: '',
            })) : props.tokens)}%
      </h4> }
    </div>
  )
  }
