import style from './in-game.module.scss';
import clsx from 'clsx';
import ChatComponent from './components/chat.component';
import gameService from '@api/services/game.service';
import { WebsocketContext } from '@api/websocket-provider';
import { useAppSelector, useAppDispatch } from '@hooks/use-app-dispatch.hook';
import { useGetBalance } from '@hooks/use-get-balance.hook';
import { GameStateEnum, IGame } from '@models/game.interface';
import { WsResponseAction } from '@models/ws.interface';
import { selectUser, setBalance } from '@store/user/user.slice';
import { useState, useContext, useEffect } from 'react';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import CurrencyPickPage from './components/currencyPick/index.page';
import Music from '@pages/inGame/components/Music/music.component';
import Graph from './components/Graph/graph.component';
import GameLengthTitle from './components/GameLengthTitle/game-length-title.component';
import PickedTokensComponent from './components/currencyPick/components/picked-tokens.component';
import ResultModal from './components/ResultModal/result-modal.component';
import { timeAgo } from '@utils/utils';
import { clearPicked } from '@store/currency-pick/currency-picks.slice';
import GameHeader from '@components/shared/game-header/game-header.component';
import loadingImg from '@assets/icons/loading-tournament.png'
import tournamentService from '@api/services/tournament.service';
import { IRound } from '@store/tournament/tournament.model';
import PrizePoolComponent from '@pages/inGame/components/prize-pool.component';
import { removeRound, selectIsWinner, selectRound, setRound } from '@store/tournament/rounds.slice';
import RightBarComponent from './components/rightBar/right-bar.component';



export default function TournamentGamePage() {
  const [searchParameters] = useSearchParams()
  const [bracket, setBracket] = useState(<></>);
  const tournamentId = searchParameters.get('tournament')! as unknown as number;
  const paramId = searchParameters.get('id')! as unknown as number;
  const { state, bet } = useAppSelector((state) => selectRound(state, paramId))
  const winner = useAppSelector((state) => selectIsWinner(state, paramId))
  const { id, balance, wallet } = useAppSelector(selectUser)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [expiryTime, setExpiryTime] = useState(new Date().toString())
  const [showResultModal, setShowResultModal] = useState(false)
  const [tournamentResult, setTournamentResult] = useState(false)
  const [resultState, setResultState] = useState<undefined | string>(undefined)
  const { socket } = useContext(WebsocketContext)
  const { getBalance } = useGetBalance()
  const [timeOffset, setTimeOffset] = useState(0)
  const [musicPlaying, setMusicPlaying] = useState(false)
  const [winBet, setWinBet] = useState(0);
  const [winnerName, setWinnerName]= useState<string | undefined>(undefined)
  const confirmResultAndRedirect = () => {
    setShowResultModal(false)
    dispatch(clearPicked(paramId))
    dispatch(removeRound(paramId))
    
    
    if (tournamentResult)
      window.close();
    else
      navigate({
        pathname: '/tournament-map',
        search: createSearchParams({ id: tournamentId.toString() }).toString(),
      })
  }

  // Get game info from startup
  const [prepareTime, setPrepareTime] = useState<any>(0);

  const roundToGame = (round: IRound) => {
    return {
      id: round.id,
      cryptos: round.cryptos,
      bet: round.bet,
      duration: round.duration,
      state: round.state,
      startsAt: round.startsAt,
      endsAt: round.endsAt,
      maxCryptos: round.maxCryptos,
      winner: round.winner,
    } as IGame;
  };
  useEffect(() => {
    const requestGameInfo = async () => {
      //setIsLoading(true)
      try {
        
        getBalance()
        const serverTimestamp = await gameService.getServerTimestamp()
        const offset = Date.now() - serverTimestamp.data
        setTimeOffset(offset)
        const queryId = searchParameters.get('id') as string
        const gameInfo = await tournamentService.getTournamentRoundInfo(queryId)
        dispatch(setRound(roundToGame(gameInfo.data)))
        setIsLoading(false)
        if (state === GameStateEnum.preparation) {
          dispatch(clearPicked(paramId))
          const startsAt = new Date(gameInfo.data.startsAt)
          const gameStartDate = new Date(startsAt.getTime() + offset).toISOString()
          setExpiryTime(gameStartDate)
          const secondsToStart = new Date(gameStartDate).getTime() - Date.now()
          setPrepareTime(setTimeout(() => {
            //setIsLoading(true)
          }, secondsToStart));
        } else {
          clearTimeout(prepareTime);
          const endsAt = new Date(gameInfo.data.endsAt)
          setExpiryTime(new Date(endsAt.getTime() + offset).toISOString())
          setMusicPlaying(true)
          // getBalance()
          // dispatch(setBalance(balance - gameInfo.data.bet))
        }
      } catch (error) {
        console.log(error)
      }
    }
    if (id && state !== GameStateEnum.ended) {
      requestGameInfo()
    }
  }, [dispatch, searchParameters, id, state])
  
  // Websocket listeners
  useEffect(() => {
    if (!socket) {
      return
    }
    const startGameListener = (data: IRound) => {
      dispatch(setRound(roundToGame(data)))
      const endsAt = new Date(data.endsAt)
      setExpiryTime(new Date(endsAt.getTime() + timeOffset).toISOString())
      dispatch(setBalance(balance - data.tournament.bet))
      setMusicPlaying(true)
    }
    socket.on(WsResponseAction.roundStarted+ '_' + paramId, startGameListener)

    const endGameListener = (data: IRound) => {
      dispatch(setRound(roundToGame(data)))
      setShowResultModal(true)
      setMusicPlaying(false)
      // getBalance()
    }
    socket.on(WsResponseAction.roundEnds+ '_' + paramId, endGameListener)

    const cancelGameListener = (data: any) => {
      alert(data)
      dispatch(clearPicked(paramId))
      dispatch(removeRound(paramId))
      navigate({
        pathname: '/tournament-map',
        search: createSearchParams({ id: tournamentId.toString() }).toString(),
      })
    }
    socket.on(WsResponseAction.roundCanceled+ '_' + paramId, cancelGameListener)

    const getPercentPerDist = (playerCount: number, index: number) => {
      if (index == 1){
        if (playerCount == 8)
          return 100;
        if (playerCount == 16)
          return 65;
        if (playerCount == 32)
          return 50;
      }
      if (index == 2){
        if (playerCount == 16)
          return 35;
        if (playerCount == 32)
          return 25;
      }
      if (index == 3){
        return 12.5;
      }
      return 12.5;
      
    }
    const tournamentEndsListener = (data: { winners: string[], tournament: any}) => {
      setTournamentResult(true)
      setWinnerName(data.tournament.winner ? data.tournament.winner.username : undefined);
      const winnerState = data.winners.findIndex( winner => winner == wallet);
      setResultState( winnerState != -1 ? ((winnerState + 1)+ '. Winner ') : undefined)
      setWinBet(getPercentPerDist(data.tournament.maxPlayers, (winnerState + 1)) / 100 * data.tournament.bet * data.tournament.maxPlayers)
    }
    
    socket.on(WsResponseAction.tournamentEnds + '_' + tournamentId, tournamentEndsListener)


    return () => {
      socket.off(WsResponseAction.roundStarted+ '_'+paramId, startGameListener)
      socket.off(WsResponseAction.roundEnds+ '_'+paramId, endGameListener)
      socket.off(WsResponseAction.roundCanceled+ '_'+paramId, cancelGameListener)
    }
  }, [dispatch, socket, paramId])

  if (isLoading) {
    return (
      <div>
        <GameHeader gameType='Tournament Game'/>
        <div className={style.loader}>
          <img src={loadingImg} alt='Loading'/>
        </div>
      </div>
    )
  }
  
  return (
    <div>
      <GameHeader gameType='Tournament Game'/>
      <div className={style.mainContent}>
        {state === GameStateEnum.preparation ? (
          <CurrencyPickPage prizePool={<PrizePoolComponent expiryTime={expiryTime} gameId={tournamentId} isRound/>} roundId={paramId} tournamentId={tournamentId}/>
        ) : (
          <div className={style.content}>
            {bracket}
            <div className={style.leftColumn}>
              
            <PickedTokensComponent isGame gameId={paramId}/>
            <div className={clsx(style.wrapper, style.wrapper_inner, style.wrapper_withoutHeight, style.wrapper_hideLaptop)}>
                <ChatComponent roundId={paramId} />
              </div>
            </div>
            <div className={style.midColumn}>
              <div className={clsx(style.wrapper, style.wrapper_inner, style.wrapper_displayBlock, style.wrapper_graph)}>
                
                <div className={style.withMusicWrapper}>
                  <GameLengthTitle gameId={paramId}/>
                  <Music
                    playing={musicPlaying}
                    toggleAudio={() => setMusicPlaying(!musicPlaying)}
                    hidden={state !== GameStateEnum.started}
                  />
                </div>
                <Graph gameId={paramId}/></div>
            </div>
            <div className={style.rightColumn}>
              <RightBarComponent roundId={paramId} tournamentId={tournamentId} expiryTime={expiryTime} setBracket={setBracket}/>
            </div>
          </div>
        )}
        <ResultModal isOpen={showResultModal} onClose={() => confirmResultAndRedirect()} winnerName={winnerName} isWinner={winner} bet={winBet} map={tournamentResult} state={resultState}/>
      </div>
    </div>
  )
}

