import Button from '@components/shared/button/button.component';
import styles from '../loby.module.scss';
import gameService from '@api/services/game.service';
import { useContext, useEffect, useState } from 'react';
import authService from '@api/services/auth.service';
import { WebsocketContext } from '@api/websocket-provider';
import { WsAction } from '@models/ws.interface';
import LobyTable from '@components/shared/loby-table/table.component';
import clsx from 'clsx';
import { useAppSelector } from '@hooks/use-app-dispatch.hook';
import { selectIsLoggedIn, selectUser } from '@store/user/user.slice';
import inviteLogo from '@assets/icons/invite.png';
import CancelGamePopup from './cancel-game-popup.component';
import { useLogin } from '@hooks/use-login.hook';
import { useNavigate } from 'react-router-dom';

export default function LabyTableComponent(props: { refreshTime: number}) {
  const isLogged = useAppSelector(selectIsLoggedIn)
  const { id, balance } = useAppSelector(selectUser);
  const cols = ['entry fee', 'duration', 'coin', 'player', 'status'];
  const { socket } = useContext(WebsocketContext)
  const [rows, setRows] = useState<any[]>([]);
  const [filter, setFilter] = useState('all')
  const navigate = useNavigate()
  const [popupGame, setPopupGame] = useState<number |null>(null);
  const [joinState, setJoinState] = useState(true);
  const [loading, setLoading] = useState(true);
  const popitup = (url: string, windowName: string) => {
    const newwindow=window.open(url,windowName,'height=1920,width=1080');
    if (newwindow)
      newwindow.focus()
    else {
      alert('Pop-up blocked! Please enable pop-ups for this site');
      navigate(url, { replace: false, relative: 'path' })
    }
  }
  const cancelGame = async (id: number) => {
    setPopupGame(null);
    const cancelState = await gameService.cancelGame(id)
      if (cancelState.status == 200){
        alert('Game Canceled!');
      }
    getSearchs('scheduled');
  }
  useEffect(() => {
    getSearchs(filter, false);
  }, [props.refreshTime])
  useEffect(() => {
    if (id != null)
      getSearchs('all')
  }, [id])
  const getStatusFromGame = (id: number) => {
    if (id == 4) return 'CANCELED'
    if (id == 3) return 'COMPLETED'
    if (id == 2) return 'FULL'
    if (id == 1) return 'FULL'
    if (id == 0) return 'JOIN'

    return 'COMPLETED'
  }
  const { executeLogin } = useLogin()
  const getGameButton = (id: number, state: number,isMine: boolean, bet: number) => {
    if (state == 0) {
      return (
        <div className={styles.tableBtns}>
          { !isMine ? <Button
          additionalClassnames={styles.tableBtn}
            variant="primary"
            onClick={() => {
              if (isLogged)
                join(id, bet)
              else
                executeLogin()
            }}
            title='Join game'
          >Join</Button>
          :
          <Button
            additionalClassnames={clsx(styles.tableBtn, styles.text_negative)}
            variant="primary"
            onClick={() => {
              /*cancelGame(id)*/
              setPopupGame(id);
              //getSearchs('scheduled')
            }}
            title='Cancel Game'
          >cancel</Button>}
        </div>
      )
    }
    if ((state == 2 || state == 1) && isMine) {
      return (<div className={styles.tableBtns}>
        <Button
          additionalClassnames={styles.tableBtn}
          variant="primary"
          onClick={() => {
            popitup('https://app.tradasy.io/game?id='+ id,'game' +  id.toString());
          }}
        >
          Re-Join
        </Button></div>
      )
    }
    return <a className={styles.joinBtn}>{getStatusFromGame(state)}</a>
  }

  useEffect(() => {
    if (joinState) {
      const interval = setInterval(() => {
        setJoinState(false);
        setLoading(false);
        getSearchs(filter);
      }, 2000);
      return () => clearInterval(interval);
    }
    return;
  }, [joinState, filter]);
  const filtered = (filterText: string) => {
    if (filterText == 'all')
      return undefined;
    if (filterText == 'scheduled')
      return [0];
    if (filterText == 'running')
      return [1, 2];

    return [3];
  }
  const copyToClipboard = (text: string) => {
    const textArea = document.createElement('textarea');
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
  
      try {
        document.execCommand('copy');
        alert('Invite URL Copied');
      } catch (err) {
        console.error('Unable to copy to clipboard', err);
      } finally {
        document.body.removeChild(textArea);
      }
  };

  const getSearchs = async (filter: string, loading = true) => {
    setLoading(loading);
    setPopupGame(null);
    setFilter(filter);
    const searchs = await gameService.getGames(filtered(filter))
    if (searchs.status == 200) {
      const rendered = searchs.data.map((row: any) => {
        const isMine = (row.players as any[]).find(player => player.id == id) != null;
        if (row.isPrivate && !isMine && row.state == 0) 
          return null;

        return {
          ...{ 'entry fee': row.bet + ' USDT', duration: row.duration + ' MIN.', coin: row.maxCryptos },
          player: (row.state == 0 || row.state== 1 ? <div className={styles.tableBtns}>{row.state == 0 ? '1/2' : '2/2'} { (isMine && row.state == 0) && <Button
            variant="primary"
            additionalClassnames={styles.tableBtn}
            onClick={() => {
              copyToClipboard('https://app.tradasy.io/invite?type=game&code=' + row.invite.code);
            }}
            title='Invite a friend'
          ><img src={inviteLogo} alt='Invite a friend'/></Button>}</div> :  row.state == 0 ? '1/2' : '2/2' ),
          status: getGameButton(row.id, row.state, isMine, Number(row.bet)),
          mine: (isMine && row.state == 0),
        }
      })
      setRows(rendered.filter((row: any) => row !=null))
      setLoading(false)
    }
  }

  const join = async (gameId: any, bet: number) => {
    setPopupGame(null);
    if (!isLogged) {
      executeLogin()
      return;
    }
    
    if (balance < bet ) {
      alert('Not enough USDT '+ balance);
      return;
    }
    const joinParameters = {
      gameId: gameId,
      userId: id,
    }

    socket.emit(WsAction.join_game, joinParameters)
    setJoinState(true);
    setLoading(true);
  }
  return (
    <>
    <div className={styles.columnHeader}> 
      <Button additionalClassnames={clsx(styles.filterBtn, filter == 'all'  && styles.filterBtn_selected)} children={'All'} onClick={() => { getSearchs('all');}}/>
      <Button additionalClassnames={clsx(styles.filterBtn, filter == 'scheduled' && styles.filterBtn_selected)} children={'Scheduled'} onClick={() => { getSearchs('scheduled');}}/>
      <Button additionalClassnames={clsx(styles.filterBtn, filter == 'running' && styles.filterBtn_selected)} children={'Running'} onClick={() => { getSearchs('running');}}/>
      <Button additionalClassnames={clsx(styles.filterBtn, filter == 'completed' && styles.filterBtn_selected)} children={'Completed'} onClick={() => {  getSearchs('completed');}}/>
    </div>
    <LobyTable rows={rows} columns={cols} darkTheme columnDivider isLoading={loading}/>
    { popupGame != null && <CancelGamePopup onCancel={() => { setPopupGame(null)}} gameId={popupGame} onDelete={cancelGame}/> }
    </>
  );
}