/* eslint-disable */
import { WebsocketContext } from '@api/websocket-provider'
import searchGameLogo from '@assets/icons/search-game-logo.png'
import Loader from '@components/shared/loader/loader.component'
import type { IGameVariant } from '@constants/game-variants.constant'
import { durationToEnum, gameVariants } from '@constants/game-variants.constant'
import { useAppDispatch, useAppSelector } from '@hooks/use-app-dispatch.hook'
import type { BetEnum } from '@models/game.interface'
import { WsAction, WsResponseAction } from '@models/ws.interface'
//import { setGameId } from '@store/game/game.slice'
import { selectIsLoggedIn, selectUser } from '@store/user/user.slice'
import React, { useContext, useEffect, useState } from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

//import SearchCard from './components/SearchCard/search-card.component'
import styles from './search.module.scss'
import Card from './components/card.component'
import PrizePoolComponent from '@pages/inGame/components/prize-pool.component'
import Button from '@components/shared/button/button.component'
import BattleLogComponent from '@pages/home/components/battleLog/batte-log.component'
import { useLogin } from '@hooks/use-login.hook'
import clsx from 'clsx'
import GameHeader from '@components/shared/game-header/game-header.component'
import GameLog from '@components/shared/gameLog/game-log.component'

export default function SearchPage() {
  const isLogged = useAppSelector(selectIsLoggedIn)
  const [selectedGame, setSelectedGame] = useState<IGameVariant | null>(null)
  const [isSearchStarted, setISearchStarted] = useState(false)
  const [error, setError] = useState('')
  const navigate = useNavigate()
  const { socket } = useContext(WebsocketContext)
  const dispatch = useAppDispatch()
  const { balance, id } = useAppSelector(selectUser)

  const { executeLogin } = useLogin()
  const startSearching = (searchingGame: IGameVariant, bet: number) => {
    if (!isLogged) {
      executeLogin()
      return;
    }
    setSelectedGame(searchingGame)
    if (balance < bet) {
      setError('Not enough USDT')
      return
    }
    setISearchStarted(true)
    setError('')

    const searchParameters = {
      duration: searchingGame.duration,
      bet,
    }
    socket.emit(WsAction.startSearch, searchParameters)
  }

  const cancelSearching = () => {
    socket.emit(WsAction.stopSearch)
    setSelectedGame(null)
    setISearchStarted(false)
  }

  const popitup = (url: string, windowName: string) => {
    const newwindow=window.open(url,windowName,'height=1920,width=1080');
    if (newwindow)
      newwindow.focus()
    else {
      alert('Pop-up blocked! Please enable pop-ups for this site');
      navigate(url, { replace: false, relative: 'path' })
    }
  }
  useEffect(() => {
    if (socket === null) {
      return
    }
    const searchStoppedListener = (data: string) => {
      setISearchStarted(false)
      setError(data)
    }
    socket.on(WsResponseAction.searchStopped, searchStoppedListener)

    const gameReadyListener = (data: number) => {
      popitup('https://app.tradasy.io/game?id='+ data.toString(),'game' +  data.toString());
      cancelSearching();
      /*
      dispatch(setGameId(data))
        navigate({
        pathname: '/game',
        search: createSearchParams({ id: data.toString() }).toString(),
      })*/
    }
    socket.on(WsResponseAction.gameReady + '_' + id, gameReadyListener)
  }, [dispatch, navigate, socket])

  if (!socket) {
    return (
      <div className={styles.loader}>
        <Loader color="#02959f" />
        <p>Establishing a connection...</p>
      </div>
    )
  }

  return (
    <div>
      <GameHeader/>
    <main className={styles.search}>
      <div className={styles.title}>Select Game Type
        
        <div className={styles.titleButtons}>
          <Button additionalClassnames={clsx(styles.btn, styles.btn_selected)} variant='primary' onClick={() => { navigate('/standard-game') }}> Standard</Button>
          <Button additionalClassnames={styles.btn} variant='secondary' onClick={() => { navigate('/custom-game') }}> Custom</Button>
          <Button additionalClassnames={styles.btn} variant='secondary' onClick={() => { navigate('/tournament') }}> tournament</Button>
        </div>
      </div>
      <div className={styles.cardsWrapper}>
        {gameVariants.map((card, index) => (
          <Card
            key={card.id}
            card={card}
            selectedGame={selectedGame}
            isSearchStarted={isSearchStarted}
            startSearching={startSearching}
            cancelSearching={cancelSearching}
            error={error}
            freeplay={ index == 0}
          />
        ))}
      </div>

      <div className={styles.mobileSlider}>
        <h3>Choose game duration</h3>
        <Swiper
          slidesPerView={1}
          modules={[Pagination]}
          pagination={{
            clickable: true,
            renderBullet(index, className) {
              return `<span class="${className}">${gameVariants[index]?.duration}</span>`
            },
            bulletClass: styles.mobileBullet,
            bulletActiveClass: styles.mobileBullet_active,
          }}
          noSwiping={true}
          noSwipingClass="rc-slider"
        >
          {gameVariants.map((card, index) => (
            <SwiperSlide key={card.id}>
              <Card
                key={card.id}
                card={card}
                selectedGame={selectedGame}
                isSearchStarted={isSearchStarted}
                startSearching={startSearching}
                cancelSearching={cancelSearching}
                error={error}
                freeplay={ index == 0}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className={styles.contentBoard}>
        <GameLog/>
      </div>
    </main>
    </div>
  )
}
