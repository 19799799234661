import { GameStateEnum, ICryptos, IGame, IPlayer, IRounds } from "@models/game.interface";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@store/store";
import _ from "lodash";

const initialState: IRounds = { rounds: []};
interface ISetCryptos { id: number, cryptos: ICryptos[] };
interface IsetGameStartDate { id: number, date: string };
interface ISetGameWinner { id: number, player: IPlayer | null };
const defaultGame = {
  id: null,
  bet: 0,
  duration: 10,
  startsAt: Date.now().toString(),
  endsAt: Date.now().toString(),
  winner: null,
  state: GameStateEnum.preparation,
  maxCryptos: 3,
  cryptos: [],
};
export const roundsSlice = createSlice({
    name: 'rounds',
    initialState,
    reducers: {
      setRound: (state, action: PayloadAction<IGame>) => {
        const { id } = action.payload;
        state.rounds[id!] = action.payload;
      },
      removeRound: (state, action: PayloadAction<number>) => {
        delete state.rounds[action.payload];
      },
      tempStartRound: (state, action: PayloadAction<number>) => {
        const round = state.rounds[action.payload];
        if (round) {
          round.state = GameStateEnum.started
        }
      },
      tempEndRound: (state, action: PayloadAction<number>) => {
        const round = state.rounds[action.payload];
        if (round) {
          round.state = GameStateEnum.ended
        }
      },
      setRoundCrytos: (state, action: PayloadAction<ISetCryptos>) => { 
        const { id, cryptos } = action.payload;
        const round = state.rounds[id];
        if (round) {
          round.cryptos = cryptos;
        }
      },
      setRoundStartDate: (state, action: PayloadAction<IsetGameStartDate>) => { 
        const { id, date } = action.payload;
        const round = state.rounds[id];
        if (round) {
          round.startsAt = date;
        }
      },
      setRoundWinner: (state, action: PayloadAction<ISetGameWinner>) => { 
        const { id, player } = action.payload;
        const round = state.rounds[id];
        if (round) {
          round.winner = player;
        }
      },
      resetRounds: () => {
        return initialState
      },
    },
  });

  export const selectRound = (state: RootState, id: number) => { return state.roundsReducer.rounds[id] || defaultGame }

  export const selectIsWinner = (state: RootState, id: number) => {
    return state.userReducer.user.id == state.roundsReducer.rounds[id]?.winner?.id
  }

  export const selectOpponent = (state: RootState, id: number): { id: number; username: string } => {
    const myId = state.userReducer.user.id
    const myCryptosIndex = _.findIndex(state.roundsReducer.rounds[id]?.cryptos, { user: { id: myId } })
    const oppCryptosIndex = myCryptosIndex === 0 ? 1 : 0
    const opp = state.roundsReducer.rounds[id]?.cryptos[oppCryptosIndex]
    if (opp && opp.user) {
      return opp.user
    }
    return { id: 0, username: '' }
  }

  export const {
    setRound,
    removeRound,
    resetRounds,
    setRoundCrytos,
    setRoundStartDate,
    setRoundWinner,
    tempStartRound,
    tempEndRound
  } = roundsSlice.actions;
  export default roundsSlice.reducer;