import { useAppSelector } from '@hooks/use-app-dispatch.hook'
import { useWithdrawHook } from '@hooks/use-withdraw.hook'
import { selectUser } from '@store/user/user.slice'
import { isInputDecimalValid } from '@utils/utils'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'

import Button from '../button/button.component'
import ModalWrapper from '../modal-wrapper/modal-wrapper.component'
import type { IModalProperties } from './modal.interface'
import styles from './modals.module.scss'
import TransactionLoader from './transaction-loader.component'

export default function WithdrawModal(props: IModalProperties) {
  const { isOpen, onClose } = props
  const [inputError, setInputError] = useState('')
  const [amount, setAmount] = useState('')
  const { balance } = useAppSelector(selectUser)

  const {
    withdraw,
    status: transactionStatus,
    error,
    setStatus: setTransactionStatus,
  } = useWithdrawHook(amount)

  const handleWithdraw = (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (+amount > balance) {
      setInputError('Not enough funds to withdraw')
      return
    }
    withdraw()
  }

  const handleInput = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    const isValid = isInputDecimalValid(value)
    if (isValid === false) {
      return
    }
    setAmount(value)
    setInputError('')
  }

  useEffect(() => {
    if (transactionStatus === 'success') {
      onClose()
      setAmount('')
    }
  }, [transactionStatus])

  const renderContent = () => {
    switch (transactionStatus) {
      case 'loading':
        return <TransactionLoader />
      case 'failed':
        return (
          <div className={styles.form}>
            <h4 className={styles.title}>Transaction failed</h4>
            <p className={styles.description}>Reason: {error}</p>
            <Button additionalClassnames={styles.btn} onClick={() => setTransactionStatus('none')}>
              Try again
            </Button>
          </div>
        )
      default:
        return (
          <>
            <form className={styles.form} onSubmit={handleWithdraw}>
              <h4 className={styles.title}>Withdraw</h4>
              <p className={styles.label} style={{ marginTop: '100px'}}>Withdraw amount (USDT)</p>
              <input
                type="number"
                className={clsx('input', inputError && 'input_error')}
                required
                value={amount}
                onChange={(e) => handleInput(e)}
                step=".01"
              />
              <Button type="submit" additionalClassnames={styles.btn} disabled={!!inputError}>
                Withdraw
              </Button>
            </form>
            {inputError && <small className={styles.formError}>{inputError}</small>}
          </>
        )
    }
  }

  return (
    <ModalWrapper
      isOpen={isOpen}
      toggle={() => (transactionStatus === 'loading' ? null : onClose())}
    >
      {transactionStatus !== 'loading' && (
        <button className={styles.closeBtn} onClick={onClose}>
          X
        </button>
      )}
      {renderContent()}
    </ModalWrapper>
  )
}
