import React from 'react'
import { FadeLoader } from 'react-spinners'
import style from './loader.module.scss';

interface ILoaderProperties {
  color?: string
}

export default function Loader(props: ILoaderProperties) {
  const { color = '#000000' } = props

  return <FadeLoader height={12} width={4} margin={1} color={color} />
}
