import { useAppSelector } from '@hooks/use-app-dispatch.hook'
import style from './right-bar.module.scss';
import clsx from 'clsx';
import tetherLogo from '@assets/icons/tether.png'
import Timer from '../Timer/timer.component';
import Button from '@components/shared/button/button.component';


export default function Cell(props: { title?: string, variant: 'primary' | 'secondary', children: any, style: React.CSSProperties | undefined}) {
    return (
        <div className={clsx(style.cell, props.variant == 'secondary' && style.cell_secondary, props.title== 'Distribution' && style.cell_dist)} style={props.style}>
            { props.title && <h2 className={style.title}>{props.title}</h2>}
            <div className={style.childContent} >{props.children}</div>
        </div>
    )
  }
  