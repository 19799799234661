import authService from '@api/services/auth.service'
import { GameStateEnum } from '@models/game.interface'
import { setLoggedIn, setLoggedOut, setUser, setWallet } from '@store/user/user.slice'
import { useEthers } from '@usedapp/core'
import { checkAndChangeChainId } from '@utils/utils'
import { createSearchParams, useNavigate } from 'react-router-dom'

import { useAppDispatch } from './use-app-dispatch.hook'
import { useGetBalance } from './use-get-balance.hook'
import { WebsocketContext } from '@api/websocket-provider'
import { useContext } from 'react'

export const useLogin = () => {
  const { activateBrowserWallet, account, deactivate, library } = useEthers()
  const dispatch = useAppDispatch()
  const { getBalanceByWallet } = useGetBalance()
  const navigate = useNavigate()
  const { socket } = useContext(WebsocketContext)

  const createSignature = async () => {
    if (!library || !account) {
      return
    }
    try {
      // !!! Date + 1 day, change it later
      const expirationDate = new Date(new Date().setDate(new Date().getDate() + 1)).toISOString()
      const message = `Tradasy Login\nAddress: ${account}\nExpiration: ${expirationDate}`
      // @ts-ignore
      const signer = library.getSigner()
      const signature = await signer.signMessage(message)
      const signPostman = await signer.signMessage('Tradasy Login Address: 0x0C0F2811D0E8afF4541Ee0593f234755CE39232c Expiration: 2023-10-25T12:03:28.771Z');
      const address = await signer.getAddress()
      return {
        message,
        signature,
        address,
      }
    } catch (error) {
      return { error }
    }
  }

  const getUserInfo = async () => {
    try {
      const userInfo = await authService.getUserInfo()
      dispatch(setUser(userInfo.data))
      dispatch(setWallet(userInfo.data.address))
      await getBalanceByWallet(userInfo.data.address);
      /*const lastGameId = userInfo.data.gameId?.toString()
      const gameInProgress = userInfo.data.gameState === GameStateEnum.started
      if (lastGameId && gameInProgress) {
        navigate({ pathname: '/game', search: createSearchParams({ id: lastGameId }).toString() })
      } else {
        navigate('/standard-game')
      }*/
    } catch (error) {
      console.log(error)
    }
  }

  const executeLogin = async () => {
    if (!window.ethereum) {
      alert('Please install the Metamask extension.')
      dispatch(setLoggedOut())
      return
    }

    try {
      await checkAndChangeChainId().then(async () => {
        await activateBrowserWallet()
      })
      const signResult = await createSignature()
      if (!signResult || signResult?.error) {
        dispatch(setLoggedOut())
        localStorage.removeItem('trokens')
        return
      }
      // @ts-ignore
      const response = await authService.logIn(signResult?.message, signResult?.signature)
      const userTokens = JSON.stringify(response.data)
      localStorage.setItem('trokens', userTokens)
      if (socket){
        socket.emit('update_connection',{ token: JSON.parse(userTokens) })
      }
      dispatch(setLoggedIn())
      navigate('/standard-game')
    } catch (error) {
      console.log(error)
      dispatch(setLoggedOut())
      localStorage.removeItem('trokens')
    }
  }

  const checkMetaMaskLoginState = () => {
    return account;
  }

  const refreshTokens = async () => {
    const { refreshToken } = JSON.parse(localStorage.getItem('trokens') as string)
      try {
       /*await checkAndChangeChainId().then(async (data) => {
        console.log(data);
        await activateBrowserWallet()
      })*/
      const response = await authService.refreshTokens(refreshToken)
      const userTokens = JSON.stringify(response.data)
      localStorage.setItem('trokens', userTokens)
      dispatch(setLoggedIn())
      await getUserInfo();
    } catch (error) {
      console.log(error)
      localStorage.removeItem('trokens')
      dispatch(setLoggedOut())
    }
  }

  const logout = async () => {
    await authService.logout()
    dispatch(setLoggedOut())
    deactivate()
    localStorage.removeItem('trokens')
  }

  return { executeLogin, logout, refreshTokens, getUserInfo, checkMetaMaskLoginState }
}
