import { deleteRequest, getRequest, postRequest } from '@api/axios-client'
import type { IAccessTokens, IMeResponse } from '@api/response.interface'
import { IGameLog } from '@models/game.interface'

class AuthService {
  async logIn(message: string, signature: string) {
    return await postRequest<IAccessTokens>('api/auth/log-in', { message, signature })
  }

  async refreshTokens(refreshToken: string) {
    return await postRequest<IAccessTokens>('api/auth/refresh-tokens', { value: refreshToken })
  }

  async logout() {
    return await deleteRequest('api/auth/log-out')
  }

  async setUserAttr(username: string, refCode: string | null) {
    return await postRequest('api/auth/me/username', { name: username, code: refCode })
  }

  async validateRefCode(code: string) {
    return await getRequest('api/auth/validate/'+code)
  }

  async getUserInfo() {
    return await getRequest<IMeResponse>('api/auth/me')
  }
}

export default new AuthService()
