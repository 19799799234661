import AppRouter from '@components/app.router'
import {useAppDispatch, useAppSelector} from '@hooks/use-app-dispatch.hook'
import { useLogin } from '@hooks/use-login.hook'
import {selectIsLoggedIn, setLoggedOut} from '@store/user/user.slice'
import { useEffect } from 'react'

function App(): JSX.Element {
  const { refreshTokens, getUserInfo, checkMetaMaskLoginState, executeLogin } = useLogin()
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (isLoggedIn) {
      getUserInfo()
      return
    }

    const tokens = localStorage.getItem('trokens')
    if (tokens) {
      refreshTokens()
    }else{
      dispatch(setLoggedOut())
    }
  }, [isLoggedIn])

  return <AppRouter />
}

export default App
