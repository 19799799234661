import gameService from '@api/services/game.service';
import { timeAgo } from '@utils/utils';
import clsx from 'clsx';
import { useState } from 'react';
import Button from '../button/button.component';
import LogTable from './components/log-table.component';
import style from './game-log.module.scss';

export default function GameLog(): JSX.Element {
    const [tab, setTab] = useState('leaderboard');
    const [detailsOpen, setDetailOpen] = useState(false);
    const leaderboardCols = ['ranking', 'username', 'points'];
    const battleLogCols = [ 'players', 'time', 'duration', 'prize', 'winner'];
    const [rows, setRows] = useState<any[]>([ { 'ranking': '', 'username': '', 'points': ''},
    { 'ranking': '', 'username': '', 'points': ''},
    { 'ranking': '', 'username': '', 'points': ''},
    { 'ranking': '', 'username': '', 'points': ''},
    { 'ranking': '', 'username': '', 'points': ''},
    { 'ranking': '', 'username': '', 'points': ''},
    { 'ranking': '', 'username': '', 'points': ''}]);
    const getRows = async (table: string) => {
        if (table == 'leaderboard')
            setRows([ { 'ranking': '', 'username': '', 'points': ''},
            { 'ranking': '', 'username': '', 'points': ''},
            { 'ranking': '', 'username': '', 'points': ''},
            { 'ranking': '', 'username': '', 'points': ''},
            { 'ranking': '', 'username': '', 'points': ''},
            { 'ranking': '', 'username': '', 'points': ''},
            { 'ranking': '', 'username': '', 'points': ''}]);
        else  {
            const response = await gameService.getBattleLog();
            if (response.status == 200 && response.data) {
                setRows(response.data.map( (dataRow: any) => {
                    if (dataRow.players.length != 2)
                    return null;
            
                    return { players : (dataRow.players ? dataRow.players[0].username + ' vs ' + dataRow.players[1].username : ''), time: timeAgo(dataRow.startsAt), duration: (dataRow.duration + 'Mins'), prize: (dataRow.bet + 'USDT'), winner: (dataRow.winner ? dataRow.winner.username : '')}
                }));
            }
        }
    };

    const changeTab = async (tab: string) => {
        setTab(tab)
        if (tab == 'battle log')
            setDetailOpen(false);

        await getRows(tab);
    }

    return (
        <div className={style.container}>
            <div className={style.tabs}>
                <Button onClick={() => { changeTab('leaderboard'); }} additionalClassnames={clsx(style.tabBtn, tab == 'leaderboard' && style.tabBtn_selected)}>Leaderboard</Button>
                <Button onClick={() => { changeTab('battle log'); }} additionalClassnames={clsx(style.tabBtn, tab == 'battle log' && style.tabBtn_selected)}>Battle Log</Button>
            </div>
            <div className={style.content}>
                {  !detailsOpen ?
                    <LogTable title={tab} cols={tab == 'leaderboard' ? leaderboardCols : battleLogCols} rows={rows}/>
                    :
                    <div className={style.details}>
                        <LogTable title={'Highest Revenue'} cols={leaderboardCols} rows={rows} smallSize/>
                        <LogTable title={'Highest Win Rate'} cols={leaderboardCols} rows={rows} smallSize/>
                        <LogTable title={'Highest Volume'} cols={leaderboardCols} rows={rows} smallSize/>
                        <LogTable title={'Highest Commision Earned'} cols={leaderboardCols} rows={rows} smallSize/>
                    </div>
                }
            </div>
                <Button additionalClassnames={clsx(style.detailBtn, tab == 'battle log' && style.detailBtn_hide)} onClick={() => {setDetailOpen(!detailsOpen)}}>
                    View  { detailsOpen ? 'Leaderboard' : 'Details'}
                </Button>
        </div>
    )
}