import Button from '@components/shared/button/button.component'
import ModalWrapper from '@components/shared/modal-wrapper/modal-wrapper.component'
import { useDeposit } from '@hooks/use-deposit.hook'
import { isInputDecimalValid } from '@utils/utils'
import infoIcon from '@assets/icons/information.svg';
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'

import type { IModalProperties } from './modal.interface'
import styles from './modals.module.scss'
import TransactionLoader from './transaction-loader.component'

export default function BonusesModal(props: IModalProperties) {
  const { isOpen, onClose } = props
  const [inputError, setInputError] = useState('')
  const [amount, setAmount] = useState('')


  const handleFormApprove = (e: React.SyntheticEvent) => {
    e.preventDefault()
  }

  const handleInput = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    const isValid = isInputDecimalValid(value)
    if (isValid === false) {
      return
    }
    setAmount(value)
    setInputError('')
  }

  const renderContent = () => {
        return (
          <>
            <form className={styles.form} onSubmit={handleFormApprove}>
              <h4 className={styles.title} >Current Bonues</h4>
              <p className={styles.label}  style={{ fontSize:'84px', marginTop: '150px', marginBottom: '10px' ,marginLeft: '30px'}}>50 USDT</p>
              <div className={styles.liner}>
                        <img className={styles.info} src={infoIcon}/>
                        <div className={styles.bar}></div>
                    </div>
                    
                    <div className={styles.values}>320/500</div>
            </form>
            {inputError && <small className={styles.formError}>{inputError}</small>}
          </>
        )
  }


  return (
    <ModalWrapper
      isOpen={isOpen}
      toggle={() => (onClose())}
    >
      
      <button className={styles.closeBtn} onClick={onClose}>
          X
      </button>
      {renderContent()}
    </ModalWrapper>
  )
}
