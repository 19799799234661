import Button from '@components/shared/button/button.component'
import ModalWrapper from '@components/shared/modal-wrapper/modal-wrapper.component'
import { useDeposit } from '@hooks/use-deposit.hook'
import { isInputDecimalValid } from '@utils/utils'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'

import type { IModalProperties } from './modal.interface'
import styles from './modals.module.scss'
import TransactionLoader from './transaction-loader.component'
import { useLogin } from '@hooks/use-login.hook'

export default function DepositModal(props: IModalProperties) {
  const { isOpen, onClose } = props
  const [inputError, setInputError] = useState('')
  const [amount, setAmount] = useState('')
  const { checkMetaMaskLoginState, executeLogin } = useLogin()

  const { error, handleApprove, status: transactionStatus, setStatus } = useDeposit(amount)

  const handleFormApprove = (e: React.SyntheticEvent) => {
    e.preventDefault()
    const metaSate = checkMetaMaskLoginState();
    if (!metaSate) {
      executeLogin();
    } else
      handleApprove()
  }

  const handleInput = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    const isValid = isInputDecimalValid(value)
    if (isValid === false) {
      return
    }
    setAmount(value)
    setInputError('')
  }

  const renderContent = () => {
    switch (transactionStatus) {
      case 'loading':
        return <TransactionLoader />
      default:
        return (
          <>
            <form className={styles.form} onSubmit={handleFormApprove}>
              <h4 className={styles.title}>Deposit</h4>
              <p className={styles.errorTransaction} style={{ visibility:  (error ? 'visible' : 'hidden')}} >{'(' + error + ')'}</p>
              <p className={styles.label}>Deposit amount (USDT)</p>
              <input
                type="number"
                className={clsx('input', inputError && 'input_error')}
                required
                value={amount}
                onChange={(e) => handleInput(e)}
                step=".01"
              />
              <p className={styles.label}>Bonus Code</p>
              <input
                type="text"
                className={clsx('input', inputError && 'input_error')}
                required
                value={'WELCOME30'}
                onChange={(e) => handleInput(e)}
                style={{ fontSize: '35px'}}
              />
              <Button type="submit" additionalClassnames={styles.btn} disabled={!!inputError}>
              Deposit
              </Button>
            </form>
            {inputError && <small className={styles.formError}>{inputError}</small>}
          </>
        )
    }
  }

  useEffect(() => {
    if (transactionStatus === 'success') {
      onClose()
      setAmount('')
    }
  }, [transactionStatus])

  return (
    <ModalWrapper
      isOpen={isOpen}
      toggle={() => (transactionStatus === 'loading' ? null : onClose())}
    >
      {transactionStatus !== 'loading' && (
        <button className={styles.closeBtn} onClick={onClose}>
          X
        </button>
      )}
      {renderContent()}
    </ModalWrapper>
  )
}

