export enum WsAction {
  startSearch = 'start_search',
  join_game= 'join_game',
  join_tournament = 'join_tournament',
  stopSearch = 'stop_search',
  chooseCryptos = 'choose_cryptos',
  chooseRoundCryptos = 'choose_round_cryptos',
  currencyPicked = 'currency_picked',
  currencyRoundPicked = 'currency_round_picked',
  playWithBot = 'play_with_bot',
  endGameWithBot= 'end_game_with_bot',
  sendMessage = 'send_message',
  sendRoundMessage = 'send_round_message',
  sendTournamentMessage = 'send_tournament_message',
}

export enum WsResponseAction {
  currency = 'currency',
  gameNotReady = 'game_not_ready',
  tournamentNotReady = 'tournament_not_ready',
  tournamentOpen = 'tournament_open',
  gameReady = 'game_ready',
  gameStarted = 'game_started',
  gameEnds = 'game_ends',
  roundReady = 'round_ready',
  tournamentSeq = 'tournament_seq',
  roundMessage = 'round_message',
  tournamentMessage = 'tournament_message',
  roundStarted = 'round_started',
  roundEnds = 'round_ends',
  tournamentEnds = 'tournament_ends',
  searchStopped = 'search_stopped',
  gameCanceled = 'game_canceled',
  roundCanceled = 'round_canceled',
  allCurrencies = 'all_currencies',
  gameCurrencies = 'game_currencies',
  roundCurrencies = 'round_currencies',
  gameCurrenciesBot = 'game_currencies_bot',
  battleLog = 'battle_log',
  receiveMessage = 'receive_message',
  receiveRoundMessage = 'receive_round_message',
  receiveTournamentMessage = 'receive_tournament_message',
}
