import Button from '@components/shared/button/button.component';
import styles from '../loby.module.scss';
import clsx from 'clsx';
import { useState } from 'react';
import { IGameVariant, betNumberToEnum } from '@constants/game-variants.constant';
import authService from '@api/services/auth.service';
import gameService from '@api/services/game.service';
import InviteFriend from './invite-friend.component';
import { useLogin } from '@hooks/use-login.hook';
import { useAppSelector } from '@hooks/use-app-dispatch.hook';
import { selectIsLoggedIn, selectUser } from '@store/user/user.slice';
import Loader from '@components/shared/loader/loader.component';
import lockImage from '@assets/icons/private-lock.svg';


export default function CustomPoolComponent(props: { setRefreshTime: (time: number) => void}) {
  const isLogged = useAppSelector(selectIsLoggedIn)
  const { id, balance } = useAppSelector(selectUser)
  const [inputError, setInputError] = useState('');
  const [isCreatedGame, setIsCreatedGame] = useState<number | null>(null);
  const [buyInState, setBuyInState] = useState<number | null>(null);
  const [duration, setDuration] = useState<number>(5);
  const [isprivate, setPrivate] = useState(false);
  const [maxCryptos, setMaxCryptos] = useState(3);
  const [inviteState, setInviteState] = useState(false);
  const [createState, setCreateState] = useState<any>('Create Pool');
  const [showPopup, setShowPopup] = useState(false);
  const [generatedLink, setGeneratedLink] = useState('')
  const getRandomString = () => {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
    
      for (let i = 0; i < 5; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
      }
    
      return result;
    };
    
  const { executeLogin } = useLogin()
  const handleStartSearch = async () => {
    if (!isLogged) {
      executeLogin()
      return;
    }
    if (duration == null || buyInState==null) {
      return;
    }
    
    if (balance < buyInState ) {
      alert('Not enough USDT');
      return;
    }
    setCreateState(<Loader color='#7EFBEF'/>);
    /*if (isCreatedGame != null){
      const cancelState = await gameService.cancelGame(isCreatedGame)
      if (cancelState.status == 200){
        alert('Game Canceled!');
        setIsCreatedGame(null)
        setInviteState(false)
      }
      return;
    }*/

    const inviteCode = getRandomString();
    const gameState = await gameService.createGame(id!,duration, buyInState, inviteCode, isprivate, maxCryptos);
    if (gameState.status == 201 && gameState.data){
      setIsCreatedGame(gameState.data.id);
      setInviteState(true);
      setGeneratedLink('https://app.tradasy.io/invite?code=' +inviteCode);
      setCreateState('Created');
      props.setRefreshTime(Date.now())
    }
    setTimeout(() => { setCreateState('Create Pool')}, 2000);
  }
  
  const arrangeNumber = (value: number, min: number, max: number) => {
    if (value < min)
      return min;

    if (value > max)
      return max

    return value;
  }
  return (
    <div className={styles.customPoolWrapper}>
    { !isLogged && <div className={styles.notLogged}><img src={lockImage}/></div>}
      <h3>Create Custom Pool</h3>
      <form className={styles.form}>
        <div className={styles.formRow}>
          <h4> Entry Fee</h4>
          <input
            type="number"
            className={clsx('input', inputError && 'input_error')}
            required
            min={0}
            max={100}
            value={buyInState!}
            placeholder="(1USDT - 100USDT)"
            onChange={(event) => {
              setBuyInState(arrangeNumber(Number(event.target.value), 0, 100))
            }}
          />
        </div>
        <div className={styles.formRow}>
          <h4> Duration - (Minutes)</h4>
          <div className={styles.tokens}>
            <Button
              additionalClassnames={styles.tokenButton}
              variant={duration == 5 ? 'primary' : 'secondary'}
              onClick={() => {
                setDuration(5)
              }}
            >
              5
            </Button>
            <Button
              additionalClassnames={styles.tokenButton}
              variant={duration == 10 ? 'primary' : 'secondary'}
              onClick={() => {
                setDuration(10)
              }}
            >
              10
            </Button>
            <Button
              additionalClassnames={styles.tokenButton}
              variant={duration == 15 ? 'primary' : 'secondary'}
              onClick={() => {
                setDuration(15)
              }}
            >
              15
            </Button>
            <Button
              additionalClassnames={styles.tokenButton}
              variant={duration == 30 ? 'primary' : 'secondary'}
              onClick={() => {
                setDuration(30)
              }}
            >
              30
            </Button>
            <Button
              additionalClassnames={styles.tokenButton}
              variant={duration == 45 ? 'primary' : 'secondary'}
              onClick={() => {
                setDuration(45)
              }}
            >
              45
            </Button>
            <Button
              additionalClassnames={styles.tokenButton}
              variant={duration == 60 ? 'primary' : 'secondary'}
              onClick={() => {
                setDuration(60)
              }}
            >
              60
            </Button>
          </div>
        </div>
        <div className={styles.formRow}>
          <h4> Coin Number</h4>
          <div className={styles.tokens}>
            <Button
              additionalClassnames={styles.tokenButton}
              variant={maxCryptos == 3 ? 'primary' : 'secondary'}
              onClick={() => {
                setMaxCryptos(3)
              }}
            >
              3
            </Button>
            <Button
              additionalClassnames={styles.tokenButton}
              variant={maxCryptos == 4 ? 'primary' : 'secondary'}
              onClick={() => {
                setMaxCryptos(4)
              }}
            >
              4
            </Button>
            <Button
              additionalClassnames={styles.tokenButton}
              variant={maxCryptos == 5 ? 'primary' : 'secondary'}
              onClick={() => {
                setMaxCryptos(5)
              }}
            >
              5
            </Button>
          </div>
        </div>
        <div className={styles.formRow}>
          <Button
            additionalClassnames={clsx(styles.privateBtn, isprivate && styles.privateBtn_private)}
            onClick={() => {
              setPrivate(!isprivate)
            }}
            variant={isprivate ? 'primary' : 'secondary'}
          >
            <div className={clsx(styles.privateSp, isprivate && styles.privateSp_passive)}>
              {' '}
              Public
            </div>
            <div className={clsx(styles.privateSp, !isprivate && styles.privateSp_passive)}>
              {' '}
              Private
            </div>
          </Button>
        </div>
        {inputError}
        <div className={styles.formRow}>
          <Button
            additionalClassnames={clsx(
              styles.btn,
              (createState != 'Create Pool' || duration == null || buyInState == null) &&
                styles.btn_creating
            )}
            disabled={
              !!inputError || createState != 'Create Pool' || duration == null || buyInState == null
            }
            variant={createState == 'Create Pool' ? 'primary' : 'secondary'}
            onClick={handleStartSearch}
          >
            {createState} {/* isCreatedGame!=null ? 'Cancel' : 'Create Pool' */}
          </Button>
        </div>
      </form>

      <div
        className={clsx(
          styles.actionButtons,
          (createState != 'Create Pool' || duration == null || buyInState == null) && styles.btn_creating
        )}
      >
        {/*<Button additionalClassnames={styles.btn} variant='secondary' disabled={!inviteState} onClick={() => { setShowPopup(!showPopup)}}> Invite Friend</Button>*/}
      </div>
      {showPopup && <InviteFriend generatedLink={generatedLink} />}
    </div>
  )
}