import { useAppSelector } from '@hooks/use-app-dispatch.hook'
import style from '../in-game.module.scss';
import clsx from 'clsx';
import Timer from './Timer/timer.component';
import tetherLogo from '@assets/icons/tether.png'
import { selectGame } from '@store/game/games.slice';
import { selectRound } from '@store/tournament/rounds.slice';


export default function PrizePoolComponent(props: { gameId: number, expiryTime: any; isGame?: boolean, isRound?: boolean }) {
  const { bet } =  useAppSelector((state) => selectGame(state, props.gameId));
  return (
        <div className={clsx(style.wrapper, style.wrapper_inner, style.wrapper_withoutHeight, props.isGame && style.safeWrapper,style.poolTitle)}>
        { props.isGame ? 
          <>
            <div className={style.prizePoolTitle}><div className={clsx(style.bet, props.isGame && style.bet_game)}> <div className={style.prize}>Prize</div> <div className={style.prize}>{bet * 2}  USDT</div></div> </div>
          
            <Timer expiry={props.expiryTime} isGame={props.isGame}/>
          </>
          :
          <>
          <div className={style.prizePoolTitle}>{ props.isRound ? 'T-' + props.gameId+ ' Round' : <>Prize Pool: <div className={style.bet}>{bet * 2}  Usdt</div></>} </div>
        
            <Timer expiry={props.expiryTime} isRound={props.isRound} />
          </>
        }
        
        </div>
    )
  }
