import gameService from '@api/services/game.service'
import { WebsocketContext } from '@api/websocket-provider'
import { ReactComponent as OpponentDot } from '@assets/icons/opponent-dot.svg'
import { ReactComponent as YourDot } from '@assets/icons/your-dot.svg'
import Loader from '@components/shared/loader/loader.component'
import { useAppSelector } from '@hooks/use-app-dispatch.hook'
import { useDidMountEffect } from '@hooks/use-did-mount-effect.hook'
import type { ICurrency } from '@models/currency.interface'
import { WsResponseAction } from '@models/ws.interface'
//import { selectPickedSymbols } from '@store/currency-pick/currency-pick.slice'
//import { selectGame, selectOpponent } from '@store/game/game.slice'
import { selectUser } from '@store/user/user.slice'
import { roundNumber } from '@utils/utils'
import _ from 'lodash'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import styles from './graph.module.scss'
import { selectGame, selectOpponent } from '@store/game/games.slice'
import { selectPickedSymbols } from '@store/currency-pick/currency-picks.slice'

/*interface IGraphData {
  name: string
  your?: number
  opponent?: number
}*/

const xAxisLabels = ['-5 min', '-4 min', '-3 min', '-2 min', '-1 min', 'Current', '+1 min']

const CustomizedDot = (props: any) => {
  const { cx, cy, dataKey, payload, points, stroke } = props
  if (!points || points.length <= 0) {
    return <span></span>
  }

  const lastDot = points.findLast((element: any) => element.value[1] !== undefined)
  const fillColor = stroke; // === 'your' ? '#b54554' : '#429e52'

  if (!lastDot) {
    return <span></span>
  }

  if (payload.name === lastDot.payload.name) {
    return (
      <svg
        x={cx - 11}
        y={cy - 11}
        width="22"
        height="22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_f_205_4441)">
          <circle cx="11" cy="11" r="6" fill={fillColor} />
        </g>
        <circle cx="11" cy="11" r="4" fill={fillColor} />
        <defs>
          <filter
            id="filter0_f_205_4441"
            x="0"
            y="0"
            width="22"
            height="22"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="2.5" result="effect1_foregroundBlur_205_4441" />
          </filter>
        </defs>
      </svg>
    )
  }

  return null
}

const recordsIn5Min = 150

export default function Graph(props: { gameId: number }) {
  const { myPickedSymbols, opponentPickedSymbols } = useAppSelector((state) => selectPickedSymbols(state, props.gameId))
  const [graphData, setGraphData] = useState<any[]>([])
  const { socket, isReady } = useContext(WebsocketContext)
  const { startsAt, id } = useAppSelector((state) => selectGame(state, props.gameId))
  const { username } = useAppSelector(selectUser)
  const { username: oppUsername } = useAppSelector((state) => selectOpponent(state, props.gameId))
  const isHistoryFetched = useRef(false)
  const [history, setHistory] = useState<any>([])

  const createMergedValue = (array: ICurrency[], isYours: boolean) => {
    const merged: any = { name: '' }
    for (const oneItem of array) {
      if (!oneItem)
        continue;
      
      merged.name = oneItem.createdAt
      if (isYours) {
        const yourSum = +array.reduce(
          (previous: number, current: ICurrency) => previous + Number(current ? current.change : 0),
          0
        )
        merged[username] = +roundNumber(yourSum)
      } else {
        const opponentSum = +array.reduce(
          (previous: number, current: ICurrency) => previous + Number(current ? current.change : 0),
          0
        )
        merged[oppUsername] = +roundNumber(opponentSum)
      }
    }
    return merged
  }

  const combineHistoryRecords = (historyData: any, isYours: boolean) => {
    const result: any[] = []
    const numberOfTokens = historyData.length
    for (let i = 0; i < historyData[0].length; i++) {
      const res = []
      for (let j = 0; j < numberOfTokens; j++) {
        res.push(historyData[j][i])
      }
      const merged = createMergedValue(res, isYours)
      result.push(merged)
    }
    return result
  }

  // Request history on startup
  useEffect(() => {
    const requestHistory = async () => {
      try {
        const response = await gameService.getGameHistory(id as number)
        if (response.data) {
          setHistory(response.data)
        }
        isHistoryFetched.current = true
      } catch (error: any) {
        console.log(error)
      }
    }

    if (
      myPickedSymbols.length > 0 &&
      opponentPickedSymbols.length > 0 &&
      id &&
      isHistoryFetched.current === false
    ) {
      requestHistory()
    }
  }, [id, myPickedSymbols, opponentPickedSymbols])

  // Transformation of history when it was received
  useEffect(() => {
    const transform = () => {
      const myHistory: any[] = []
      const oppHistory: any = []
      myPickedSymbols.forEach((tkn) => {
        // @ts-ignore
        const finded: ICurrencyHistory = _.find(history, { symbol: tkn.symbol })
        myHistory.push(finded.currencies)
      })

      opponentPickedSymbols.forEach((tkn) => {
        // @ts-ignore
        const finded: ICurrencyHistory = _.find(history, { symbol: tkn.symbol })
        oppHistory.push(finded.currencies)
      })

      const myTransformedArray = combineHistoryRecords(myHistory, true)
      const opponentTransformedArray = combineHistoryRecords(oppHistory, false)
      const merged = _.merge(
        _.keyBy(myTransformedArray, 'name'),
        _.keyBy(opponentTransformedArray, 'name')
      )
      const values = _.values(merged)

      const difference = Math.abs(Date.now() - new Date(startsAt).getTime())
      const secondsPassed = Math.floor(difference / 1000)
      const numberOfRecs = Math.round(secondsPassed / 2)
      if (numberOfRecs >= recordsIn5Min) {
        const res = values.slice(0, recordsIn5Min)
        setGraphData(res)
      } else {
        const filteredByDate = _.filter(values, (rec) => rec.name >= startsAt)
        filteredByDate.unshift({ name: 'first', [username]: 0, [oppUsername]: 0 })
        const empty: any = Array.from({ length: recordsIn5Min - filteredByDate.length }).fill({})
        filteredByDate.push(...empty)
        setGraphData(filteredByDate)
      }
    }

    if (history.length > 0) {
      transform()
    }
  }, [history])

  const updateArray = useCallback(
    (newCurrentValues: any) => {
      const newGraphData = [...graphData]

      const difference = Math.abs(Date.now() - new Date(startsAt).getTime())
      const secondsPassed = Math.floor(difference / 1000)
      const numberOfRecs = Math.round(secondsPassed / 2)

      if (numberOfRecs >= recordsIn5Min) {
        const sliced = newGraphData.slice(1, recordsIn5Min)
        sliced.push(newCurrentValues)
        setGraphData(sliced)
      } else {
        const empty = Array.from({ length: recordsIn5Min - numberOfRecs - 1 }).fill({})
        const sliced = newGraphData.slice(0, numberOfRecs)
        sliced.push(newCurrentValues, ...empty)
        setGraphData(sliced)
      }
    },
    [graphData]
  )

  useDidMountEffect(() => {
    if (!isReady) {
      return
    }

    const currencyListener = (data: ICurrency[]) => {
      const myCurrencyData = _.intersectionBy(data, myPickedSymbols, 'symbol')
      const opponentCurrencyData = _.intersectionBy(data, opponentPickedSymbols, 'symbol')

      const myMerged = createMergedValue(myCurrencyData, true)
      const oppMerged = createMergedValue(opponentCurrencyData, false)
      const merged = _.merge(myMerged, oppMerged)
      updateArray(merged)
    }
    socket.on(WsResponseAction.gameCurrencies+'_'+props.gameId, currencyListener)

    return () => {
      socket.off(WsResponseAction.gameCurrencies+'_'+props.gameId, currencyListener)
    }
  }, [isReady, updateArray])

  if (graphData.length === 0) {
    return (
      <div
        className={styles.wrapper}
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Loader color="#b54554BF" />
      </div>
    )
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.legend}>
        <p>Live performances</p>
        <div>
          <span>
            <YourDot /> {username}
          </span>
          <span>
            <OpponentDot /> {oppUsername}
          </span>
        </div>
      </div>

      <div className={styles.chart}>
        <ResponsiveContainer>
          <AreaChart data={graphData} margin={{ bottom: 20, right: -10 }}>
            <defs>
              <linearGradient id="colorYours" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#429e52" stopOpacity={1} />
                <stop offset="75%" stopColor="#429e52" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorOpponent" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#b54554" stopOpacity={1} />
                <stop offset="75%" stopColor="#b54554" stopOpacity={0} />
              </linearGradient>
            </defs>

            <CartesianGrid stroke="#4f4c4c76" strokeDasharray="0" />

            <XAxis
              dataKey="name"
              tickLine={false}
              tick={{ fill: '#fff' }}
              height={18}
              interval={21}
              dx={20}
              axisLine={false}
              tickFormatter={(value, index) => {
                if (value == 'first') {
                  return 'Started'
                }
                const date = new Date(value).toLocaleTimeString()
                return date != 'Invalid Date' ? date : '-'
              }}
            />

            <YAxis
              orientation="right"
              tick={{ fill: '#fff' }}
              tickFormatter={(value) => `${value}%`}
              type="number"
              width={60}
              tickLine={false}
              axisLine={false}
              domain={[(dataMin:number) => Number((dataMin == 0 ? -0.5 : dataMin * 1.5).toFixed(2)), (dataMax: number) => Number((dataMax == 0 ? 0.5 : dataMax * 1.5).toFixed(2))]} 
            />

            <Tooltip
              formatter={(value, name, props) => {
                if (name === username || name === oppUsername) {
                  return [`${value}%`, name]
                }
                return [value, name]
              }}
            />
            <Area
              type="monotone"
              dataKey={oppUsername}
              stroke="#b54554"
              fillOpacity={0}
              strokeWidth={3}
              fill="url(#colorOpponent)"
              dot={<CustomizedDot />}
              animationDuration={500}
              animationEasing={'ease-in-out'}
              connectNulls
            />

            <Area
              type="monotone"
              dataKey={username}
              stroke="#429e52"
              strokeWidth={3}
              fillOpacity={0}
              fill="url(#colorYours)"
              dot={<CustomizedDot />}
              animationDuration={500}
              animationEasing={'ease-in-out'}
              connectNulls
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}
