import style from '../faq.module.scss';
import Paragraph, { IParagraphProperties } from '@components/shared/paragraph/paragraph.component';

interface IParagraphList {
    values: { question: string; answer: any }[];
    icon?: boolean;
    iconType?: any;
    darkTheme?: boolean;
}

export default function ParagrapListComponent(props: IParagraphList) {
  return (
    <div className={style.questions}>
        { props.values.map(value => {
            return (
                <Paragraph question={value.question} answer={value.answer}  darkTheme={props.darkTheme} />
            );
        })}
    </div>
  )
}

