import { combineReducers } from '@reduxjs/toolkit'
import currencyPickReducer from '@store/currency-pick/currency-pick.slice'
import currencyPicksReducer from '@store/currency-pick/currency-picks.slice'
import gameReducer from '@store/game/game.slice'
import userReducer from '@store/user/user.slice'
import gamesReducer from '@store/game/games.slice'
import roundsReducer from '@store/tournament/rounds.slice'

// Include all the reducer to combine and provide to configure store.
const rootReducer = combineReducers({
  userReducer,
  currencyPickReducer,
  currencyPicksReducer,
  gameReducer,
  gamesReducer,
  roundsReducer,
})

export default rootReducer
