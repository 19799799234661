import howImage1 from '@assets/images/how-1.png'
import howImage2 from '@assets/images/how-2.png'
import howImage3 from '@assets/images/how-3.png'
import howImage4 from '@assets/images/how-4.png'
import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import metaIcon from '@assets/icons/metemask.png';
import phantomIcon from '@assets/icons/phantom.png';
import binanceIcon from '@assets/icons/binance.png';
import solanaIcon from '@assets/icons/solana.png'
import titleImage from '@assets/images/how-it-works-title.png';
import styles from './how.module.scss'

export default function How() {
  return (
    <section className={styles.wrapper}>
      <div className={styles.title}><img src={titleImage}/></div>
      <div className={styles.imageVideoWrapper}>
        <div className={styles.imageWrapper} >
          <img width={202} height={188} src={metaIcon} alt='Metamask'/>
          <img width={401} height={60} src={binanceIcon} alt='Binance Smart Chain'/>
          <img width={283} height={263} src={phantomIcon} alt='Phantom'/>
          <img width={342} height={51} src={solanaIcon} alt='Solana'/>
        </div>
        <div className={styles.videoWrapper}>
          <iframe  src="https://www.youtube.com/embed/-HTubEJ61zU" title="What Is MetaMask? | Beginner&#39;s MetaMask Tutorial In 2023!" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" ></iframe>
        </div>

      </div>
      <div className={styles.textWrapper}>
        <h4>Connect Wallet</h4>
        <span className={styles.textDivider}></span>
        <h4>Deposit USDT</h4>
        <span className={styles.textDivider}></span>
        <h4>Search game - Pick cryptocurrencies - Win USDT</h4>
        <span className={styles.textDivider}></span>
        <h4>Withdraw funds</h4>
      </div>
    </section>
  )
}
