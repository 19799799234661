import { BetEnum, DurationEnum } from '@models/game.interface'

export interface IGameVariant {
  id: number
  maxCryptos: number
  duration: number
  betNumber: number
}

export const durationToEnum = new Map<number, DurationEnum>([
  [10, DurationEnum._10min],
  [30, DurationEnum._30min],
  [60, DurationEnum._60min],
])

export const betNumberToEnum = new Map<number, BetEnum>([
  [1, BetEnum._0],
  [2, BetEnum._1],
  [3, BetEnum._2],
  [4, BetEnum._5],
  [5, BetEnum._10],
])

export const gameVariants: IGameVariant[] = [
  {
    id: 1,
    maxCryptos: 3,
    duration: 5,
    betNumber: 1,
  },
  {
    id: 2,
    maxCryptos: 4,
    duration: 10,
    betNumber: 1,
  },
  {
    id: 3,
    maxCryptos: 5,
    duration: 15,
    betNumber: 1,
  },
]
