// import { AuthGuard } from '@common/'
import { WebsocketContext, WebsocketProvider } from '@api/websocket-provider'
import ROUTES from '@constants/routes.constant'
import { useAppSelector } from '@hooks/use-app-dispatch.hook'
import MainLayout from '@layouts/main.layout'
import ErrorPage from '@pages/404/index.page'
import Home from '@pages/home/index.page'
import { selectIsLoggedIn } from '@store/user/user.slice'
import {Navigate, Route, Routes, useLocation, useNavigate} from 'react-router-dom'
import HomePage from '@pages/home/index.page'
import { useEffect, useState } from 'react'
import loadingImg from '@assets/icons/loading.png'
import style from './app.module.scss';

function AuthGuard({ children, requireAuth}: any) {

    const loading = (<div>
        <div className={style.loader}>
            <img src={loadingImg} alt='Loading'/>
        </div>
    </div>)

    const isLogged = useAppSelector(selectIsLoggedIn)
    const navigate = useNavigate()
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const [comp, setComp] = useState(loading);

    
    useEffect(() => {
        if (isLogged != undefined) {
            if(!isLogged && requireAuth){
                navigate('/')
            }
            setIsLoading(false)
            setComp(children)

        } else {
            setIsLoading(true);
        }
    }, [isLogged,location.pathname])

    if (isLoading){
        return loading
    }


    return comp
}

export default function AppRouter(): JSX.Element {
    return (
        <Routes>
            <Route path="/" element={<MainLayout />}>
                {ROUTES.map(({ path, component: Component, requireAuth }, index) => {
                    return (
                        <Route
                            path={path}
                            key={`${path}${index}`}
                            element={
                                <WebsocketProvider>
                                    <AuthGuard requireAuth={requireAuth}>
                                        <Component />
                                    </AuthGuard>
                                </WebsocketProvider>}
                        />
                    )
                })}
                <Route path="*" element={<Home/>} />
            </Route>
        </Routes>
    )
}

