import style from './currency-pick.module.scss'
import clsx from 'clsx'
import Button from '@components/shared/button/button.component'
import CurrenciesListComponent from './components/currencies-list.component'
import { useCallback, useContext, useEffect, useState } from 'react'
import { WebsocketContext } from '@api/websocket-provider'
import { useAppSelector, useAppDispatch } from '@hooks/use-app-dispatch.hook'
import { ICurrency } from '@models/currency.interface'
import gameService from '@api/services/game.service'
import { useDidMountEffect } from '@hooks/use-did-mount-effect.hook'
import { GameStateEnum } from '@models/game.interface'
import { WsAction, WsResponseAction } from '@models/ws.interface'
import Fuse from 'fuse.js'
import _ from 'lodash'
import PickedTokensComponent from './components/picked-tokens.component'
import { selectGame } from '@store/game/games.slice'
import { pickCurrency, selectPickedCurrencies } from '@store/currency-pick/currency-picks.slice'

export default function CurrencyPickPage(props: { gameId: number, prizePool: any}) {
    
    const [inputError, setInputError] = useState('');
    
  const picked = useAppSelector((state) => selectPickedCurrencies(state, props.gameId))
  const dispatch = useAppDispatch()
  const { maxCryptos, state, id, duration } = useAppSelector((state) => selectGame(state, props.gameId) )
  const [allCryptos, setAllCryptos] = useState<ICurrency[]>([])
  const [searched, setSearched] = useState<ICurrency[]>([])
  const { socket, isReady } = useContext(WebsocketContext)
  const [ready, setReady] = useState(false)

  const readyToGame = () => {
    try {
      socket.emit(WsAction.currencyPicked,id);
      setReady(!ready)
    } catch (error) {
      console.error(error);
      setReady(false)
    }
  }
  const fuse = new Fuse(allCryptos, {
    keys: ['name', 'symbol'],
    threshold: 0.2,
  })

  const handleSearch = (value: string) => {
    if (value === '') {
      setSearched(allCryptos)
      return
    }
    const searchResult = fuse.search(value)
    const res: ICurrency[] = []
    searchResult.forEach((el) => res.push(el.item))
    setSearched(res)
  }

  const selectCurrency = (currency: ICurrency) => {
    if (picked.length >= maxCryptos) {
      return
    }
    dispatch(pickCurrency({ id: props.gameId, currency: currency }))
  }

  const updateSearchedList = useCallback(
    (newData: ICurrency[]) => {
      const updatedSearched: ICurrency[] = searched.map((token) =>
        _.find(newData, { symbol: token.symbol })
      ) as ICurrency[]
      setSearched(updatedSearched)
    },
    [searched]
  )

  // Get all currencies for pick on startup
  useEffect(() => {
    if (!isReady) {
      return
    }
    const getAllCurrencies = async () => {
      try {
        const resp = await gameService.getCryptosList()
        setAllCryptos(resp.data)
        setSearched(resp.data)
      } catch (error) {
        console.log(error)
      }
    }
    getAllCurrencies()
  }, [isReady])

  // Send picked tokens through socket
  useDidMountEffect(() => {
    if (!isReady && state !== GameStateEnum.preparation) {
      return
    }
    const pickedArray = picked.map((p) => p.symbol)
    socket.emit(WsAction.chooseCryptos, { gameId: props.gameId, cryptos: pickedArray })
  }, [picked, isReady])

  // Watch for all currencies update from websocket
  useEffect(() => {
    if (!isReady) {
      return
    }
    const allCurrenciesListener = (allCurrencies: ICurrency[]) => {
      setAllCryptos(allCurrencies)
      updateSearchedList(allCurrencies)
    }
    socket.on(WsResponseAction.allCurrencies, allCurrenciesListener)

    return () => {
      socket.off(WsResponseAction.allCurrencies, allCurrenciesListener)
    }
  }, [isReady, updateSearchedList]);

  return (
    <div className={style.content}>
      <div className={style.leftColumn}>
        <div className={style.wrapper}>
          <div className={style.wrapperTitle}> {duration} Minute Game</div>
          <div className={style.pickCurrenciesRow}>
            <div className={style.pickCurrenciesCol}>
              Pick currencies({picked.length}/{maxCryptos}){' '}
            </div>
            <div className={style.pickCurrenciesCol}>
              <input
                className={clsx('input', style.additionInput, inputError && 'input_error')}
                type="text"
                name="search"
                id="search"
                placeholder="🔍 Search..."
                onChange={(event) => { handleSearch(event.target.value)}}
              />
            </div>
          </div>
          <CurrenciesListComponent tokens={searched} picked={picked} onSelect={selectCurrency} />
        </div>
      </div>
      <div className={style.rightColumn}>
        {props.prizePool}
        <PickedTokensComponent gameId={props.gameId}/>
        <Button  additionalClassnames={clsx(style.readyBtn, ready && style.readyBtn_disabled)} variant={ready ? 'third' : 'primary'}  disabled={picked.length != maxCryptos} onClick={readyToGame} children={ready ? 'CANCEL' : 'READY'}/>
      </div>
    </div>
  )
}
