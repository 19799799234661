import { getRequest, postRequest } from '@api/axios-client'
import type { ICurrencyHistory } from '@api/response.interface'
import type { ICurrency } from '@models/currency.interface'
import type { IGame, IGameLog } from '@models/game.interface'
import { IRound, ITournament } from '@store/tournament/tournament.model'

class TournamentService {
  async getPercentFee() {
    return await getRequest<number>(`api/tournament/percentFee`);
  }
  async getTournamentInfo(tournamentId: string) {
    return await getRequest<ITournament>(`api/tournament/id/${tournamentId}`)
  }
  async getTournamentRoundInfo(roundId: string) {
    return await getRequest<IRound>(`api/tournament/round/id/${roundId}`)
  }
  async cancelTournament(tournamentId: number) {
    return await getRequest<IGame>(`api/tournament/cancel/${tournamentId}`)
  }

  /*async getGameHistory(tournamentId: number) {
    return await getRequest(`api/crypto/tournament/game/${tournamentId}`)
  }
  async getGameLogs(userId: number) {
    return await getRequest<IGameLog[]>(`api/tournament/logs/${userId}`)
  }*/
  async getTournaments(filter?: number[]) {
    return await getRequest<any>(`api/tournament/tournaments/${filter}`);
  }
  async createTournament(userId: number, duration: number, bet: number, maxCryptos: number, maxPlayers: number, isPrivate: boolean, inviteCode: string) {
    return await postRequest<any>('api/tournament/create', { userId, duration, bet, maxCryptos, maxPlayers, isPrivate, inviteCode });
  }
  async getInvitedTournament(inviteCode: string) {
    return await getRequest<any>(`api/tournament/invite/${inviteCode}`)
  }
  async joinTournament(userId: number, tournamentId: number) {
    return await postRequest<any>('api/tournament/join', { userId, tournamentId});
  }
  async leaveTournament(userId: number, tournamentId: number) {
    return await postRequest<any>('api/tournament/leave', { userId, tournamentId});
  }
  async userTournamentState(userId: number, tournamentId: number) {
    return await postRequest<any>('api/tournament/user-tournament', { userId, tournamentId});
  }
  
}

export default new TournamentService()
