import * as React from 'react'

import Advantages from './components/advantages/advantages.component'
import How from './components/how/how.components'
import Advertisement from './components/advertisement/advertisement.component'
import What from './components/what/what.component'
import styles from './home.module.scss'
import BattleLogComponent from './components/battleLog/batte-log.component'
import GameHeader from '@components/shared/game-header/game-header.component'
import Header from '@components/shared/header/header.component'

export interface IMainHomeProperties {}

export default function Home() {
  return (
    <div>
    <Header/>
    <main className={styles.home}>
      <Advertisement />
      <BattleLogComponent/>
      {/*<What />*/}
      <How />
      {/*<Advantages />*/}
    </main>
    </div>
  )
}
