import { WebsocketContext } from "@api/websocket-provider";
import { useAppSelector } from "@hooks/use-app-dispatch.hook";
import { GameStateEnum } from "@models/game.interface";
import MapPrizePoolComponent from "@pages/tournamentGame/components/prize-pool.component";
import { IRound, ITournament } from "@store/tournament/tournament.model";
import { selectUser } from "@store/user/user.slice";
import clsx from "clsx";
import _ from "lodash";
import { round } from "lodash";
import { useContext, useEffect, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import style from '../tournament-map.module.scss';

export default function RoundMatchComponent(props: {tournament: ITournament, expiryTime: string, info: string, isGame?: boolean, close?: () => any}) {
    const { id } = useAppSelector(selectUser)
    const navigate = useNavigate();
      const openRoundMatch = (roundId: number) => {
        navigate({
          pathname: '/tournament-game',
          search: createSearchParams({ tournament: props.tournament.id.toString(), id: roundId.toString() }).toString(),
        })
      };

      const getBracketList = (
        finalRound: IRound
          | {
              id: number
              players: never[]
              roundLevel: number
              state: GameStateEnum.waiting
              winner: undefined
            },
        maxRound: number,
        tournamentRounds: IRound[]
      ) => {
        const roundCols: any[] = []
        for (let i = 1, k = 0; i < maxRound; i++) {
          let rounds = tournamentRounds
            .filter((round) => round.roundLevel == i)
          if (rounds.length == 0) {
            k = Math.pow(2, maxRound - i + 1) / 2
            rounds = Array(k).fill({
              id: 0,
              players: [],
              roundLevel: i,
              state: GameStateEnum.waiting,
            })
          }
          roundCols.push(
            <ul className={clsx(style.round, style.round + i.toString())} style={ i== 1 ? { marginLeft:0} : {} }>
              {rounds.slice(0, rounds.length / 2).map((round, index) => {
                const ended =
                  round.state == GameStateEnum.ended || round.state == GameStateEnum.canceled
                return (
                  <>
                    <li className={style.roundSpacer}>&nbsp;</li>
                    { ((round.players.findIndex( player => player.id == id) == 0 ? round.players : round.players.reverse())).map((player, index) => {
                      const mine = player.id == id;
                      const winner = round.winner ? round.winner.id == player.id : false;
                      return (
                        <>
                          <li className={style.game}>
                            <div
                              className={clsx(style.player, mine && style.player_mine, (ended && !winner) && style.player_eliminated)}
                              onClick={() => {
                                if (mine && !ended) openRoundMatch(round.id)
                              }}
                            >
                              {player.username}
                            </div>
                          </li>
                          {index % 2 == 0 && (
                            <li className={clsx(style.game, style.gameSpacer)}>
                              {/* <li className={clsx(style.game, style.gameSpacer, style.gameSpacer_right)}><h4>{round.roundLevel}</h4>. Round Id: {round.id}</li>*/}
                            </li>
                          )}
                        </>
                      )
                    })}
                    {round.players.length == 1 && (
                      <li className={style.game}>
                        <div className={style.player}></div>
                      </li>
                    )}
                    {round.players.length == 0 && (
                      <>
                        <li className={style.game}>
                          <div className={style.player}></div>
                        </li>
                        <li className={clsx(style.game, style.gameSpacer)}>
                          {/* <li className={clsx(style.game, style.gameSpacer, style.gameSpacer_right)}><h4>{round.roundLevel}</h4>. Round Id: {round.id}</li>*/}
                        </li>
                        <li className={style.game}>
                          <div className={style.player}></div>
                        </li>
                      </>
                    )}
                  </>
                )
              })}
              <li className={style.roundSpacer}>&nbsp;</li>
            </ul>
          )
        }
        roundCols.push(
          <ul className={clsx(style.round, style.finalSpacer)}>
            <li className={clsx(style.roundSpacer, style.roundSpacer_final)}>&nbsp;</li>
            <MapPrizePoolComponent expiryTime={props.expiryTime} info={props.info} state={props.tournament.state}/>
            <div className={style.final}>
              <div className={style.finalContainer}>
                {(finalRound.players.findIndex( player => player.id == id) == 0? finalRound.players : finalRound.players.reverse()).map((player, index) => {
                  const mine = player.id == id
                  const winner = finalRound.winner ? finalRound.winner.id == player.id  : undefined
                  return (
                    <>
                      <div className={style.finalGame}>
                        <div
                          className={clsx(
                            style.player,
                            style.player_final,
                            mine && style.player_mine, ((
                              finalRound.state == GameStateEnum.ended ||
                              finalRound.state == GameStateEnum.canceled
                            ) && !winner) && style.player_eliminated
                          )}
                          onClick={() => {
                            if (
                              mine &&
                              !(
                                finalRound.state == GameStateEnum.ended ||
                                finalRound.state == GameStateEnum.canceled
                              )
                            )
                              openRoundMatch(finalRound.id)
                          }}
                        >
                          {player.username}
                        </div>
                      </div>
                      {index % 2 == 0 && (
                        <div className={style.finalGame}>
                          <div
                            className={clsx(style.player, style.player_final, style.player_mine)}
                          >
                            {winner ? winner : ''}
                          </div>
                        </div>
                      )}
                    </>
                  )
                })}
                {finalRound.players.length == 1 && (
                  <div className={style.finalGame}>
                    <div className={clsx(style.player, style.player_final)}></div>
                  </div>
                )}
                {finalRound.players.length == 0 && (
                  <>
                    <div className={style.finalGame}>
                      <div className={clsx(style.player, style.player_final)}></div>
                    </div>
                    <div className={style.finalGame}>
                      <div
                        className={clsx(style.player, style.player_final, style.player_mine)}
                      ></div>
                    </div>
                    <div className={style.finalGame}>
                      <div className={clsx(style.player, style.player_final)}></div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <li className={clsx(style.roundSpacer, style.roundSpacer_final)}>&nbsp;</li>
          </ul>
        )
        for (let i = maxRound - 1, k = 0; i >= 1; i--) {
          let rounds = tournamentRounds
            .filter((round) => round.roundLevel == i)
          if (rounds.length == 0) {
            k++
            rounds = Array(k).fill({
              id: 0,
              players: [],
              roundLevel: i,
              state: GameStateEnum.waiting,
            })
          }
          roundCols.push(
            <ul className={clsx(style.round, style.round + i.toString(), style.round_right)} style={ i== 1 ? { marginRight:0} : {}}>
              {rounds.slice(rounds.length / 2, rounds.length).map((round, index) => {
                const ended =
                  round.state == GameStateEnum.ended || round.state == GameStateEnum.canceled
                return (
                  <>
                    <li className={style.roundSpacer}>&nbsp;</li>
                    {round.players.map((player, index) => {
                      const mine = player.id == id
                      const winner = round.winner ? round.winner.id == player.id : false;
                      return (
                        <>
                          <li className={style.game}>
                            <div
                              className={clsx(
                                style.player,
                                style.player_right,
                                mine && style.player_mine, ( ended && !winner) && style.player_eliminated
                              )}
                              onClick={() => {
                                if (mine && !ended) openRoundMatch(round.id)
                              }}
                            >
                              {player.username}
                            </div>
                          </li>
                          {index % 2 == 0 && (
                            <li
                              className={clsx(style.game, style.gameSpacer, style.gameSpacer_right)}
                            >
                              {/* <li className={clsx(style.game, style.gameSpacer, style.gameSpacer_right)}><h4>{round.roundLevel}</h4>. Round Id: {round.id}</li>*/}
                            </li>
                          )}
                        </>
                      )
                    })}
                    {round.players.length == 1 && (
                      <li className={style.game}>
                        <div className={clsx(style.player, style.player_right)}></div>
                      </li>
                    )}
                    {round.players.length == 0 && (
                      <>
                        <li className={style.game}>
                          <div className={clsx(style.player, style.player_right)}></div>
                        </li>
                        <li className={clsx(style.game, style.gameSpacer, style.gameSpacer_right)}>
                          {/* <li className={clsx(style.game, style.gameSpacer, style.gameSpacer_right)}><h4>{round.roundLevel}</h4>. Round Id: {round.id}</li>*/}
                        </li>
                        <li className={style.game}>
                          <div className={clsx(style.player, style.player_right)}></div>
                        </li>
                      </>
                    )}
                  </>
                )
              })}
              <li className={style.roundSpacer}>&nbsp;</li>
            </ul>
          )
          k = rounds.length * 2
        }
        return roundCols
      }
      let sortedRounds: IRound[] = [];
      const sortRounds = (rounds: IRound[]) => {
        let dividedRoundsFirst = rounds.slice(0, rounds.length / 2);
        const dividedLength = dividedRoundsFirst.length;
        let dividedRoundsSecond = rounds.slice(dividedLength , 2 * dividedLength);
        let isFirstStage = (_.flatten(dividedRoundsFirst.map(round => round.players))).find(player => player.id == id);
        if (isFirstStage) {
          sortedRounds = sortedRounds.concat(...dividedRoundsSecond);
          if (dividedRoundsFirst.length > 1)
            sortRounds(dividedRoundsFirst);
          else {
            sortedRounds = sortedRounds.concat(...dividedRoundsFirst);
          }
        } else {
          sortedRounds = sortedRounds.concat(...dividedRoundsFirst);
          if (dividedRoundsSecond.length > 1)
            sortRounds(dividedRoundsSecond);
          else {
            sortedRounds = sortedRounds.concat(...dividedRoundsSecond);
          }
        }
      };
  
    

      const sortBracket = (rounds: IRound[], maxRound: number, currentRound: number) => {
        let result: IRound[] = []
        for (let i = 1; i <= maxRound && i <= currentRound; i++) {
          sortedRounds = [];
          sortRounds(rounds.filter(round => round.roundLevel == i));
          result = result.concat(...sortedRounds.reverse());
        }
        return result;
      }


      const getBracket = (tournament: ITournament) => {
        
        const finalRound = tournament.rounds.find(round => round.roundLevel == tournament.maxRound) || { id: 0, players: [], roundLevel: 0, state: GameStateEnum.waiting, winner: undefined};
        
        //const last = sortBracket(tournament.rounds, tournament.maxRound, tournament.currentRound);
        return getBracketList(finalRound, tournament.maxRound, tournament.rounds);
      }
    //tournament.rounds.map(round => <RoundMatchComponent round={round} tournamentId={tournament.id}/>)
    return (
      <div className={clsx(style.roundsMap, props.isGame && style.roundsMap_game)} onClick={() => { props.close ? props.close() : console.log() }}>
        { (props.isGame) && <div className={style.mapClose} onClick={() => { props.close ? props.close() : console.log() }}>X </div>}
        <div className={style.mapTitle}>
          <div className={style.mapTitle_title}>T-{props.tournament?.id}</div>
          <h2>Semi-finals</h2>
        </div>
        <div className={clsx(style.mainContainer)} id="tournament">
            {getBracket(props.tournament)}
          </div>
      </div>
    );
}