import { ICurrency } from '@models/currency.interface';
import style1 from '../../../../inGame/components/currencyPick/currency-pick.module.scss'
import style2 from '../../../../inGame/components/currencyPick/currency-pick-game.module.scss'
import { WebsocketContext } from '@api/websocket-provider';
import { useAppSelector, useAppDispatch } from '@hooks/use-app-dispatch.hook';
import { selectUser } from '@store/user/user.slice';
import { useContext, useEffect, useState } from 'react';
import PortfolioComponent from '@components/shared/portfolio/portfolio.component';
import { GameStateEnum } from '@models/game.interface';
import { WsResponseAction } from '@models/ws.interface';
import _, { max, min } from 'lodash';
import { selectGame } from '@store/game/games.slice';
import { selectOpponentPick, selectPickedCurrencies, setOpponentPick, setPicked } from '@store/currency-pick/currency-picks.slice';
import clsx from "clsx";
import style from "@pages/inGame/in-game.module.scss";

export default function PickedTokensComponent( props: { isGame?: boolean }) {
  const style = props.isGame ? style2 : style1;
  const picked = useAppSelector((state) => selectPickedCurrencies(state, 0))
  const opponentPick = useAppSelector((state) => selectOpponentPick(state, 0))
  const { id, username } = useAppSelector(selectUser)
  const { state, cryptos } = useAppSelector((state) => selectGame(state, 0))
  const { socket, isReady } = useContext(WebsocketContext)
  const dispatch = useAppDispatch()
  
  useEffect(() => {
    if (state === GameStateEnum.preparation) {
      return
    }
    if (!isReady) {
      return
    }

    const currencyListenerPicked = (data: any) => {
      if (id === null) {
        return
      }
      const myPick: ICurrency[] = []
      const myCryptosIndex = _.findIndex(cryptos, { user: { id } })
      const oppCryptosIndex = myCryptosIndex === 0 ? 1 : 0
      cryptos[myCryptosIndex]?.cryptos.forEach((tkn) => {
        const finded = data.find((f: ICurrency) => f.symbol === tkn.symbol)
        myPick.push(finded)
      })
      dispatch(setPicked({ id: 0, currencies: myPick }))

      
      const opponentsPick: ICurrency[] = []
      cryptos[oppCryptosIndex]?.cryptos.forEach((tkn) => {
        const finded = data.find((f: ICurrency) => f.symbol === tkn.symbol)
        opponentsPick.push(finded)
      })
      dispatch(setOpponentPick({ id: 0, currencies: opponentsPick }))
    }
    socket.on(WsResponseAction.gameCurrenciesBot, currencyListenerPicked)
    /*return () => {
      socket.off(WsResponseAction.gameCurrenciesBot, currencyListener)
    }*/

  }, [isReady, cryptos, dispatch, state, id])
  return (
    <div className={clsx(style.wrapper, style.wrapper_inner, style.wrapper_withoutHeight)}>
      <PortfolioComponent title= {username} tokens={picked} preparation = {state == GameStateEnum.preparation} gameId={0}/>
      <PortfolioComponent title=  {"AI"}  preparation = {state == GameStateEnum.preparation} tokens={opponentPick} opp gameId={0}/>
    </div>
  )
}
