import style from '../in-game.module.scss';
import clsx from 'clsx';

interface IMessageProps {
    sender: 'you' | 'opponent',
    senderName: string;
    message: any;
}

export default function MessageComponent(props: IMessageProps) {
    const { sender, message, senderName} = props;
    return (
      <div className={style.chatMessage }>
        <h5 className={clsx(style.chatMessageSender, sender === 'you' && style.colorGreen, sender === 'opponent' && style.colorRed)}>
            {senderName}:</h5>
        <p>{message}</p>
      </div>
    )
  }
  