
import { useState } from 'react';
import styles from './program.module.scss';
import star from '@assets/icons/star.svg';
import starSecondary from '@assets/icons/star-second.svg';
import infoIcon from '@assets/icons/info.svg';
import infoIconSecond from '@assets/icons/info-second.svg';
import clsx from 'clsx';
export default function ProgramComponent (props: { title: string, maxValue: number, currentValue: number, subTitle: string, subData: any, secondary?: boolean }) {
    return (
        <div className={styles.wrapper}>
            <div className={clsx(styles.title, props.secondary && styles.title_second)}>{props.title}</div>
            <div className={clsx(styles.content, props.secondary && styles.content_second)}>
                <div className={clsx(styles.row, props.secondary && styles.row_firstSecond)}>
                    <div className={styles.stars}>
                        <img src={props.secondary ? starSecondary : star}/>
                        <img src={props.secondary ? starSecondary : star}/>
                        <img src={props.secondary ? starSecondary : star}/>
                    </div>
                    <div className={styles.liner}>
                        <img className={styles.info} src={props.secondary ? infoIconSecond : infoIcon}/>
                        <div className={clsx(styles.bar, props.secondary && styles.bar_second)}></div>
                    </div>
                    <div className={styles.values}>{props.currentValue}/{props.maxValue}</div>
                </div>
                <div className={clsx(styles.row, styles.nonBottom)}>
                    <div className={clsx(styles.subTitle, props.secondary && styles.subTitle_second)}>{props.subTitle}</div>
                    <div className={clsx(styles.subData, props.secondary && styles.subData_second)}>{props.subData}</div>
                </div>
            </div>
        </div>
    )
}