import clsx from 'clsx';
import { useState } from 'react';
import style from './slider.module.scss';
import infoIcon from '@assets/icons/information.svg';
import RightBarComponent from '@pages/tournamentGame/components/rightBar/right-bar.component';
import ChatComponent from './chat.component';

export default function SliderComponent(props: { tournamentId: number }) {
    const [show, setShow] = useState(false);
    return (
        <div className={clsx(style.container, show && style.container_opened)} >
            <div className={style.triggerArea}><div className={style.trigger} onClick={() => { setShow(!show)}}>
                <img src={infoIcon}></img>
            </div></div>
            <div className={clsx(style.sliderContent, show && style.sliderContent_open)}>
                <RightBarComponent tournamentId={props.tournamentId} isMap/>
                
                <ChatComponent tournamentId={props.tournamentId} />
            </div>
        </div>
    );
}