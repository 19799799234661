import clsx from 'clsx'
import React from 'react'

import styles from './button.module.scss'

interface IButtonProperties {
  children: any
  onClick?: () => void
  disabled?: boolean
  additionalClassnames?: string
  type?: 'button' | 'submit'
  variant?: 'primary' | 'secondary' | 'third'
  size?: 'xlarge' | 'large' | 'medium' | 'small'
  title?: string
}

export default function Button(props: IButtonProperties) {
  const {
    children,
    onClick,
    disabled,
    additionalClassnames,
    type = 'button',
    variant = 'primary',
    size = 'small',
    title,
  } = props
  return (
    <button
      className={clsx(
        styles.button,
        additionalClassnames,
        variant === 'secondary' && styles.button_secondary,
        variant === 'third' && styles.button_third,
        size === 'small' && styles.button_small,
        size === 'medium' && styles.button_medium,
        size === 'large' && styles.button_large,
        size === 'xlarge' && styles.button_xlarge,
        disabled && styles.disabledBtn,
      )}
      onClick={onClick}
      disabled={disabled}
      type={type}
      title = {title}
    >
      {children}
    </button>
  )
}
