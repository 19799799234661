import bigLogo from '@assets/icons/logo-vertical.png'
import logoImg from '@assets/icons/profile-icon.png'
import chartsImg from '@assets/images/charts.svg'
import Button from '@components/shared/button/button.component'
import { useAppSelector } from '@hooks/use-app-dispatch.hook'
import { useLogin } from '@hooks/use-login.hook'
import { selectIsLoggedIn } from '@store/user/user.slice'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import styles from './advertisement.module.scss'
import TokenListComponent from './tokens.component'

export default function Advertisement() {
  /*const [ graphWiewBox , setViewBox] = useState('0 0 350 350');
  useEffect(() => {
    const svgElement = document.getElementById('graph')!;
    const w = ( svgElement.parentElement?.clientWidth != undefined ? svgElement.parentElement?.clientWidth : 350);
    const h = ( svgElement.parentElement?.clientHeight != undefined ? svgElement.parentElement?.clientHeight : 350);
    setViewBox('0 0 ' + w + ' ' + h);
  }, []);*/
  const navigate = useNavigate()
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const { executeLogin } = useLogin()

  const handleTryButton = () => {
    if (isLoggedIn) {
      navigate('/standard-game')
    } else {
      executeLogin()
    }
  }

  return (
    <section className={`container ${styles.main}`}>
      <div className={styles.wrapper}>
        <div className={styles.leftWrapper}>
          <h2 className={styles.title}>
            <span>
              challange
              your trading skills
            </span>
          </h2>
          <div className={styles.leftSubWrapper}>
            <ol className={styles.list}>
              <li>
                <span>
                  An exciting trading game, in which, players pick cryptocurrencies
                  <br />
                  for a time frame and compete with others.
                </span>
              </li>
              <li>
                <span>
                  join the trading battles from the<br />
                  pool, invite friends for a battle,
                  <br />
                  challenge your trading skills.
                </span>
              </li>
            </ol>
            <div className={styles.buttons}>
              <Button additionalClassnames={styles.mainButton} size='large' onClick={handleTryButton}>
                join battle
              </Button>
              <Button additionalClassnames={styles.subButton} variant='secondary' size='large' onClick={() => { window.scroll({
                top: document.body.offsetHeight,
                left: 0, 
                behavior: 'smooth',
              });}}>
                learn more
              </Button>
            </div>
          </div>
        </div>
        <div className={styles.rightWrapper}>
          <img className={styles.image} src={chartsImg} alt="charts" />
        </div>
      </div>
    </section>
  )
}
