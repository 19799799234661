import clsx from 'clsx';
import styles from './table.module.scss';
import logoImg from '@assets/icons/tradasy_logo@2x.png'
import { useRef, useState } from 'react';
import Button from '../button/button.component';

export interface ITableProperties {
  columns: any[];
  rows: any[];
  columnDivider?: boolean;
  rowNumber?: boolean;
  darkTheme?: boolean;
  showLogo?: boolean;
  isGame?: boolean;
  isPaginate?: boolean;
  nonRadius?: boolean
  thinRow?: boolean;
  height?: number;
  fullRadius?: boolean;
  thinBars?: boolean;
  bolderHead?: boolean;
}

export default function Table(props: ITableProperties) {
  const [loadCount, setLoadCount]  = useState(0);
  const divRef = useRef<HTMLTableSectionElement>(null);
  const { columns, rows, columnDivider, rowNumber, darkTheme, bolderHead, thinBars, isGame, nonRadius, isPaginate, height, thinRow, fullRadius } = props;
  const tableClassName = darkTheme ? styles.mainTable_dark : styles.mainTable_light;
  const cellClassName = columnDivider ? styles.columnDivider : ''; 
  const rowSize = 7;

  const getPageButtons = () => {
    let btns: any[] = [];
    for (let i = 0; i < rows.length / rowSize; i++) {
      btns.push(<Button additionalClassnames={clsx(styles.pageBtn, thinBars && styles.pageBtn_small, loadCount == i*rowSize && styles.pageBtn_selected)} key={i} disabled={loadCount == i*rowSize} onClick={() => { setLoadCount(i*rowSize)}}>{i + 1}</Button>)
    }
    return btns;
  }

  return (
    <div className={clsx(styles.wrapper, styles.wrapper_fullHeight, nonRadius && styles.wrapper_nonRadius, fullRadius && styles.wrapper_fullRadius)} >
      <table className={clsx(styles.mainTable, tableClassName )} style={ (height ? { height: height } : { })}>
        <thead>
          <tr style={thinBars ? { height: '20px'} : {}}>
            {rowNumber && <th>#</th>}
            {columns.map((column: any, index: number) => (
              <th
                key={index}
                className={clsx(
                  styles.field,
                  columnDivider && index < columns.length - 1 && styles.columnDivider,
                  bolderHead && styles.bolderHead,
                  isGame && styles.gameHeader
                )}
              >
                {column}
              </th>
            ))}
          </tr>
        </thead>
        <tbody ref={divRef}>
          {rows.slice(loadCount, (isPaginate ? loadCount + rowSize : 11)).map((row: any, rowIndex: number) => {
            if (!row)
              return <></>

            return (<tr
              key={rowIndex}
              className={clsx(
                isGame && styles.gameRow,
                rowIndex % 2 == 1 && styles.secondaryRow,
                thinRow && styles.thinRow,
                thinBars && styles.thinBars,
                isGame && styles.gameHeader
              )}
            >
              {rowNumber && <td>{rowIndex + 1}</td>}
              {columns.map((col: any, colIndex: number) => (
                <td
                  key={colIndex}
                  className={`${cellClassName} ${
                    columnDivider && colIndex < columns.length - 1 ? styles.columnDivider : ''
                  } ${ (columnDivider && colIndex == columns.length - 1) ? styles.clearRight : '' }`}
                >
                  {row[col]}
                </td>
              ))}
            </tr>)
          })}
        </tbody>
        {props.showLogo && (
          <tfoot>
            <tr style={thinBars ? { height: '20px'} : {}}>
              <th className={styles.footer} colSpan={columns.length} style={thinBars ? { padding: '0px'} : {}}>
                { isPaginate && <div className={styles.pagination}>
                  <Button additionalClassnames={clsx(styles.pageBtn, thinBars && styles.pageBtn_small, loadCount == 0 && styles.pageBtn_selected)} disabled={loadCount == 0} onClick={() => setLoadCount(loadCount - rowSize || 0)}>{'<'}</Button>
                  {
                    getPageButtons()
                  }
                  <Button additionalClassnames={clsx(styles.pageBtn, thinBars && styles.pageBtn_small, loadCount >= props.rows.length - rowSize && styles.pageBtn_selected)} disabled={loadCount >= props.rows.length - rowSize} onClick={() => setLoadCount(loadCount+rowSize)}>{'>'}</Button>
                </div>}
                <img src={logoImg} />
              </th>
            </tr>
          </tfoot>
        )}
      </table>
    </div>
  )
}
