import gameService from '@api/services/game.service'
import { WebsocketContext } from '@api/websocket-provider';
import Loader from '@components/shared/loader/loader.component';
import { useAppSelector } from '@hooks/use-app-dispatch.hook';
import { WsAction, WsResponseAction } from '@models/ws.interface';
import { selectIsLoggedIn, selectUser } from '@store/user/user.slice';
import * as React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import style from './invite.module.scss';
import loadingImg from '@assets/icons/loading.png'
import LobyTable from '@components/shared/loby-table/table.component';
import { IGame, IPlayer } from '@models/game.interface';
import Button from '@components/shared/button/button.component';
import GameItemComponent from './components/game-item.component';
import clsx from 'clsx';
import { ITournament } from '@store/tournament/tournament.model';
import tournamentService from '@api/services/tournament.service';
import GameHeader from '@components/shared/game-header/game-header.component';
import { useLogin } from '@hooks/use-login.hook';


const InvitePage = () => { 
  const isLogged = useAppSelector(selectIsLoggedIn)
  const [isLoading, setIsLoading] = React.useState(true)
  const [searchParameters] = useSearchParams()
  const { id, balance } = useAppSelector(selectUser)
  const { socket } = React.useContext(WebsocketContext)
  const { executeLogin } = useLogin()
  const navigate = useNavigate()
  const popitup = (url: string, windowName: string) => {
    const newwindow = window.open(url, windowName, 'height=1920,width=1080');
    if (newwindow)
      newwindow.focus()
    else {
      alert('Pop-up blocked! Please enable pop-ups for this site');
      navigate(url, { replace: false, relative: 'path' })
    }
  }
  React.useEffect(() => {
    if (socket === null) {
      return
    }

    const gameReadyListener = (data: number) => {
      setIsLoading(false)
      popitup('https://app.tradasy.io/game?id=' + data.toString(), 'game' + data.toString())
      navigate('/custom-game')
      /*
      dispatch(setGameId(data))
        navigate({
        pathname: '/game',
        search: createSearchParams({ id: data.toString() }).toString(),
      })*/
    }
    const tournamentReadyListener = (data: any) => {
      popitup('https://app.tradasy.io/tournament-map?id='+ data.tournament+'&round='+ data.round,  'tournament' + data.tournament.toString());
      navigate('/tournament')
    }
    const tournamentOpener= (data: number) => {
      popitup('https://app.tradasy.io/tournament-map?id='+ data.toString(), 'tournament' + data.toString());
      navigate('/tournament')
    }

    socket.on(WsResponseAction.gameReady, gameReadyListener)
    socket.on(WsResponseAction.roundReady, tournamentReadyListener)
    socket.on(WsResponseAction.tournamentOpen, tournamentOpener)
  }, [navigate, socket])
  const [game, setGame] = React.useState<IGame | ITournament |  null>(null)
  const [user, setUser] = React.useState<IPlayer | null>(null)

  React.useEffect(() => {
    const getGame = async () => {
      const inviteType = searchParameters.get('type') as unknown as string
      const invitedGame = inviteType == 'game' ? await gameService.getInvitedGame(
        searchParameters.get('code') as unknown as string
      ) : await tournamentService.getInvitedTournament(
        searchParameters.get('code') as unknown as string
      )
      if (invitedGame.status == 200 && invitedGame.data) {
        setGame(inviteType == 'game' ? invitedGame.data.game: invitedGame.data.tournament)
        setUser(invitedGame.data.user)
        setIsLoading(false)
      } else {
        navigate('/custom-game')
      }
    }
    getGame()
  }, [id])

  const joinGame = async () => {
    if (!game) {
      alert('An error occured!')
      return
    }
    if (!isLogged) {
      executeLogin()
      return;
    }
    const percentFee = await tournamentService.getPercentFee();
    if (percentFee.status != 200) {
      alert('An error occured!')
      return;
    }
    if (balance < ( ('maxPlayers' in game) ? (game.bet + (game.bet * percentFee.data / 100)) :  game.bet )) {
      alert('Not enough USDT');
      return;
    }
    if (joined) {
      const leaveState = await tournamentService.leaveTournament(id!, game.id!);
      
      if (leaveState.status == 201) {
        alert('Leaved Tournament: T-'+game.id)
        setJoined(false);
      }
      else 
        alert('An error occured');

      return;
    }
    const joinParameters = ('maxPlayers' in game) ? {
      tournamentId: game.id,
      userId: id,
    } : {
      userId: id,
      gameId: game.id,
    };

    if (id) 
      socket.emit(('maxPlayers' in game) ? WsAction.join_tournament : WsAction.join_game, joinParameters);

    if ('maxPlayers' in game) 
      setJoined(true);
  }
  const [joined, setJoined] = React.useState(false);
  return (
    <div>
      <GameHeader gameType={( game && 'maxPlayers' in game) ? 'Tournament' : 'Custom'} />
    <div className={style.mainContent}>
      {game && (
        <>
          <div className={style.title}>
            {user?.username}  invites you
          </div>
          <div className={style.content}>
            <div className={style.contentRow}>
            <GameItemComponent title='Entry Fee' content={game.bet + ' usdt'}/>
            <GameItemComponent title='Duration' content={game.duration + ' minutes'}/>
            </div>
            <div className={style.contentRow}>
            <GameItemComponent title='Coin' content={game.maxCryptos} bigFont/>
            <GameItemComponent title='Players' content={( 'maxPlayers' in game) ? ((game as unknown as ITournament).maxPlayers) : '1/2'} bigFont/>
            </div>
            <div className={clsx(style.contentRow, style.contentRow_buttons)}>
              <Button disabled={isLoading} additionalClassnames={style.joinBtn} variant="primary" onClick={() => joinGame()}>
                { joined ? '⧖-Leave' : 'Accept'}
              </Button>
              { !joined  && <Button disabled={isLoading} additionalClassnames={clsx(style.joinBtn, style.joinBtn_second)} variant="primary" onClick={() => navigate('/custom-game')}>
                Reject
              </Button> }
            </div>
          </div>
        </>
      )}
    </div>
    </div>
  )
}

export default InvitePage
