import { useContext, useState } from 'react';
import style from '../in-game.module.scss';
import clsx from 'clsx';
import Button from '@components/shared/button/button.component';
import { WebsocketContext } from '@api/websocket-provider';
import { WsAction } from '@models/ws.interface';
import sendIcon from '@assets/icons/send-message.svg';


export default function SendMessageComponent(props: { id: number | null, userId: number | null, muted: boolean, isTournament?: boolean}) {
    const [inputError, setInputError] = useState('');
    const [message, setMessage] = useState<null |string>(null);
    const { socket } = useContext(WebsocketContext)
    const handleKeyDown = (event: any) => {
      if (event.key === 'Enter') {
        sendMessage();
        event.preventDefault()
      }
    };
    const sendMessage = () => {
      if (props.userId == null || message == null || message == '')
        return;
      
      if (props.isTournament)
      socket.emit(WsAction.sendTournamentMessage, { gameId: props.id, message:message, date: new Date(Date.now()), userId: props.userId })
      else 
        socket.emit(WsAction.sendRoundMessage, { gameId: props.id, message:message, date: new Date(Date.now()), userId: props.userId })
      setMessage(null);
    }
  return (
      <form className={style.form}>
            <input
            className={clsx('input', inputError && 'input_error')}
            required
            step=".01"
            placeholder="Chat"
            value={message ? message : ''}
            onChange={(event) => {setMessage(event.target.value)}}
            onKeyDown={handleKeyDown}
            disabled={props.muted || props.id == null}
            />
            <Button disabled={props.muted || props.id == null} variant='primary' size='small' additionalClassnames={style.sendMessageBtn} onClick={sendMessage}><img src={sendIcon}/></Button>
      </form>
  )
}
  