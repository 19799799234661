import Button from '@components/shared/button/button.component'
import ModalWrapper from '@components/shared/modal-wrapper/modal-wrapper.component'
import TokenChange from '@components/TokenChange/token-change.component'
import { useAppDispatch, useAppSelector } from '@hooks/use-app-dispatch.hook'
//import {  selectOpponentPick,  selectPickedCurrencies,} from '@store/currency-pick/currency-pick.slice'
//import { selectGame, selectIsWinner, selectOpponent } from '@store/game/game.slice'
import { selectUser } from '@store/user/user.slice'
import { calculateTotalChange } from '@utils/utils'
import clsx from 'clsx'
import React, { useEffect } from 'react'
import logoImg from '../../../../assets/icons/logo-small.png'

import ChosenToken from '../ChosenToken/chosen-token.component'
import styles from './result-modal.module.scss'
import { selectGame, selectIsWinner } from '@store/game/games.slice'

interface IResultModalProperties {
  gameId: number
  isOpen: boolean
  onClose: () => void
}

export default function ResultModal(props: IResultModalProperties) {
  const { isOpen, onClose } = props
  const isWinner = useAppSelector((state) => selectIsWinner(state, props.gameId))
  const { bet } = useAppSelector((state) => selectGame(state, props.gameId));


  return (
    <ModalWrapper
      isOpen={isOpen}
      toggle={onClose}
      styleVariant="secondary"
      className={clsx(styles.result, !isWinner && styles.result_lose)}
      isNegative={!isWinner}
    >
      <img className={styles.logo} src={logoImg}></img>
      <p className={styles.gameTypeLabel}>standard</p>
      {isWinner ? (
        <h4 className={styles.title}>CONGRATS!</h4>
      ) : (
        <h4 className={clsx(styles.title, styles.title_lose)}>YOU LOSE</h4>
      )}
      <p className={styles.desc}>
        { isWinner ? ( <p>You won: { bet }</p>): 'Better luck next time!' }
      </p>
      <Button additionalClassnames={clsx(styles.btn, !isWinner && styles.btn_lose)} onClick={onClose} variant="secondary">
        Exit
      </Button>
    </ModalWrapper>
  )
}
