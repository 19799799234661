import { useAppSelector } from '@hooks/use-app-dispatch.hook'
import style from './right-bar.module.scss';
import clsx from 'clsx';
import tetherLogo from '@assets/icons/tether.png'
import Timer from '../Timer/timer.component';
import Button from '@components/shared/button/button.component';
import Cell from './cell.component';
import { useEffect, useState } from 'react';
import { ITournament } from '@store/tournament/tournament.model';
import tournamentService from '@api/services/tournament.service';
import RoundMatchComponent from '@pages/tournament-map/components/round-match.component';
import ResultModal from '../ResultModal/result-modal.component';
import ModalWrapper from '@components/shared/modal-wrapper/modal-wrapper.component';


export default function RightBarComponent(props: { tournamentId: number, expiryTime?: any, roundId?: number, setBracket?: (child: JSX.Element) => any, isMap?:boolean }) {
    const [tournament, setTournament] = useState<ITournament | null>(null);

    const getTournament = async () => {
        const req = await tournamentService.getTournamentInfo(props.tournamentId.toString());
        if (req.status == 200)
            setTournament(req.data);
    }
    useEffect(() => {
      getTournament()
    }, [])
    const resBracket = () => {
      if (!props.setBracket)
        return;

      props.setBracket(<></>)
    }
    const openBracket = () => {
      if (!props.setBracket)
        return;


      props.setBracket(
        <ModalWrapper
          isOpen={true}
          toggle={resBracket}
          styleVariant="secondary"
          className={clsx(style.result)}
          notContent={true}
        >
          <RoundMatchComponent
            tournament={tournament!}
            expiryTime={props.expiryTime}
            info="Ends in"
            isGame
            close={resBracket}
          />
        </ModalWrapper>
      )!
    }
    const getPercentPerDist = (playerCount: number, index: number) => {
      if (index == 1){
        if (playerCount == 8)
          return 100;
        if (playerCount == 16)
          return 65;
        if (playerCount == 32)
          return 50;
      }
      if (index == 2){
        if (playerCount == 16)
          return 35;
        if (playerCount == 32)
          return 25;
      }
      if (index == 3){
        return 12.5;
      }
      return 12.5;
      
    }
    const getPrizeList = (playerCount: number) => {
      const list: JSX.Element[] = [];
      for (let i = 1; i<=playerCount / 8; i++) {
        list.push(<li>1. {tournament?.bet! * playerCount * getPercentPerDist(playerCount, i) / 100 } Usdt</li>)
      }
      return list;
    };
    return (
        <div className={clsx(style.container, props.isMap && style.container_map)}>
            { !props.isMap && <div className={style.timer}>
                <Timer expiry={props.expiryTime} isGame/>
            </div> }
            <div className={clsx(style.detailsContainer, props.isMap && style.detailsContainer_map)}>
                <div className={style.colContainer}>
                    <div className={style.col}>
                        <Cell variant='secondary' style={{ height: 107, justifyContent: 'center', display: 'flex', alignItems: 'center', fontSize: '39px', fontWeight: '600' }} >t-{props.tournamentId}</Cell>
                        <Cell variant='secondary'  style={{ height: 135, justifyContent: 'center', flexDirection: 'column', display: 'flex', alignItems: 'center' }} title='Round Time'>{tournament?.duration} Minutes</Cell>
                        <Cell variant='secondary'  style={{ height: 135, justifyContent: 'center', flexDirection: 'column', display: 'flex', alignItems: 'center', }} title='Players'>{(tournament?.maxPlayers + '/' + tournament?.maxPlayers)}</Cell>
                    </div>
                    <div className={style.col}>
                        <Cell variant='primary'  style={{ height: 135, justifyContent: 'center', flexDirection: 'column', display: 'flex', alignItems: 'center', }} title='Prize Pool'><div className={style.line}>{(tournament?.bet || 0) * (tournament?.maxPlayers || 1)} Usdt</div></Cell>
                        <Cell variant='secondary'  style={{ height: 257, margin: 'auto' }} title='Distribution'>
                            <ol className={style.prizeList }>
                                {getPrizeList(tournament?.maxPlayers!)}
                            </ol>
                        </Cell>
                    </div>
                </div>
                { !props.isMap && <Button additionalClassnames={style.bracketButton} variant='secondary' onClick={() => openBracket() }>Bracket</Button>}
            </div>
        </div>
    )
  }
  